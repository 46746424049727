import { Tooltip } from "@mui/material";
import React from "react";

const Button = ({ title, callback, disabled, tooltipText="" }) => {
  return (
    <Tooltip title={tooltipText}>
      <img
        alt="icon"
        onClick={callback}
        style={{
          height: "25px",
          cursor: "pointer",
        }}
        src={title}
      />
    </Tooltip>
  );
};

export default Button;
