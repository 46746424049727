import React, { useState } from "react";

import axiosConfig from "../../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import MuiDenseTable from "../../muiComps/MuiDenseTable";

import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";

// device_id
// device_name
// device_type
// id

const UserDevices = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const [deviceDetails, setDeviceDetails] = useState([]);
  const deviceData = deviceDetails?.map((device) => {
    return { ...device, delete_device_icon: <CancelIcon /> };
  });

  // DELETE DEVICE HANDLER
  const removeDevice = async (device_id) => {
    if (!device_id) return;

    try {
      const res = await axiosConfig({
        method: "delete",
        url: "/user/userdevice",
        data: { id: device_id, userid: USERID },
      });
      
      if (res.status === 200 || res.statusText === 'OK') {
        setDeviceDetails(prevState => {
          return prevState.filter(device => device.id !== device_id)
        })
        toast.success("Device deleted successfully!");
      }
      
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  const HEADINGS = [
    { id: "device_id", label: "Device ID", minWidth: 50 },
    { id: "device_name", label: "Device Name", minWidth: 50 },
    { id: "device_type", label: "Device Type", minWidth: 50 },
    {
      id: "delete_device_icon",
      label: "Remove Device",
      minWidth: 50,
      align: 'center',
      onClick: removeDevice,
    },
  ];

  

  // FETCH ALL DEVICES QUERY
  const devicesQuery = useQuery({
    queryKey: ["user-devices"], // dependency
    queryFn: () => fetchDevices(USERID),
    onSuccess: (data) => {
      setDeviceDetails(data.response);
    },
    onError: (error) => {
      console.log("watchlist error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  

  return (
    <div>
      <h1>User Devices for Alerts</h1>

      {/* devices table */}
      <div>
        <MuiDenseTable rowData={deviceData} columnHeadings={HEADINGS} />
      </div>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default UserDevices;

// UTILITIES: Fetcher Functions
const fetchDevices = async (user_id) => {
  if (!user_id) return;

  const res = await axiosConfig({
    method: "get",
    url: `/user/userdevice?userid=${user_id}`,
    data: {},
  });

  return res.data;
};
