import { Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import axiosConfig from "../../axios/axiosConfig";
import LoginScreen from "../LoginScreen/LoginScreen";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESS, UPDATE_USER_DETAILS } from "../../reduxStore/actions/types";
import ReactHotToaster from "../common/ReactHotToaster";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const userLocalStorageDetails = JSON.parse(localStorage.getItem("user"));

const VerifyEmailPrompt = ({ userDetails }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const enteredEmail = location?.state?.email;  // getting state from user profile page

  const [emailOTP, setEmailOTP] = useState("");

  const [verifyBtnClicked, setVerifyBtnClicked] = useState(false);

  const routeToHome = () => {
    if (location.pathname === "/login") {
      // navigate('/');
      window.location.assign("/");
    } else {
      window.location.reload();
    }
  };

  const sendEmailOTP = async () => {
    try {
      const res = await axiosConfig({
        method: "get",
        url: `/enroll/emailverify?userid=${userDetails?.id}`,
        data: {},
      });

      // if success, setVerifyBtnClicked(true)
      if (res.status === 200 || res.statusText === "OK") {
        setVerifyBtnClicked(true);
        toast.success("Verification code sent to your email.");
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  // VERIFY EMAIL CODE (SUBMIT BUTTON)
  const verifyEmailOTP = async () => {
    if (!userDetails || !emailOTP) {
      return;
    }

    try {
      const res = await axiosConfig({
        method: "post",
        url: "/enroll/emailverify",
        data: { userid: userDetails?.id, otp: emailOTP, email: enteredEmail },
      });

      // res.data:  { status: "", user_details: {}, response: "You have succesfully."}

      if (res.status === 200 || res.statusText === "OK") {
        // state update user
        dispatch({
          type: UPDATE_USER_DETAILS,
          // payload: { user: res.data.user_details },
          payload: { user: res.data.user_details },
        });

        // updte local storage
        const newUserLocalStorage = {
          ...userLocalStorageDetails,
          userDetails: res.data.user_details,
        };

        localStorage.setItem("user", JSON.stringify(newUserLocalStorage));

        toast.success("Email has been verified successfully!");

        // window.location.assign("/");
        // routeToHome();
        navigate('/home');
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.response ||
          "Something went wrong, please try again."
      );
    }
  };

  // IF NOT AUTHENTICATED, ROUTE TO LOGIN PAGE
  // if (!userDetails) {
  //   return <LoginScreen />;
  // }

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          maxWidth: 600,
          minHeight: 400,
          mt: 4,
          p: 8,
          bgcolor: "#cfe8fc",
          // height: "100vh",
        }}
      >
        {/* <Typography variant="h3" textTransform="uppercase">
          Thank You for choosing tnibro.com
        </Typography> */}
        <Typography variant="h3" textTransform="uppercase">
          Email Verification.
        </Typography>

        <Typography variant="body1" sx={{ mt: 4, mb: 4, textAlign: "justify" }}>
          An email verification code has been sent to your email{" "}
          <span>{userDetails?.email}</span>. Please enter the code (within 10
          minutes) to verify your email address and get access to all Features.
        </Typography>

        <Typography variant="body1" sx={{ mt: 3, mb: 3, textAlign: "justify" }}>
          If you don't see the link, you may need to{" "}
          <strong>check your spam</strong> folder. If you still can't find the
          link, click the <strong>RESEND</strong> button below:
        </Typography>

        {/* OTP INPUT HERE */}
        <Box
          component="form"
          sx={{
            "& > :not(style)": { width: "15ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            size="small"
            id="emailOTP"
            label="Email Code"
            variant="outlined"
            value={emailOTP}
            onChange={(e) => setEmailOTP(e.target.value)}
            inputProps={{ maxLength: 6 }}
            // type="number"
            textAlign="center"
          />
        </Box>

        {/* BUTTON CONTAINER */}
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            disabled={
              !emailOTP || emailOTP.trim() === "" || emailOTP.trim().length < 6
            }
            variant="contained"
            color="info"
            onClick={verifyEmailOTP}
            // sx={{ mr: 2 }}
          >
            Submit
          </Button>

          <Button variant="contained" color="info" onClick={sendEmailOTP}>
            Resend
          </Button>

          <Button variant="contained" color="info" onClick={() => navigate('/home')}>
            home
          </Button>

          <Button variant="contained" color="info" onClick={() => navigate('/user-dashboard/profile')}>
            back
          </Button>
        </Stack>
      </Box>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </Container>
  );
};

export default VerifyEmailPrompt;
