import React from 'react';

import styles from './InfoCard.module.css';

const InfoCard = ({ cardInfo }) => {
  
  return (
    <div className={styles.outerWrapper} >
      <div className={styles.innerWrapper}>
        <img className={styles.image} src={cardInfo.img} alt="" />

        <h2 className={styles.title}>
          {cardInfo.title}
        </h2>

        <p className={styles.description}>
          {cardInfo.description}
        </p>
      </div>
    </div>
  )
}

export default InfoCard