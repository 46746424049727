import React, { useState } from "react";

import BasicButton from "../components/Button/BasicButton";
import BasicTextFields from "../components/Modal/Input";
import Dropdown from "../components/Dropdown/Dropdown";

import "./AddTeamMember.css"
import { memberDepartmentList } from "../utils/Member-options";

const AddTeamMember = ({ handleCreateMember }) => {

  const [memberName, setMemberName] = useState("");
  const [memberPassword, setMemberPassword] = useState("");
  const [memberDepartment, setMemberDepartment] = useState("");
  const [memberPhone, setMemberPhone] = useState(null);
  const [memberEmail, setMemberEmail] = useState("");

  const handleUserName = (key, name) => {
    setMemberName(name);
  };

  const handleUserPassword = (key, pass) => {
    setMemberPassword(pass);
  };

  const handleUserDepartment = (dept) => {
    setMemberDepartment(dept);
  };

  const handleUserPhone = (key, ph) => {
    setMemberPhone(ph);
  };

  const handleMemberEmail = (key, email) => {
    setMemberEmail(email);
  };

  const handleSubmitMemberDetails = () => {
    const memDetails = {
      "username": memberName,
      "email": memberEmail,
      "password": memberPassword,
      "department": memberDepartment,
      "phoneno": memberPhone,
      "department_status": true
    };
    handleCreateMember(memDetails);
  };

  return (
    <>
      <div className="add-member-wrappper">
        <div className="add-member-info">
          <div className="add-member-fields-container">
            <BasicTextFields value={memberName} label="User Name" fieldKey="username" callback={handleUserName} />
            <BasicTextFields value={memberPhone} label="Phone"  fieldKey="phoneno" callback={handleUserPhone} />
          </div>
          <div className="add-member-fields-container">
            <BasicTextFields value={memberEmail} label="Email" fieldKey="email" callback={handleMemberEmail} />
            <BasicTextFields value={memberPassword} label="Password" fieldKey="user_password" callback={handleUserPassword} />
          </div>
        </div>

        <div className="user-department">
          <Dropdown list={memberDepartmentList} value={memberDepartment} label="Department" callback={(val) => handleUserDepartment(val)} />
        </div>
        <div className="team-member-action">
          <BasicButton title="Create Member" callback={handleSubmitMemberDetails} />
        </div>
      </div>
    </>
  )
};

export default AddTeamMember;
