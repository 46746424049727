import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";


export default function SymbolAlertTable({ symbolAlertData }) {

  const styles = {
    symbols: {
      backgroundColor: '#FFF5EE',
      // backgroundColor: '#F5B7B1',
      borderRadius: '2px',
      padding: '2px 4px'
    },
    headers: {
      fontWeight: 'bold'
    }
  }
  const symbolStyles = {
    backgroundColor: '#FFF5EE',
    // backgroundColor: '#F5B7B1',
    borderRadius: '2px',
    padding: '2px 4px'
  }

  return (
    <>
      <Typography variant="h6" sx={{ textAlign: "center", maxWidth: 600 }}>
        Alert Counts for <span style={symbolStyles}>{symbolAlertData.symbol}</span>
      </Typography>

      <TableContainer component={Paper} sx={{ maxWidth: 500 }}>
      {/* <Typography variant="h6" sx={{ textAlign: "center" }}>
        Alert Counts for <span style={symbolStyles}>{symbolAlertData.symbol}</span>
      </Typography> */}
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: '#FFF5EE' }} >
            <TableRow>
              <TableCell sx={styles.headers} >SYMBOL</TableCell>
              <TableCell sx={styles.headers} align="right">Price Alerts</TableCell>
              <TableCell sx={styles.headers} align="right">Buy Alerts</TableCell>
              <TableCell sx={styles.headers} align="right">Sell Alerts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {symbolAlertData.symbol}
              </TableCell>
              <TableCell align="right">
                {symbolAlertData.price_alert_count}
              </TableCell>
              <TableCell align="right">
                {symbolAlertData.buy_alert_count}
              </TableCell>
              <TableCell align="right">
                {symbolAlertData.sell_alert_count}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
