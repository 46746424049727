import { useState, useEffect } from "react";
import axios from "axios";

const access_token = JSON.parse(localStorage.getItem("user"))?.access_token;

// axios Defaults have already been provided in the server
// axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${access_token}`,
  },
});

export const useAxios = (axiosParams) => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async (params) => {
    try {
      const result = await axiosInstance.request(params);
      setResponse(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // return if other than get
    // if (axiosParams.method !== 'get' || axiosParams.method !== 'GET') {
    //   return;
    // }

    fetchData(axiosParams);
  }, []); // execute once only

  const postAdvert = async (newAdData) => {
    // post Ad to backend
    const newParams = {
      method: "POST",
      url: "/advertisement/access",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${access_token}`,
      },
      data: newAdData,
    };

    fetchData(newParams);
  };

  return { response, error, loading, postAdvert };
};
