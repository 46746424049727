import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack } from '@mui/material';

export default function Dropdown({ list, value, label, callback, disabled, sxStyles = {} }) {

  const handleChange = (event) => {
    callback(event.target.value);
  };

  return (
    <Stack>
      <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">{label}</InputLabel>
        <Select
          size='small'
          labelId="demo-select-small"
          id="demo-select-small"
          value={value}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {list.map(item => {
            return <MenuItem value={item.value}>{item.title}</MenuItem>
          })}


        </Select>
      </FormControl>
    </Stack>
  );
}