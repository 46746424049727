import axiosConfig from "../../axios/axiosConfig";

export const getUserPayments = async (USERID) => {
  const res = await axiosConfig({
    method: "get",
    url: `/user/userdashboard?section=payment&userid=${USERID}`,
    data: {},
  });

  return res.data;
};

export const getUserReferrals = async (USERID) => {
  const res = await axiosConfig({
    method: "get",
    url: `/user/userdashboard?section=referral&userid=${USERID}`,
    data: {},
  });

  return res.data;
};

export function formatDate(date_string) {
  return new Date(date_string).toDateString();
}
