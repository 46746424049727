import React from 'react';
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";

const MobilePhoneInput = ({ userDetails, phoneInputOnChange }) => {
  return (
    <PhoneInput
              inputStyle={{ height: 25, width: "100%", backgroundColor: userDetails?.countryCode !== "in" ? '#bbb' : '' }}
              placeholder="Mobile Number"
              country={userDetails?.countryCode || "in"}
              value={userDetails?.phone}
              onChange={phoneInputOnChange}
              preferredCountries={["us", "in"]}
              inputProps={{
                name: "phone",
                required: userDetails?.countryCode === "in", // true if user is Indian
                autoFocus: true,
                disabled: userDetails?.countryCode !== "in", // disabled if user is non-Indian
              }}
            />
  )
}

export default MobilePhoneInput;