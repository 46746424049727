import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [countdownTimer, setCountdownTimer] = useState("00:00:00");

  useEffect(() => {
    // Set the date we're counting down to
    const countDownDate = new Date("August 5, 2023 15:37:25").getTime();

    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      // document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      // + minutes + "m " + seconds + "s ";
      setCountdownTimer(`${hours}:${minutes}:${seconds}`);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setCountdownTimer("00:00:00");
      }
    }, 1000);

    return () => clearInterval(x);
  }, []);

  return <span style={styles.date}>{countdownTimer}</span>;
};

export default CountdownTimer;

const styles = {
  date: {
    backgroundColor: "black",
    display: "inline-block",
    width: "90px",
    textAlign: "center",
    borderRadius: 2,
    fontFamily: "Orbitron",
    // fontWeight: 'bold',
    fontSize: "16px",
    padding: "2px",
  },
};
