export const memberDepartmentList = [
  {
    title: "Management",
    value: "management"
  },

  {
    title: "Accounts",
    value: "accounts"
  },

  {
    title: "Marketing",
    value: "marketing"
  },

  {
    title: "Sales",
    value: "sales"
  },

  {
    title: "HR",
    value: "hr"
  }
];
