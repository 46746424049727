import { Outlet, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentIcon from "@mui/icons-material/Payment";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EmailIcon from "@mui/icons-material/Email";
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import RedeemIcon from '@mui/icons-material/Redeem';
import CollectionsIcon from '@mui/icons-material/Collections';
import PeopleIcon from '@mui/icons-material/People';
import NotesIcon from '@mui/icons-material/Notes';

import DashboardLayout from "../components/common/DashboardLayout";

const user = JSON.parse(localStorage.getItem("user"));

const drawerWidth = 240;

const mainListItemsArray = [
  {
    id: 1,
    label: "Users",
    link: "/admin-dashboard/users",
    icon: <GroupsIcon />,
  },
  {
    id: 2,
    label: "Alerts",
    link: "/admin-dashboard/alerts",
    icon: <NotificationsActiveIcon />,
  },
  {
    id: 3,
    label: "Coupons",
    link: "/admin-dashboard/coupons",
    icon: <RedeemIcon />,
  },
  {
    id: 4,
    label: "Ads",
    link: "/admin-dashboard/ads",
    icon: <CollectionsIcon />,
  },

  {
    id: 5,
    label: "Members",
    link: "/admin-dashboard/members",
    icon: <PeopleIcon />,
  },
  {
    id: 6,
    label: "Create Marquee",
    link: "/admin-dashboard/create-marquee",
    icon: <NotesIcon />,
  },
];

export default function AdminDashboardLayout() {
  

  return (
    <DashboardLayout mainListItems={mainListItemsArray} />
  );
}
