import React from 'react'
import { useState } from 'react'
import { country_list } from '../../../data/countryList';
import { useAxios } from '../../../hooks/useAxios';

import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import styles from './AdsForm.module.css'
import { FilledInput, FormControl, FormHelperText, FormLabel, InputLabel, OutlinedInput } from '@mui/material';
import { useSelector } from 'react-redux';
import AccessDenied from '../../common/AccessDenied';

// fields - userid, imagefile, adlink, screename select list, screenlocation top bottom, country, publishdate, expiry date

const screenNameOptions = ["home", "watchlist"];

const screenLocationOptions = [ "top", "bottom"]

const AdsForm = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  console.log(userDetails)

  const [imgFile, setImgFile] = useState(null);

  const [inputOptions, setInputOptions] = useState({
    adLink: "",
    country: "",
    publishDate: "",
    expiryDate: "",
    screenName: "home",
    screenLocation: "top",
  });
  const {
    adLink,
    country,
    publishDate,
    expiryDate,
    screenName,
    screenLocation,
  } = inputOptions;

  const { response, loading, error, postAdvert } = useAxios({
    method: "",
    url: "/advertisement/access",
    headers: {
      accept: "*/*",
    },
    data: inputOptions,
  });

  // console.log(response)

  const handleInputChange = (e) => {
    setInputOptions((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  // Create new advert and POST it to the backend, using useAxios hook
  const createNewAd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("userid", userDetails.id);
    formData.append("adv_image", imgFile);
    formData.append("adv_url", adLink);
    formData.append("country", country);
    formData.append("published_date", publishDate);
    formData.append("expiry_date", expiryDate);
    formData.append("screen_name", screenName);
    formData.append("screen_location", screenLocation);

    postAdvert(formData).then((res) => {
      // check (response.status)
      if (response.status === "success") {
        // invoke toast
      } else {
        // toast with error
      }
    });
  };

  
  return (
    <form>
      <h2>Add New Advertisement</h2>
      {/* IMAGE UPLOAD */}
      <div className="input_Control">
        <label htmlFor="imgFile">Upload Image</label>
        <input
          type="file"
          name="imgFile"
          id="imgFile"
          onChange={(e) => setImgFile(e.target.files[0])}
        />
      </div>
      {/* <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormLabel>Upload Image</FormLabel>
      <Input
        type="file"
        placeholder="Placeholder"
        onChange={(e) => setImgFile(e.target.files[0])}
      />
    </Box> */}
      {/* ADD HYPERLINK */}
      <div className="input_Control">
        <label htmlFor="">Add Hyperlink</label>
        <input
          type="text"
          name="adLink"
          id="adLink"
          onChange={handleInputChange}
        />
      </div>
      {/* <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl disabled={false} error={false}>
        <InputLabel htmlFor="adLink">Name</InputLabel>
        <OutlinedInput
          // id="component-outlined"
          defaultValue="Composed TextField"
          label="Name"
          name="adLink"
          id="adLink"
          onChange={handleInputChange}
        />
        <FormHelperText id="component-helper-text">
          Some important helper text
        </FormHelperText>
      </FormControl>
    </Box>

    {/* MUI AUTOCOMPLETE COMPS */}
      {/* <MuiAutoComplete
      autoCompleteOptions={screenNameOptions}
      label="Screen Name"
    /> */}

      {/* PREVIOUS CODE STARTS HERE */}
      {/* SCREEN NAME LIST */}
      <div className="input_Control">
        <label htmlFor="screenName">Screen Name:</label>
        <select name="screenName" id="screenName" onChange={handleInputChange}>
          {screenNameOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {/* SCREEN / AD LOCATION LIST */}
      <div className="input_Control">
        <label htmlFor="screenLocation">Screen Location:</label>
        <select
          name="screenLocation"
          id="screenLocation"
          onChange={handleInputChange}
        >
          {screenLocationOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {/* COUNTRY LIST */}
      <div className="input_Control">
        <label htmlFor="country">Select Country:</label>
        <select name="country" id="country" onChange={handleInputChange}>
          {country_list.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      {/* PUBLISH DATE */}
      <div className="input_Control">
        <label htmlFor="publishDate">Publish Date</label>
        <input
          min={new Date().toISOString().substring(0, 10)}
          name="publishDate"
          id="publishDate"
          type="date"
          onChange={handleInputChange}
        />
      </div>
      {/* EXPIRY DATE */}
      <div className="input_Control">
        <label htmlFor="expiryDate">Expiry Date</label>
        <input
          type="date"
          name="expiryDate"
          id="expiryDate"
          onChange={handleInputChange}
        />
      </div>
      <button onClick={createNewAd}>Add</button>
    </form>
  );
}

export default AdsForm;




// MUI AUTOCOMPLETE COMPONENT
const MuiAutoComplete = ({ autoCompleteOptions, label }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={autoCompleteOptions}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={label} />}
      // onChange={(event, newValue) => setValue(newValue)}
    />
  );
}
