import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";

const MobileOTPInputBox = ({
  mobileNumber,
  setMobileNumber,
  mobileOTP,
  setMobileOTP,
  onGetOTP,
  onVerifyOTP,
  selectedOptionsArray = [],
  actionType,
}) => {

  const disableGetOTPButton =
    !mobileNumber ||
    mobileNumber.trim() === "" ||
    mobileNumber.length < 10 ||
    (actionType === "deactivate" ? selectedOptionsArray.length <= 0 : false);

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: "0.5rem",
        padding: "1rem 2rem",
        marginTop: "1rem",
        // alignItems: 'center',
        // justifyContent: 'center'
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginBottom: "1rem",
          textTransform: "uppercase",
          fontSize: "0.7rem",
          fontWeight: "bold",
          color: "#666",
          backgroundColor: "#e2e2e2",
          borderRadius: "0.2rem",
          padding: "0.5rem",
        }}
      >
        A verification code will be sent to your registered mobile. Enter mobile
        number below.
      </Typography>
      <Stack
        direction="row"
        spacing={3}
        sx={{
          marginBottom: "1rem",
          // alignItems: 'center',
          // justifyContent: 'space-between'
        }}
      >
        <TextField
          required
          size="small"
          id="mobile-number"
          label="Mobile Number"
          // defaultValue="9999 999 999"
          variant="outlined"
          value={mobileNumber}
          onChange={(event) => setMobileNumber(event.target.value)}
        />

        <Stack direction="row">
          <Button
            disabled={disableGetOTPButton}
            size="small"
            variant="contained"
            onClick={onGetOTP}
          >
            get otp
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={3}
        sx={
          {
            // alignItems: 'center',
            // justifyContent: 'space-between'
          }
        }
      >
        <TextField
          required
          size="small"
          id="mobile-otp"
          label="Enter OTP"
          variant="outlined"
          value={mobileOTP}
          onChange={(event) => setMobileOTP(event.target.value)}
          inputProps={{
            minLength: "6",
            maxLength: "6",
          }}
        />
        <Button
          disabled={!mobileOTP || mobileOTP.length < 6}
          size="small"
          variant="contained"
          onClick={onVerifyOTP}
        >
          {actionType}
        </Button>
      </Stack>
    </Box>
  );
};

export default MobileOTPInputBox;
