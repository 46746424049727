import { Box } from "@mui/material";
import React from "react";
import UserAlertsTable from "../../components/alerts/UserAlertsTable";

const AlertsPage = () => {
  return (
    <Box sx={{ p: "1rem 2rem" }}>
      <h1>Alerts</h1>

      {/* Alerts TABLE COMPONENT HERE */}
      <UserAlertsTable />
    </Box>
  );
};

export default AlertsPage;
