import React, {useState} from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function BasicPagination({handlePagechange, count, currentPage}) {
  const [page, setPage] = useState(currentPage);

  const handleChange = (event, value) => {
    setPage(value);
    handlePagechange(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination count={count} page={page} variant="outlined" shape="rounded" onChange={handleChange} />
    </Stack>
  );
}