import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import axiosConfig from "../../axios/axiosConfig";
import { useNavigate } from "react-router-dom";
import LoginScreen from "../../components/LoginScreen/LoginScreen";
import AccessDenied from "../../components/common/AccessDenied";
import AdminMobileOTPLoginForm from "./AdminMobileOTPLoginForm";
// import VerifyEmailPrompt from "../components/VerifyEmailPrompt";

const local_admin_access_token = localStorage.getItem("admin_access_token");

const AdminRoute = ({
  auth: { isAuthenticated },
  children,
  allowedRoles = null,
}) => {
  const userState = useSelector((store) => store.auth);
  const navigate = useNavigate();

  const USER_DETAILS = userState?.user?.userDetails;
  const USER_NAME = USER_DETAILS?.username;

  // state for session ID
  const [currentAdminAccessToken, setCurrentAdminAccessToken] = useState(
    local_admin_access_token
  );
  const [isAdminAccessTokenValid, setIsAdminAccessTokenValid] = useState(false);

  // FUNCTION TO SET ADMIN ACCESS TOKEN
  const setAdminAccessToken = (access_token) => {
    setCurrentAdminAccessToken(access_token);
  };

  // POST REQUEST TO CHECK SESSION ID
  const checkAdminAccessToken = useMutation({
    mutationFn: async () => {
      // if (!currentAdminAccessToken || currentAdminAccessToken.trim() === "") {
      //   return;
      // }

      const res = await axiosConfig({
        method: "post",
        url: "tnibroadmin/admin_access",
        data: {
          admin_access_token: currentAdminAccessToken,
          // admin_access_token: local_admin_access_token,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // console.log("data admin token: ", data);
      if (data.response.toLowerCase() === "valid") {
        setIsAdminAccessTokenValid(true);
      } else {
        setIsAdminAccessTokenValid(false);
      }
      // localStorage.setItem("admin_session_id", JSON.stringify(okaaaa));
    },
    onError: (error) => {
      setIsAdminAccessTokenValid(false);
      window.alert(error?.response?.data?.response || "Something went wrong");
      // toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // useEffect to send and check admin access token
  useEffect(() => {
    if (!currentAdminAccessToken || currentAdminAccessToken.trim() === "") {
      return;
    }
    checkAdminAccessToken.mutate();
  }, [currentAdminAccessToken]);

  // IF NOT AUTHENTICATED, ROUTE TO LOGIN PAGE
  if (!isAuthenticated) {
    return <LoginScreen />;
  }

  // ONLY ALLOW ROLES CONTAINED IN 'ALLOWED_ROLES' ARRAY
  if (!allowedRoles?.includes(USER_DETAILS.user_type.toLowerCase())) {
    return <AccessDenied />;
  }

  // if there's no token, render OTP form, else send post request to check if token is valid
  // if (!currentAdminAccessToken || currentAdminAccessToken.trim() === "") {
  //   return (
  //     <AdminMobileOTPLoginForm
  //       setAccessToken={setAdminAccessToken}
  //       validateAccessToken={setIsAdminAccessTokenValid}
  //     />
  //   );
  // }

  if (checkAdminAccessToken.isLoading) {
    return <h1>Loading...</h1>;
  }

  if (!isAdminAccessTokenValid) {
    return (
      <AdminMobileOTPLoginForm
        setAccessToken={setAdminAccessToken}
        validateAccessToken={setIsAdminAccessTokenValid}
      />
    );
  }
  // else {
  //   return children;
  // }

  return children;

  // return isAuthenticated ? children : <LoginScreen />;
};

export default AdminRoute;
