import React, { useState } from "react";

import styles from "./ForgetPasswordPage.module.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import axiosConfig from "../../axios/axiosConfig";
import ReactHotToaster from "../../components/common/ReactHotToaster";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSubmitEmail = async () => {
    // check if email is registered, else send error response.
    // if success, show toast message, route to 'verify-email-code'

    try {
      const res = await axiosConfig({
        method: "get",
        url: `/enroll/forgot_password?email=${email}`,
        // data: { email: email },
      });

    
      if (res.status === 200 || res.statusText === "OK") {
        toast.success('An email verification code has been sent to your email.');
        navigate("/verify-email-code", { state: { email } });
      }
    } catch (error) {
      console.log(error.response.data.response);
      toast.error(error.response?.data?.response || "Something went wrong");
    }
  };

  const disbleBtn = !email || email.trim() === "";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 4,
        padding: "0 1rem",
        // backgroundColor: 'yellow'
      }}
    >
      <h1>Forget Password</h1>

      <Box
        component="form"
        sx={{
          width: "50%",
          minWidth: 400,
          display: "flex",
          flexDirection: "column",
          margin: "1rem 0",
          // backgroundColor: 'red'
        }}
        noValidate
        autoComplete="off"
      >
        <Typography>
          Type your registered email address as on your TNIBro account.
        </Typography>

        <TextField
          size="small"
          id="email"
          label="Email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          sx={{
            margin: "1rem 0",
          }}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={disbleBtn}
            variant="contained"
            onClick={handleSubmitEmail}
          >
            Submit
          </Button>
        </div>
      </Box>

      {/* REACT HOT TOAST FOR TOAST MESSAGES */}
      <ReactHotToaster /> 
    </Box>
  );
};

export default ForgetPasswordPage;
