import React from "react";

import styles from "./CustomDynamicTable01.module.css";
import { useState } from "react";
import HtmlSelectDropdown from "../HtmlSelectDropdown";
import SocialShare from "../SocialShare";
import { Button, Stack, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from "@mui/icons-material/Share";
import { Link } from "react-router-dom";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import toast from "react-hot-toast";

// This Table is not dynamic as the rows data needs to be changed accordingly
// Table being used in HomePage '/' component

const tableHeaders = [
  { id: 1, label: "Symbol", align: "left" },
  { id: 2, label: "Date Suggested", align: "left" },
  { id: 3, label: "Price Suggested", align: "right" },
  { id: 4, label: "% Gain / Loss", align: "right" },
];

// TABLE ROW FORMAT
// const tableRowsData = [
//   { id: 1, name: "RELIANCE", date: "23/04/2023", gain: "5%", random: "abc" },
//   { id: 2, name: "HDFC", date: "23/04/2023", gain: "2%", random: "ujy" },
//   { id: 3, name: "TATASTEEL", date: "23/04/2023", gain: "9%", random: "plo" },
//   { id: 4, name: "MAZDOCK", date: "23/04/2023", gain: "3%", random: "oiu" },
// ];

// "cash", "F&O", "futures", "nifty 100", "nifty 200", "nifty 50", "nifty 500"
const SEGMENT_OPTIONS = [
  { id: 1, label: "Cash", value: "cash" },
  { id: 2, label: "F&O", value: "fo" },
  { id: 3, label: "Futures", value: "futures" },
  { id: 4, label: "Nifty 50", value: "nifty50" },
  { id: 5, label: "Nifty 100", value: "nifty100" },
  { id: 6, label: "Nifty 200", value: "nifty200" },
  { id: 7, label: "Nifty 500", value: "nifty500" },
];

const CustomDynamicTable01 = ({
  tableTitle = "Table Heading",
  tableRowsData,
  linkTo = "",
  selectedSegment,
  setSelectedSegment,
}) => {
  // const [stockData, setStockData] = useState(tableRowsData);
  const [value, copy] = useCopyToClipboard();

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{tableTitle}</h1>

          <HtmlSelectDropdown
            // title="Select Segment"
            selectName="Segment Options"
            placeholder="Select Segment"
            // defaultOption="cash"
            defaultOptionVisible={false}
            optionsArray={SEGMENT_OPTIONS}
            selectedOption={selectedSegment}
            setSelectedOption={setSelectedSegment}
          />
        </div>

        <div className={styles.shareContainer}>
          <SocialShare linkTo={linkTo} />

          <Stack>
            <Button size="medium" variant="contained" startIcon={<ContentCopyIcon />} onClick={() => {
                  const fullURL = "http://faircopy.tnibro.com" + linkTo;
                  copy(fullURL);
                  toast.success("Referral Link copied to clipboard");
                }} >
              copy
            </Button>
          </Stack>
        </div>
      </div>

      {/* SELECT SEGMENT DROPDOWN */}
      {/* <div className={styles.select_segment_container}>
        <HtmlSelectDropdown
          // title="Select Segment"
          selectName="Segment Options"
          placeholder="Select Segment"
          // defaultOption="cash"
          defaultOptionVisible={false}
          optionsArray={SEGMENT_OPTIONS}
          selectedOption={selectedSegment}
          setSelectedOption={setSelectedSegment}
        />
      </div> */}

      <div className={styles.tableContainer} >
        <table className={styles.table} style={{
          // height: '800px'
          // width: '100%'
          }}>
          <thead className={styles.tableHeader} >
            <tr className={styles.tableRow}>
              {tableHeaders.map((tableHead) => {
                return (
                  <th align={tableHead.align} key={tableHead.id}>
                    {tableHead.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableRowsData.map((rowData) => {
              return (
                <tr key={rowData.id} className={styles.tableRow}>
                  <td className={styles.symbolName}>{rowData.name}</td>
                  <td style={{ minWidth: "80px" }}>
                    {/* {convertDate(rowData.suggested_date)} */}
                    {new Date(rowData.suggested_date).toDateString()}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {rowData.suggested_price}
                  </td>
                  <td
                    style={{
                      textAlign: "right",
                      color: getColor(rowData.price_prcnt_gain),
                    }}
                  >
                    {getSign(rowData.price_prcnt_gain)}
                    <span style={{ fontSize: "10px" }}>%</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Link to={linkTo}>
        <Stack
          sx={{
            backgroundColor: "#339502",
            color: "white",
            // pl: "15px",
            p: "10px"
          }}
        >
          <Typography sx={{ textAlign: "center", textTransform: 'uppercase', fontWeight: 400 }}>
            Click here to see all Recommendations
          </Typography>
        </Stack>
      </Link>

      
    </div>
  );
};

export default CustomDynamicTable01;

// UTILITY FUNCTION
// show 'today' if today's date
const convertDate = (dateString) => {
  const todayDate = new Date().toDateString();
  const inputDate = new Date(dateString).toDateString();

  if (todayDate === inputDate) {
    return "TODAY";
  }

  return dateString;
};

// if a number is positive, return green, else red
function getColor(num) {
  if (!num) {
    return "";
  }

  if (parseFloat(num) >= 0) {
    // color green (TNIBRO)
    return "#339502";
  }
  // color red (TNIBRO)
  return "#CF304A";
}

// get positive sign if number positive
function getSign(num) {
  if (!num) {
    return "";
  }

  if (num >= 0) {
    return `+${num}`;
  }

  return num;
}
