import React from 'react'
import { useSelector } from 'react-redux';
import AdsForm from '../../components/ads/AdsForm'
import AccessDenied from '../../components/common/AccessDenied';
import CustomTable from '../../components/htmlComps/CustomTable'

const AdsPage = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  // ACCESS DENIED, NOT AUTHORIZED FOR NON-ADMIN
  if (userDetails?.user_type !== "Admin") {
    return <AccessDenied />;
  }

  return (
    <div>
      <h2>Ads Page</h2>
      <AdsForm />

      <CustomTable />
    </div>
  );
}

export default AdsPage