import axiosConfig from '../axios/axiosConfig';

// FETCH ALL ALERTS FOR THE USER
export const fetchUserAlerts = async (USERID, page_number, rows_per_page) => {
  const res = await axiosConfig({
    method: "get",
    url: `tnibroadmin/alert?userid=${USERID}&page_no=${page_number}&per_page=${rows_per_page}`,
    data: {},
  });

  return res.data;
  
};



// FETCHING ALL ALERTS FOR SUPER ADMIN
export const fetchAllAlertsData = async (USERID) => {
  const res = await axiosConfig({
    method: "get",
    url: `/admin_alert?userid=${USERID}`,
    data: {},
  });

  return res.data;
}