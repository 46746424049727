export const optionsShowingInputField = [
  "macd-histogram",
  "macd-signal",
  "macd-line",
  "supertrend",
  "rsi",
  "upper-bollinger-band",
  "lower-bollinger-band",
  "sma_atr",
  "rma_atr",
  // "vwap",
  "since_day",
  "greatest",
  "lowest",
  "number",
  "max",
  "min",
  "open_pct",
  "high_pct",
  "low_pct",
  "close_pct",
  "volume_pct",
];

export const optionsWithoutDefaultInput = [
  "vwap"
]

export const timeFrameShowingAlert = ["ago"];

export const optionsShowingMultipleFields = ["ema"];

export const timeFrameShowingIntraday = [
  "5-min",
  "10-min",
  "15-min",
  "30-min",
  "45-min",
  // "1-hour",
  "60-min",  // changed from '1-hour' to '60-min', as per backend
];

export const indicatorWithoutLatestTextField = ["number"];

export const indicatorsWithGapBetweenOerator = [
  "macd-histogram",
  "macd-signal",
  "macd-line",
  "rsi",
  "upper-bollinger-band",
  "lower-bollinger-band",
  "ema",
];


