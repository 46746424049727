import React from 'react';

import { Box, Container, Stack, Typography, styled } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { grey } from "@mui/material/colors";

const tabsList = [
  { index: 0, value: "cash", label: "All Stocks" },
  { index: 1, value: "nifty50", label: "Nifty 50" },
  { index: 2, value: "nifty100", label: "Nifty 100" },
  { index: 3, value: "nifty200", label: "Nifty 200" },
  { index: 4, value: "nifty500", label: "Nifty 500" },
  { index: 5, value: "fo", label: "F&O" },
  { index: 6, value: "mcx", label: "MCX" },
  { index: 7, value: "futures", label: "Futures" },
];

// "nifty100","nifty200","nifty500"

const allStyles = {
  tabs: {
    // border: "1px solid gray",
    // borderRadius: 2,
  },
  tab: {
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    // borderRadius: 2,
    // margin: 1,
    // borderTop: "2px solid"
    backgroundColor: "#339502",
  },
  tableTitle: {
    margin: "2rem 0 1rem",
    padding: "0.5rem",
    // border: "1px solid gray",
    borderRadius: 2,
    display: "inline-block",
    textTransform: "uppercase",
    fontWeight: 200,
    // backgroundColor: "#2196f3",
    // color: "blue"
  },
};

// Custom Styling for Tab
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    // textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    // color: 'rgba(0, 0, 0, 0.85)',
    color: "white",
    margin: 0,
    // backgroundColor: lightBlue[300],

    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      // color: '#40a9ff',
      color: "#fff",
      opacity: 1,
      // backgroundColor: "#40a9ff",
      backgroundColor: "#337902",
      // borderTop: "2px solid #40a9ff"
    },
    "&.Mui-selected": {
      // color: '#1890ff',
      color: "#333",
      // backgroundColor: lightBlue[50],
      backgroundColor: "white",
      borderTop: "2px solid #333",
      // borderBottom: '4px solid #333',
      borderBottom: `3px solid ${grey[700]}`,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      // backgroundColor: '#d1eaff',
    },
  })
);

const MuiRecomTabs = ({ selectedSegmentTab, handleTabChange }) => {
  return (
    <Box sx={{ maxWidth: { xs: 480, sm: "100%" }, mt: 4}}>
        <Tabs
          sx={allStyles.tabs}
          value={selectedSegmentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs"
        >
          {tabsList.map((tab) => {
            return (
              <AntTab
                sx={allStyles.tab}
                // className={styles.tab}
                key={tab.index}
                value={tab.value}
                label={tab.label}
              />
            );
          })}
        </Tabs>
      </Box>
  )
}

export default MuiRecomTabs