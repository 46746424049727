import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";

import axiosConfig from '../../axios/axiosConfig';

// import addonsData from './addonsData';
// import subscriptionData from './subscriptionData';
import styles from "./CartPage.module.css";
import { displayRazorpay } from "../../utils/razorpay/checkout";
import { useSelector } from "react-redux";
import PayPalMain from "../../components/payment-gateways/PayPalMain";
import ReactHotToaster from "../../components/common/ReactHotToaster";
import { useQuery } from "@tanstack/react-query";


const addOnsList = [
  {
    id: 1,
    label: "Commodities",
    value: "mcx",
    checked: false,
    price: 5,
  },
  { id: 2, label: "Crypto", value: "crypto", checked: false, price: 10 },
  {
    id: 3,
    label: "Options with Chart & Signals",
    value: "options",
    checked: false,
    price: 5,
  },
  {
    id: 4,
    label: "F&O",
    value: "f&o",
    checked: false,
    price: 5,
  },
  {
    id: 5,
    label: "Cash",
    value: "cash",
    checked: false,
    price: 15,
  },
  {
    id: 6,
    label: "Nifty 50",
    value: "nifty_50",
    checked: false,
    price: 10,
  },
  {
    id: 7,
    label: "FOREX",
    value: "forex",
    checked: false,
    price: 5,
  },
  {
    id: 8,
    label: "Simulator",
    value: "simulator",
    checked: false,
    price: 10,
  },
];

function CartPage() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;
  const location = useLocation();
  const selectedPlanDetails = location?.state
  const { planPeriod, selectedTier, subscriptionType, totalBasePlanAmount } = selectedPlanDetails;
  const SELECTED_PLAN = location?.state?.selectedTier.title;   // 'basic', 'premium', etc.
  
  const [couponDiscountPercent, setCouponDiscountPercent] = useState(0);  // DISCOUNT IN PERCENTAGE

  const [couponCodeInput, setCouponCodeInput] = useState("");
  const [couponDetails, setCouponDetails] = useState({});
  
  

  // const handleAddonChange = (event) => {
  //   const addonName = event.target.name;
  //   const isAddonSelected = event.target.checked;

  //   if (isAddonSelected) {
  //     setSelectedAddons((prevSelectedAddons) => [
  //       ...prevSelectedAddons,
  //       addonsData.find((addon) => addon.name === addonName),
  //     ]);
  //   } else {
  //     setSelectedAddons((prevSelectedAddons) =>
  //       prevSelectedAddons.filter((addon) => addon.name !== addonName)
  //     );
  //   }
  // };

  // const calculateTotalPrice = () => {
  //   const basePrice = subscription.price;
  //   const addonsPrice = selectedAddons.reduce(
  //     (total, addon) => total + addon.price,
  //     0
  //   );
  //   return basePrice + addonsPrice;
  // };

  // const [selectedAddOns, setSelectedAddOns] = useState(addOnsList); // An Array of AddOns
  const [allAddOns, setAllAddOns] = useState(addOnsList); // Total AddOns List
  const selectedAddOns = allAddOns.filter(item => item.checked)
  

  const handleAddOnsChange = (event) => {
    const newAddOnsList = allAddOns.map((addOn) => {
      if (addOn.value === event.target.value) {
        return { ...addOn, checked: event.target.checked };
      }
      return addOn;
    });
    // setSelectedAddOns(newAddOnsList);
    setAllAddOns(newAddOnsList);
  };

  // Calculate total amount of Plan + Add Ons
  const calculateTotalAmount = () => {
    // calculate Total Price for Add Ons
    let addOnsTotalPrice = allAddOns
      .filter((item) => item.checked)
      .reduce((total, item) => item.price + total, 0);

    if (planPeriod.toLowerCase() === 'yearly') {
      addOnsTotalPrice *= 12;
    }

    const final_amount = addOnsTotalPrice + (location?.state?.totalBasePlanAmount || 0);
    return final_amount.toFixed(2);
  };

  // PRICE & GST CALCULATIONS (monthly vs yearly)
  // const BASE_PRICE = location?.state?.selectedTier.price;
  const total_amount = calculateTotalAmount(); // without discount & GST
  const discount_applicable = (total_amount * (couponDiscountPercent / 100)).toFixed(2)
  // const total_payable_amount = parseFloat(total_amount - discount_applicable);  // after discount if any
  // const total_payable_amount = parseFloat((total_amount - discount_applicable).toFixed(2))  // after discount if any
  const total_payable_amount = Math.round(total_amount - discount_applicable)  // after discount if any
  const total_without_gst = total_payable_amount / 1.18;
  const GST = total_without_gst * 0.18;
  
  // const handleSubscribe = async () => {
  //   // Adds Ons Array"
  //   let addOn_String;
  //   addOn_String = allAddOns
  //     .filter((item) => item.checked)
  //     .map((item) => item.value)
  //     .join();
    
  //   // if (!addOn_String || addOn_String === "") return;

  //   const data_params = {
  //     plan_type: SELECTED_PLAN,
  //     // add_on: addOn_String,
  //     userid: USERID,
  //     // payment: 55,
  //     // user_coupon: "ABCD"
  //   }

  //   if (addOn_String?.length !== 0) {
  //     data_params.add_on = addOn_String;
  //   }

  //   // console.log("data_params: ", data_params)
    
  //   try {
  //     const res = await axiosConfig({
  //       method: "post",
  //       url: "/subscription/user_subscription",
  //       data: data_params,
  //     });

  //     console.log(res);
  //     if (res.status === '200' || res.statusText === 'OK') {
        
  //       // displayRazorpay(USERID, total_payable_amount, 'INR');
  //       toast.success("Subcribed Successfully!", {
  //         duration: 6000,
  //         position: "top-center",
  //       });
  //     }
      
  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error?.response?.data?.response || "Something went wrong");
  //   }
  // };

  
  // IMP: subscriptionType IS 'NEW_PLAN' OR 'RENEW_PLAN' -> Need for post vs patch payment
  // HANDLE MAKE PAYMENT (CONFIRM & PAY)
  const makePayment = async () => {
    try {
      const paymentResponse = await displayRazorpay(USERID, total_payable_amount, 'INR', SELECTED_PLAN, allAddOns, couponDetails.id, subscriptionType, planPeriod);
      // handleSubscribe();
      console.log("paymentResponse: ", paymentResponse)
    } catch (error) {
      console.log("makePayment error: ", error)
    }
  }

  
  // FETCHING COUPON DETAILS API REQUEST
  const fetchCouponDetailQuery = useQuery({
    queryKey: ["coupon-detail"],
    queryFn: () => getCouponDetail(couponCodeInput),
    onSuccess: (data) => {
      setCouponDetails(data.response)
      const intDiscount = parseInt(data.response.discount);
      setCouponDiscountPercent(intDiscount);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
    enabled: false
  });

  // HANDLE APPLY COUPON BUTTON 
  const handleApplyCoupon = () => {
    // referral/coupon_validation?coupon_code=
    fetchCouponDetailQuery.refetch();
  }

  return (
    <div className={styles.cartPage}>
      <div className={styles.planDetails}>
        <h2>Selected Plan: {SELECTED_PLAN}</h2>
        {/* <p>{subscription.description}</p> */}
        <p>Base Price: {totalBasePlanAmount}/- <strong>{location?.state?.planPeriod.toUpperCase()}</strong></p>
      </div>

      {/* <div style={{ display: 'none'}} className={styles.addonDetails}>
        <h3>Add-Ons</h3>
        {addonsData.map((addon) => (
          <div key={addon.name}>
            <label>
              <input
                type="checkbox"
                name={addon.name}
                checked={allAddOns.some(
                  (selectedAddon) => selectedAddon.name === addon.name
                )}
                onChange={handleAddonChange}
              />
              {addon.name} (${addon.price})
            </label>
          </div>
        ))}

        <div className={styles.total}>
          <p>Total: ${calculateTotalPrice()}</p>
        </div>
      </div> */}

      {/* MAIN CONTAINER */}
      <div className={styles.main_Container}>
        {/* CART CONTAINER ON THE LEFT */}
        <div className={styles.cart_Container}>
          <h3>Select Add Ons</h3>
          
            <Stack
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // minWidth: 400,
              }}
            >
              <Typography variant="h5">ADD ONs</Typography>
              <Typography variant="h5">Price</Typography>
            </Stack>

            <FormGroup>
              {allAddOns.map((addOn) => {
                return (
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // minWidth: 400,
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <FormControlLabel
                      key={addOn.id}
                      control={
                        <Checkbox
                          sx={{ padding: "0.5rem 0", marginRight: '0.5rem' }}
                          value={addOn.value}
                          checked={addOn.checked}
                          onChange={handleAddOnsChange}
                          // inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={addOn.label}
                    />

                    <Typography variant="subtitle">{addOn.price}/-</Typography>
                  </Stack>
                );
              })}
            </FormGroup>
          
        </div>

        {/* PRICE CONTAINER ON THE RIGHT {3 DIVS, TOP, middle, payment} */}
        <div className={styles.right_price_Container}>
          {/* TOP COUPON CONTAINER */}
          <div className={styles.coupon_Container}>
            <p>COUPON ?</p>
            <Stack direction="row" spacing={1} >
              <TextField size="small" id="coupon-input" variant="outlined" value={couponCodeInput} onChange={(e) => setCouponCodeInput(e.target.value)} />
              <Button size="small" variant="contained" onClick={handleApplyCoupon} >
                Apply
              </Button>
            </Stack>
          </div>

          {/* BOTTOM PRICE CONTAINER - base price, Add Ons, Discount, GST */}
          <div className={styles.bottom_Price_Container}>
            {/* <div className={styles.price_container}>
              <p className={styles.price_text}>Total Price:</p>
              <p className={styles.amount}>${total_amount}</p>
            </div> */}
            <div className={styles.price_container}>
              <p className={styles.price_text}>Base Plan:</p>
              <p className={styles.amount}>{totalBasePlanAmount.toFixed(2)}</p>
            </div>

            {/* ADD ONS LIST */}
            {selectedAddOns?.map((item) => {
              return (
                <div key={item.id} className={styles.price_container}>
                  <p className={styles.price_text}>{item.label}:</p>
                  {/* <p className={styles.amount}>(+) ${planPeriod === 'yearly' ? item.price.toFixed(2) * 12.00 : item.price.toFixed(2)}</p> */}
                  <p className={styles.amount}>(+) {planPeriod === 'yearly' ? (item.price * 12).toFixed(2) : item.price.toFixed(2)}</p>
                </div>
              )
            })}

            {/* DISCOUNT */}
            <div className={styles.price_container}>
              <p className={styles.price_text}>Coupon Discount <span style={{ fontSize: '0.8rem'}}>({couponDiscountPercent}%)</span> :</p>
              <p className={styles.amount}>(-) {discount_applicable}</p>
            </div>

            {/* GST */}
            {/* <div className={styles.price_container}>
              <p className={styles.price_text}>GST (18%):</p>
              <p className={styles.amount}>(+) ${GST}</p>
            </div> */}

            {/* TOTAL PAYABLE */}
            <div className={`${styles.price_container} {styles.total_price_container}`}> 
              <p className={styles.price_text}>TOTAL <span style={{ fontSize: '0.8rem'}}>(inc. 18% GST)</span>:</p>
              <p className={styles.payable_amount}>{total_payable_amount}</p>
            </div>
          </div>

          <div className={styles.payment_container}>
          
            {/* Getting Location state from SubscriptionPlan ('/pricing') component */}
            <Typography variant="h4">
              Total Payable: <strong>{total_payable_amount}</strong>
            </Typography>
            <Button variant="contained" onClick={makePayment} sx={{ mt: 2, mb: 2 }}>
              {subscriptionType === "NEW_PLAN" && "Confirm & Pay"}
              {subscriptionType === "RENEW_PLAN" && "Renew"}
            </Button>
            {/* <Button variant="contained" onClick={() => displayRazorpay(USERID, total_payable_amount, 'INR')} sx={{ mt: 2 }}>
              TEST RAZORPAY
            </Button> */}

            <PayPalMain total_payable_amount={total_payable_amount} />
          
          </div>
        </div>
      </div>

      {/* React Toast */}
      <ReactHotToaster />
    </div>
  );
}

export default CartPage;


// UTLILITY / API FUNCTIONS
const getCouponDetail = async (coupon_code) => {
  if (!coupon_code) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `referral/coupon_validation?coupon_code=${coupon_code}`,
    data: {},
  });

  return res.data;
};