import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../services/auth-header";
import toast from "react-hot-toast";

import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import MonitorIcon from "@mui/icons-material/Monitor";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import AddIcon from "../assets/plus.png";

import { Autocomplete, Button, TextField } from "@mui/material";

import {
  optionsShowingInputField,
  optionsShowingMultipleFields,
  optionsWithoutDefaultInput,
  timeFrameShowingIntraday,
} from "../utils/Macd";
import {
  refreshTimeOptions,
  rowsPerPageOptions,
} from "../utils/screener-dropdown-options";
import {
  screenerSetOptions,
  screenerSetDefaultValues,
} from "../utils/screener-set-as";

import NewScan from "../components/main/NewScan";
import ScanHeader from "../components/ScanHeader/ScanHeader";
import DenseTable from "../components/Tables/Tables";



import BasicButton from "../components/Button/BasicButton";
import ControlledCheckbox from "../components/Checkbox/Checkbox";
import SaveScanModal from "./SaveScanModal";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Loader from "../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import NoResultsToDisplay from "../components/common/NoResultsToDisplay";
// import FilterByStockCategories from "../components/common/FilterByStockCategories";
import ReactHotToaster from "../components/common/ReactHotToaster";
import { setTotalStocks } from "../reduxStore/actions/pagination";

const Screener = (props) => {
  const {
    myScanQuery,
    segmentQuery,
    queryNameDesc,
    screenerId,
    handleUpdateScreener,
    handleScannerPublishToApp,
    isPublished,
    tableMarginTop,
    latestStocks,
  } = props;

  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  // const { currentPage, rowsPerPage } = useSelector((store) => store.pagination);
  const dispatch = useDispatch();

  const [screenerSetAs, setScreenerSetAs] = useState(screenerSetDefaultValues); // screenerSetAs:  {set_recommended: false}

  // STATES FOR SOFT BUY, BUY, STRONG BUY
  const recom_Options = [
    { id: 1, label: "Soft Buy", value: "soft_signal" },
    { id: 2, label: "Buy", value: "moderate_signal" },
    { id: 3, label: "Strong Buy", value: "strong_signal" },
  ];

  const initialSignalValue = screenerSetAs.set_recommended
    ? recom_Options[0].value
    : null;
  const [selectedRecommendation, setSelectedRecommendation] =
    useState(initialSignalValue);

  // const [value, setValue] = React.useState();
  const [recomInputValue, setRecomInputValue] = useState("");

  // displayFilterData is an ARRAY OF SCREENERS / QUERIES, screener 0 is the default 1st Screener.
  const [displayFilterData, setDisplayFilterData] = useState([{
    comparison_operator: {operator: 'greater_than'},
    disabled: false,
    measure1: "close",
    measure2: "close",
    timeframe1: {timeframe_offset: 'latest'},
    timeframe2: {timeframe_offset: '1-day-ago'}
  }]);
  const [screenerResult, setScreenerResult] = useState(null);

  const [isSaveToDashbaord, setIsSaveToDashbaord] = useState(false);
  
  // console.log("displayFilterData: ", displayFilterData);
  const [combination, setCombination] = useState("pass");
  const [segment, setSegment] = useState(segmentQuery || ""); // 'segmentQuery' coming from <SavedScan />

  // const [isLatestOn, setIsLatestOn] = useState(true);
  // const [refreshTime, setRefreshTime] = useState(60000);

  const [openSaveScanModal, setOpenSaveScanModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [emptyObj, setEmptyObj] = useState();
  // const [stockSearchText, setStockSearchText] = useState("");

  // this state is for search filter//
  const [stockList, setStockList] = useState([]);
  const [userAlertList, setUserAlertList] = useState([]); // list of all applied alerts for user

  // Stock category filter component
  const [selectedStockCategory, setSelectedStockCategory] = useState("");

  // *********** STATES FOR FILTERING TABLE   ********************
  const initialSelectedFiltersState = {
    previous_pattern: "",
    current_pattern: "",
    close_open_pct_operator: "",
    close_open_pct_value: "",
    fiftytwo_weeks_high_operator: "",
    fiftytwo_weeks_high_value: "",
    fiftytwo_weeks_low_operator: "",
    fiftytwo_weeks_low_value: "",
  };
  // const [selectedFilters, setSelectedFilters] = useState(
  //   initialSelectedFiltersState
  // );

  const navigate = useNavigate();

  const handleFormData = (value, key, id) => {
    // 1st argument 'value' is the option selected like 'max', 'min', 'high', 'low', 'open_pct, etc.
    // 2nd argument 'key' is the type of Autocomplete comp, e.g. 'measure1', 'timeframe1', 'offset1'
    const temp = displayFilterData.map((obj, index) => {
      // 'id' is the Screener id, starting from 0
      if (index === id) {
        // if the value is a macd option under measure1 or measure2

        if (
          optionsShowingInputField.indexOf(value) !== -1 ||
          optionsShowingMultipleFields.indexOf(value) !== -1 ||
          optionsWithoutDefaultInput.indexOf(value) !== -1
        ) {
          obj[key] = { indicator: value };
          // if (value === 'supertrend') {
          //   obj[key] = { ...obj[key], parameter: "close",  }
          // }
          if (value === "supertrend" || value === "since_day") {
            obj[key] = { ...obj[key], parameter: "close" };
          }

          if (value === "vwap") {
            obj[key] = { ...obj[key], parameter: "close", ndays: 0 };
          }
        } else {
          obj[key] = value;
        }

        if (key === "comparison_operator") {
          obj[key] = { operator: value };
        }

        // THIS SECTION NEEDS TO REVISIT//
        if (key === "timeframe1" || key === "timeframe2") {
          if (timeFrameShowingIntraday.indexOf(value) !== -1) {
            obj[key] = { ...displayFilterData[key], timeframe_offset: value };
          } else {
            obj[key] = { timeframe_offset: value };
          }
        }

        if (key === "n-timeframe1" || key === "n-timeframe2") {
          const timeFrameKey = key.split("-")[1];
          const nTimeFrameValue = obj[timeFrameKey].timeframe_offset.split("-");
          nTimeFrameValue[0] = value;
          obj[timeFrameKey] = { timeframe_offset: nTimeFrameValue.join("-") };
        }
        // SET TIMEFRAME TO NULL IF MEASURE IS SELECTED AS NUMBER//
        if (value === "number") {
          if (key === "measure1") {
            obj.timeframe1 = null;
          }
          if (key === "measure2") {
            obj.timeframe2 = null;
          }
        }
      }
      return obj;
    });
    setDisplayFilterData(temp);
  };

  // HANDLES TEXTFIELD INPUTS (for measure1 and measure2)
  const handleFormInputValues = (value, key, id, title) => {
    // value: TextField Value, 2) key: indicator / selected Option in Autocomplete, 4) title: autocomplete type, 'measure1', 'measure2', etc.
    // value: Integer input in tExtField, key: measure like 'max' 'min', title: 'measure1', 'measure2'

    let copiedObject = JSON.parse(JSON.stringify(displayFilterData));

    const temp = copiedObject.map((obj, i) => {
      if (i === id) {
        // for below list of indicators, ndays should be passed inside an Array
        const parameterCloseArray = [
          "macd-line",
          "macd-signal",
          "macd-histogram",
          "supertrend",
          "ema",
          "rsi",
        ];
        // optionsShowingInputField.indexOf(key) !== -1) means if 'key' exists in this array
        if (optionsShowingInputField.indexOf(key) !== -1) {
          if (parameterCloseArray.includes(key)) {
            obj[title].ndays = value?.split(",");
            obj[title].parameter = "close";
          } else {
            // if stock percentage is selected in measure 1 or 2
            obj[title].ndays = value;
            obj[title].parameter = "close";
          }
        }
      }
      return obj;
    });
    setDisplayFilterData(temp);
  };

  const handleEmaValues = (emamsr, emadays, key, id, title) => {
    let copiedObject = JSON.parse(JSON.stringify(displayFilterData));

    const temp = copiedObject.map((obj, i) => {
      if (i === id) {
        if (optionsShowingMultipleFields.indexOf(key) !== -1) {
          obj[title].parameter = emamsr;
          obj[title].ndays = emadays;
        }
      }
      return obj;
    });
    setDisplayFilterData(temp);
  };

  const handleComparisonGapbetween = (value, key, id) => {
    let copiedObject = JSON.parse(JSON.stringify(displayFilterData));

    const temp = copiedObject.map((obj, index) => {
      if (index === id) {
        if (key === "comparison_operator") {
          obj[key].range = value?.split("-");
        }
      }
      return obj;
    });
    setDisplayFilterData(temp);
  };

  const handleIntradayTimeframe = (value, key, id) => {
    let copiedObject = JSON.parse(JSON.stringify(displayFilterData));

    const temp = copiedObject.map((obj, i) => {
      if (i === id) {
        obj[key].intraday = value;
        // COMMENTED OUT TEMPORARILY AS BELOW CODE SEEMED REDUNDANT
        // if (
        //   ["0", "-1", "-2", "-3", "-4", "-5", "-6", "-7", "-8", "1"].indexOf(
        //     value
        //   ) !== -1
        // ) {
        //   obj[key].intraday = value;
        // }
      }
      return obj;
    });
    setDisplayFilterData(temp);
  };

  const handleScreenerSetAs = (val, key) => {
    // let temp = { ...screenerSetAs };
    // temp[key] = val;
    // setScreenerSetAs(temp);

    setScreenerSetAs({ set_recommended: val });
  };

  const handleDuplicateQuery = (id) => {
    const objToDuplicate = displayFilterData.find((Obj, i) => {
      if (i === id) {
        return Obj;
      }
    });
    setDisplayFilterData((oldValues) => [...oldValues, { ...objToDuplicate }]);
  };

  const handleCombinationValue = (val) => {
    setCombination(val);
  };

  const handleSegmentValue = (val) => {
    setSegment(val);

    // IMPORTANT :- I'VE COMMENTED OUT THE BELOW CODE AS TIMEFRAME/OFFSET WAS CHANGING WHILE SEGMENT CHANGED

    // NULLIFY or put default values in 'select an offset' when 'SEGMENT' is changed
    // SET DEFAULT VALUES WHEN SEGMENT VALUE IS CHANGED IN SCANHEADER
    // const newDisplayFilterData = displayFilterData.map((item) => {
    //   if (item.id === screenerId) {
    //     return {
    //       ...item,
    //       // timeframe1: null,
    //       // timeframe2: null,
    //       // timeframe1: { intraday: "0", timeframe_offset: "15-min" },
    //       // timeframe2: { intraday: "0", timeframe_offset: "15-min" },
    //       timeframe1: { timeframe_offset: "latest" },
    //       timeframe2: { timeframe_offset: "latest" },
    //     };
    //   }

    //   return item;
    // });

    // setDisplayFilterData(newDisplayFilterData);
  };

  // REACT QUERY useMutation() hook to post screener and get stocks data
  const screenerData = useMutation({
    mutationFn: async () => {
      const dataWithFalseDisable = displayFilterData.filter(
        (obj) => obj.disabled === false
      );
      const response = await axios.post(
        // `/screenerapi?page_no=${currentPage}&per_page=${rowsPerPage}`,
        "/screenerapi",
        {
          segment: segment,
          set_recommended: screenerSetAs.set_recommended,
          filters: [...dataWithFalseDisable],
          userid: userDetails?.id,
        },
        { headers: authHeader() }
      );
      const data = response.data;
      // console.log("response screener: ", response?.data?.total_length);
      dispatch(setTotalStocks(response?.data?.total_length));
      return data;
    },
    onSuccess: (data) => {
      setScreenerResult(data.response);
      setStockList(data.response);
      setUserAlertList(data?.user_alert);
      // toast.success("Screener saved successfully!");
    },
    onError: (error) => {
      // console.log("screener error: ", error);
      toast.error(
        error.response?.data?.response ||
          error.message ||
          "Something went wrong"
      );
    },
  });

  const handleScanSubmit = () => {
    // setIsLatestOn(false);
    setIsLoading(true);
    setScreenerResult(null);
    setStockList([]);

    const isEmpty = handleIsObjEmpty(displayFilterData);
    if (isEmpty) {
      toast.error("please fill fields before run");
      setIsLoading(false);
      return;
    }

    screenerData.mutate();

    // const dataWithFalseDisable = displayFilterData.filter(
    //   (obj) => obj.disabled === false
    // );

    // axios
    //   .post(
    //     "/screenerapi",
    //     {
    //       segment: segment,
    //       set_recommended: screenerSetAs.set_recommended,
    //       filters: [...dataWithFalseDisable],
    // userid: userDetails?.id
    //     },
    //     { headers: authHeader() }
    //   )
    //   .then((response) => {
    //     setScreenerResult(response.data.status);
    //     setStockList(response.data.status);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     toast.error(error?.response?.data?.response || "Something went wrong");
    //     setIsLoading(false);
    //   });
  };

  const handleDeleteQuery = (id) => {
    const tempArr = [...displayFilterData];
    tempArr.splice(id, 1);
    setDisplayFilterData(tempArr);
  };

  const handleStockSearch = (field, query) => {
    // setStockSearchText(query);

    if (query !== "" || query !== undefined) {
      const filteredStockList = screenerResult.filter((stock) =>
        stock.symbol.toLowerCase().includes(query)
      );
      setStockList(filteredStockList);
      return;
    }
    setStockList(screenerResult);
  };

  const handleSaveScanModal = () => {
    setOpenSaveScanModal(true);
  };

  const objIsSame = (obj1, obj2) => {
    let same;
    if (obj1 && obj2) {
      same = _.isEqual(obj1, obj2);
    }
    return same;
  };

  // saved_query_details = {scan_name: '', scan_description: ''} from 'SaveScanModal'
  const handleSaveScanScreener = (saved_query_details) => {
    const dataWithFalseDisable = displayFilterData.filter(
      (obj) => obj.disabled === false
    );

    if (screenerId) {
      const screenerQueryIsSame = objIsSame(myScanQuery, displayFilterData);
      const segmentQueryIsSame = objIsSame(segmentQuery, segment);
      const screenerNameDescIsSame = objIsSame(
        queryNameDesc,
        saved_query_details
      );

      const editedFields = {};

      if (!segmentQueryIsSame) {
        editedFields.segment = segment;
      }
      if (!screenerNameDescIsSame) {
        editedFields.scan_description = saved_query_details.scan_description;
        editedFields.scan_name = saved_query_details.scan_name;
        editedFields.hide_query = saved_query_details?.hide_query;
      }
      if (!screenerQueryIsSame) {
        const dataWithFalseDisable = displayFilterData.filter(
          (obj) => obj.disabled === false
        );
        const convertToString = JSON.stringify(dataWithFalseDisable);

        editedFields.scanner_query = convertToString;
      }

      handleUpdateScreener(editedFields, screenerId);
    } else {
      // SAVE SCAN
      axios
        .post("/tnibroadmin/scanner", {
          userid: userDetails.id,
          ...saved_query_details,
          segment: segment,
          set_recommended: screenerSetAs.set_recommended,
          scanner_query: JSON.stringify([...dataWithFalseDisable]),
          recommended_signal: screenerSetAs?.set_recommended
            ? selectedRecommendation
            : null, // 'soft_signal', 'strong_signal', etc.
        })
        .then((response) => {
          if (response.data.status === "success") {
            const scanId = response.data.response.id;
            toast.success("Scan saved!");
            // navigate(`/savedscan?id=${id}`);
            
            if (isSaveToDashbaord) {
              navigate("/dashboards?openModal=true", {
                state: { screenerId: scanId },
              });
            } else {
              navigate(`/savedscan/${scanId}`);
            }
          }
          if (response.data.status === "failed") {
            // toast.error(response.data.response);
          }
        })
        .catch((error) => {
          // console.log("error: ", error.response?.data?.response)
          toast.error(
            error?.response?.data?.response || "Something went wrong"
          );
        });
    }
  };

  const handleIsObjEmpty = (arrayOfObject) => {
    const isEmpty = arrayOfObject?.map((obj) =>
      Object.values(obj).every((value) => {
        if (value === null || value === false) {
          return true;
        }
        return false;
      })
    );

    if (isEmpty) {
      setOpenSaveScanModal(false);
    }
    setEmptyObj(isEmpty[0]);
    return isEmpty[0];
  };

  const handlePublishToApp = () => {
    if (screenerId) {
      let publish;
      isPublished ? (publish = false) : (publish = true);
      handleScannerPublishToApp(publish, screenerId);
    } else {
      toast.error("Please save the scan first!");
    }
  };

  // IMPORTANT: IF 'screenerId' EXISTS (NOT UNDEFINED), RUN THE SCAN
  useEffect(() => {
    if (screenerId && displayFilterData.length !== 0) {
      handleScanSubmit();
    }
  }, [screenerId, displayFilterData.length]);

  useEffect(() => {
    if (myScanQuery !== undefined) {
      const duplicateScanQuery = JSON.parse(JSON.stringify(myScanQuery));
      setDisplayFilterData(duplicateScanQuery);
    }
  }, [myScanQuery]);

  // HANDLE MONITOR TO DASHBOARD CLICK EVENT (route to /dashboards with choose dashboard modal)
  const routeToDashboards = () => {
    if (!screenerId) {
      setIsSaveToDashbaord(true);
      setOpenSaveScanModal(true);
      return;
    }

    navigate("/dashboards?openModal=true", {
      state: { screenerId: screenerId },
    });
  };

  // useEffect(() => {
  //   if (!segment || segment === "") return;

  //   screenerData.mutate();

  // }, [currentPage, rowsPerPage])

  // AUTO REFRESH USEEFFECT
  // useEffect(() => {
  //   if (isLatestOn) {
  //     const interval = setInterval(() => {
  //       screenerData.mutate();

  //     }, refreshTime);

  //     return () => clearInterval(interval);
  //   }
  // }, [isLatestOn, refreshTime]);

  useEffect(() => {
    handleIsObjEmpty(displayFilterData);
  }, [displayFilterData]);

  return (
    <>
      <div>
        <div style={{
          marginBottom: '1rem'
        }}>
          <h2>Stock Screener</h2>
        </div>
        <div>
          {displayFilterData.length > 0 && (
            <ScanHeader
              combinationValue={combination}
              segmentValue={segment}
              handleCombinationValue={handleCombinationValue}
              handleSegmentValue={handleSegmentValue}
            />
          )}

          {/* DISPLAYFILTERDATA is an array of Screeners  */}
          {displayFilterData?.map((screenerPayloadObj, index) => {
            return (
              <NewScan
                // handleFormData={(selectedOption, optionType, id) =>
                //   handleFormData(selectedOption, optionType, id)
                // }
                segment={segment}
                handleFormData={handleFormData}
                screenerPayload_DataObj={screenerPayloadObj}
                handleDuplicateData={handleDuplicateQuery}
                handleDeleteForm={handleDeleteQuery}
                handleTextField={handleFormInputValues}
                id={index}
                setDisplayFilterData={setDisplayFilterData}
                handleEmaFields={handleEmaValues}
                handleIntradayTimeframe={handleIntradayTimeframe}
                handleComparisonGapbetween={handleComparisonGapbetween}
              />
            );
          })}

          {/* COMMENTED OUT, ADDED A BUTTON BELOW */}
          {/* <div>
            <img
              alt="Add Icon"
              src={AddIcon}
              style={{ height: "35px", cursor: "pointer" }}
              onClick={() =>
                setDisplayFilterData((oldValue) => [
                  ...oldValue,
                  {
                    timeframe1: null,
                    measure1: null,
                    comparison_operator: null,
                    timeframe2: null,
                    measure2: null,
                  },
                ])
              }
            />
          </div> */}

          {/* ADD SCREENER BUTTON IN PLACE OF ICON ABOVE */}
          <Button size="small" variant="contained" color="warning" endIcon={<AddCircleOutlineIcon />} onClick={() =>
                setDisplayFilterData((oldValue) => [
                  ...oldValue,
                  {
                    timeframe1: null,
                    measure1: null,
                    comparison_operator: null,
                    timeframe2: null,
                    measure2: null,
                  },
                ])
              }
              sx={{
                mb: 2,
              }}
              >
              add
          </Button>

          {displayFilterData?.length > 0 && (
            <Stack direction="row" spacing={2} gap={1} mt={2} mb={2} flexWrap="wrap">
              <BasicButton title="Run Scan" callback={handleScanSubmit} variant="contained" color="success" />

              <BasicButton
                title="Save Scan"
                variant="contained"
                // color="primary"
                // callback={() => handleSaveScanModal()}
                callback={handleSaveScanModal}
              />

              <BasicButton
                variant="contained"
                title={isPublished ? "UnPublish from App" : "Publish to app"}
                callback={() => handlePublishToApp()}
              />

              <BasicButton
                // title="Monitor on Dashboard"
                title="save to Dashboard"
                color="secondary"
                variant="contained"
                startIcon={<MonitorIcon />}
                callback={routeToDashboards}
              />

              {/* RECOMMENDED CONTROL */}
              <ControlledCheckbox
                options={screenerSetOptions}
                handleOptionChange={handleScreenerSetAs}
              />

              {/* SOFT BUY LIST */}
              {screenerSetAs.set_recommended && (
                <Autocomplete
                  size="small"
                  value={selectedRecommendation}
                  onChange={(event, newValue) => {
                    setSelectedRecommendation(newValue.value);
                  }}
                  inputValue={recomInputValue}
                  onInputChange={(event, newInputValue) => {
                    setRecomInputValue(newInputValue);
                  }}
                  id="recommendation-list"
                  options={recom_Options}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Recommendation" />
                  )}
                />
              )}
            </Stack>
          )}

          {/* screenerResult?.length > 0 && */}
          {/* {true && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: 'wrap'
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => setSelectedFilters(initialSelectedFiltersState)}
              >
                clear all filters
              </Button>

              
              <Dropdown
                list={rowsPerPageOptions}
                value={rowsPerPage}
                label="Rows Per Page"
                callback={(newValue) => setRowsPerPage(newValue)}
                
              />

              <BasicTextFields
                label="Search"
                value={stockSearchText}
                callback={handleStockSearch}
              />
            </div>
          )} */}
        </div>

        {/* MAIN SCREENER TABLE */}
        {screenerData.isLoading ? (
          <Loader />
        ) : screenerResult?.length === 0 ? (
          // <NoResultsToDisplay />
          <div
            style={{
              overflowX: "auto",
            }}
          >
            <DenseTable
              tableData={stockList}
              userAlertList={userAlertList}
              setUserAlertList={setUserAlertList}
              maxHeight="600px"
              tableMarginTop={tableMarginTop}
              selectedStockCategory={selectedStockCategory}
              setSelectedStockCategory={setSelectedStockCategory}
              screenerDataMutate={screenerData.mutate}
              latest_stocks={latestStocks}
            />
          </div>
        ) : screenerResult ? (
          <>
            <div
              style={{
                overflowX: "auto",
              }}
            >
              <DenseTable
                tableData={stockList}
                userAlertList={userAlertList}
                setUserAlertList={setUserAlertList}
                maxHeight="600px"
                tableMarginTop={tableMarginTop}
                selectedStockCategory={selectedStockCategory}
                setSelectedStockCategory={setSelectedStockCategory}
                screenerDataMutate={screenerData.mutate}
                latest_stocks={latestStocks}
              />
            </div>
          </>
        ) : null}

        {openSaveScanModal &&
          (emptyObj ? (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert
                severity="error"
                onClose={() => setOpenSaveScanModal(false)}
              >
                Please fill the fields first
              </Alert>
            </Stack>
          ) : (
            <SaveScanModal
              scanFields={queryNameDesc}
              isOpen={openSaveScanModal && !emptyObj}
              // action={(val) => setOpenSaveScanModal(val)}
              setOpenSaveScanModal={setOpenSaveScanModal}
              handleSaveScanScreener={handleSaveScanScreener}
            />
          ))}
      </div>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </>
  );
};

export default Screener;
