import axiosConfig from "../../axios/axiosConfig";

// ADD OR REMOVE BUY/SELL WATCHLIST ALERT
export const setWatchlistAlert = async (
  requestType,
  watchlistID,
  alertType,
  selectedInterval,
  USERID
) => {
  try {
    const res = await axiosConfig({
      method: "post",
      url: `/watchlist/bulk_alert?watchlist_id=${watchlistID}`,
      data: {
        alert_request_type: requestType,
        userid: USERID,
        timeframe: selectedInterval,
        alert_type: alertType,
      },
    });

    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};



// FETCH USERS WATCHLISTS (NOT BEING USED)
export const getUserWatchlists = async (USERID) => {
  const res = await axiosConfig({
    method: "get",
    url: `/watchlist/userwatchlist?userid=${USERID}`,
    data: {},
  });

  return res.data;
};


// FETCH WATCHLIST WITH ID (not user watchlist)
export const fetchWatchlist = async (watchlistId, userId) => {
  if (!watchlistId) return;
// console.log("userid inside: ", userId)
  const res = await axiosConfig({
    method: "get",
    url: `/watchlist/userwatchlist?watchlist_id=${watchlistId}&userid=${userId}`,
    data: {},
  });

  return res.data;
};


// FETCHING ALL SYMBOLS FOR AUTOCOMPLETE COMPONENT, (for user to select multiple stocks/symbols)
export const fetchAllSymbols = async (user_id) => {
  const res = await axiosConfig({
    method: "get",
    url: `/watchlist/userwatchlist?symbol=true&userid=${user_id}`,
    data: {},
  });

  return res.data;

  // if (res.status === 200 || res.statusText === "OK") {
  //   setAllSymbols(res.data.response);
};


// Patch / send / ADD stocks/symbols array to backend function
export const addStocksToWatchlist = async (watchlist_id, symbols_array, user_id) => {
  const res = await axiosConfig({
    method: "patch",
    url: `/watchlist/userwatchlist?watchlist_id=${watchlist_id}&userid=${user_id}`,
    data: { symbol: symbols_array },
  });

  return res;
};


// REMOVE SYMBOL FROM WATCHLIST
export const removeSymbolFromWatchlist = async (watchlist_id, SYMBOL, user_id) => {
  const res = await axiosConfig({
    method: "patch",
    url: `/watchlist/userwatchlist?watchlist_id=${watchlist_id}&remove=true&userid=${user_id}`,
    data: { symbol: SYMBOL },
  });

  return res;
}