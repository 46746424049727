import { Container } from "@mui/material";
import React from "react";
// import SubscriptionPlan from "../../components/SubscriptionPlan";
import PricingPlans from "../../components/PricingPlans";

const Pricing = () => {
  return (
    <div>
      <PricingPlans />
      {/* <SubscriptionPlan /> */}
    </div>
  );
};

export default Pricing;
