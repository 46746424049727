import React from "react";
import { Outlet } from "react-router-dom";
import NavbarNEW from "./NavbarNEW";

const user = JSON.parse(localStorage.getItem("user"));

export default function GuestLayout() {
  return (
    <div className="guestLayout">
      <NavbarNEW isAuth={!!user} />

      <Outlet />
    </div>
  );
}
