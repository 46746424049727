import React, { useState, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reduxStore/actions/auth";
import { styled, alpha } from "@mui/material/styles";

import TNIBRO_LOGO from "../assets/stock_yaari_logo.png";
import RandomUser from "../assets/icons/male-user.png"

import styles from "./NavbarNEW.module.css";
import SearchBox from "../components/SearchBox";

const mainMenu = [
  { title: "Screener", linkto: "/screener" },
  { title: "Saved Scans", linkto: "/savedscan" },
  { title: "Options", linkto: "/nse-option-chain" },
  { title: "Recommended Stocks", linkto: "/recommended-stocks?tab=nifty50" },
  // { title: "Chart", linkto: "/charts/" },
  { title: "Dashboards", linkto: "/dashboards" },
  { title: "Watchlist", linkto: "/watchlist" },
  { title: "Alerts", linkto: "/alerts" },
  // { title: "Pricing", linkto: "/pricing" },
];

// const userOptions = [
//   { title: "Screener", linkto: "/screener" },
//   { title: "Logout", linkto: "/screener" },
// ];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function NavbarNEW({ isAuth, userDetails, showSearchBox = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((store) => store.auth);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState(null);

  // const searchStocksQuery = useQuery({
  //   queryKey: ["search-stocks"],
  //   queryFn: () => handleSearch(searchInput),
  //   onSuccess: (data) => {
  //     console.log(data.response);

  //     setSearchResultsList(data.response);
  //   },
  //   onError: (error) => {
  //     console.log('search stocks error: ', error)
  //     toast.error(error?.response?.data?.response || "Something went wrong");
  //   },
  // })

  // NAV MENU & USER MENU FUNCTIONS
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenAdminMenu = (event) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  const logOut = useCallback(() => {
    dispatch(logout());
    setAnchorElUser(null);
    window.location.reload();
  }, [dispatch]);

  // temp Array for top menu tags/chips
  const leftMenuTags = [
    { id: 1, label: "Cash", link: "/recommended-stocks?tab=cash" },
    { id: 2, label: "Nifty 50", link: "/recommended-stocks?tab=nifty50" },
    { id: 3, label: "mcx", link: "/recommended-stocks?tab=mcx" },
    { id: 4, label: "futures", link: "/recommended-stocks?tab=futures" },
    { id: 5, label: "Options", link: "/nse-option-chain" },
  ];

  const rightMenuTags = [
    { id: 1, label: "Support", link: "/", display: "inline-block" },
    { id: 2, label: "Premium", link: "/", display: "inline-block" },
    {
      id: 3,
      label: "Log In or Sign Up",
      link: "/login",
      display: userState?.isLoggedIn ? "none" : "inline-block",
    },
  ];

  const activeNavMenuStyles = {
    // backgroundColor: '#98FB98',
    borderBottom: "2px solid #339502",
    borderTop: "2px solid #339502",
    color: "white",
    // borderRadius: '4px'
  };

  return (
    <Box >
      <div className={styles.topBar}>
        <div className={styles.leftMenu}>
          {leftMenuTags.map((item) => {
            return (
              <Link key={item.id} to={item.link}>
                <span className={styles.leftMenuItem}>{item.label}</span>
              </Link>
            );
          })}
        </div>
        <div className={styles.rightMenu}>
          {rightMenuTags.map((item) => {
            return (
              <Link
                key={item.id}
                to={item.link}
                style={{ display: item.display }}
              >
                <span className={styles.rightMenuItem}>{item.label}</span>
              </Link>
            );
          })}
        </div>
      </div>

      <AppBar
        position="sticky"
        // top={0}
        sx={{
          zIndex: 1201,
          backgroundColor: "rgb(253, 253,253)",
          color: "#333",
          boxShadow: "none",
          borderBottom: "1px solid #e2e9ee",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link to="/home">
              <Box
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  cursor: 'pointer'
                }}
                // onClick={() => navigate('/')}
              >
                <img src={TNIBRO_LOGO} alt="Logo" width={120} />
              </Box>
            </Link>

            {/* <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            TNIBRO
          </Typography> */}

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {mainMenu.map((menu) => (
                  <MenuItem
                    key={menu.title}
                    onClick={handleCloseNavMenu}
                    sx={{
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    }}
                  >
                    <Link to={menu.linkto}>
                      <Typography textAlign="center" textTransform="capitalize">
                        {menu.title}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}

            <Box
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
              }}
            >
              <Link to="/home">
                <img src={TNIBRO_LOGO} alt="Logo" width={120} />
              </Link>
            </Box>

            {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            TNIBRO
          </Typography> */}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {mainMenu.map((menu) => (
                <NavLink
                  to={menu.linkto}
                  className={styles.NavLink}
                  style={({ isActive }) =>
                    isActive ? activeNavMenuStyles : {}
                  }
                >
                  <span className={styles.navMenuItem}>{menu.title}</span>
                  {/* <Button
                  key={menu.title}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#333",
                    fontSize: "15px",
                    display: "block",
                    textTransform: "capitalize",
                    border: menu.title === "Chart" ? "1px solid" : "none",
                    "&:hover": { backgroundColor: "#eee" },
                  }}
                >
                  {menu.title}
                </Button> */}
                </NavLink>
              ))}

              {/* ADMIN BUTTON & OPTIONS HERE */}
              {userDetails?.user_type === "Admin" ? (
                <Tooltip title="Super Admin Dashboard">
                  <Button
                    // onClick={handleOpenAdminMenu}
                    onClick={() => navigate("/admin-dashboard/users")}
                    sx={{ my: 2, color: "#666", display: "block" }}
                  >
                    Admin
                  </Button>
                </Tooltip>
              ) : null}

              {/* MENU NOT NEEDED, MADE DASHBOARD FOR ADMIN */}
              {/* <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElAdmin}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElAdmin)}
              onClose={handleCloseAdminMenu}
            >
              {adminMenuOptions.map((menu) => (
                <Link to={menu.linkto}>
                  <MenuItem key={menu.title} onClick={handleCloseAdminMenu}>
                    <Typography textAlign="center">{menu.title}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu> */}
            </Box>

            {/* PROFILE ICON AND OPTIONS */}
            {isAuth ? (
              <Box sx={{ flexGrow: 0, ml: 2 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      sx={{ bgcolor: "transparent" }}
                      alt="TNIBro"
                      src={RandomUser}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {/* {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))} */}

                  {/* <MenuItem
                onClick={() => {
                  navigate("/profile");
                  setAnchorElUser(null);
                }}
              >
                Profile
              </MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      navigate("/user-dashboard/profile");
                      setAnchorElUser(null);
                    }}
                  >
                    My Dashboard
                  </MenuItem>
                  <MenuItem onClick={logOut}>
                    {isAuth ? "Logout" : "Login"}
                  </MenuItem>
                </Menu>
              </Box>
            ) : null}
          </Toolbar>
          {/* </Container> */}
        </Container>
      </AppBar>

      {/* SEARCH BOX */}
      <SearchBox />
      {/* <GlobalSearchBar /> */}
      {/* {showSearchBox ? (
        <SearchBox />
      ) : null} */}
    </Box>
  );
}
export default NavbarNEW;
