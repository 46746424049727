import React, { useState } from "react";

import styles from "./AdminMobileOTPLoginForm.module.css";
import { useMutation } from "@tanstack/react-query";

import axiosConfig from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../components/common/ReactHotToaster";

const AdminMobileOTPLoginForm = ({ setAccessToken, validateAccessToken }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileOTP, setMobileOTP] = useState("");
  const [sessionID, setSessionID] = useState("");

  // FETCH MOBILE OTP POST REQUEST
  const getMobileOTP = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "tnibroadmin/admin_verification",
        data: {
          phoneno: mobileNumber,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // console.log("mobile session id res: ", data)
      setSessionID(data.Details);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const getOTP = () => {
    getMobileOTP.mutate();
  };

  // VERIFY MOBILE OTP POST REQUEST
  const verifyMobileOTP = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "tnibroadmin/admin_verification",
        data: {
          otp: mobileOTP,
          session_id: sessionID,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      // updating Access Token
      setAccessToken(data?.response);
      localStorage.setItem("admin_access_token", data?.response);
      validateAccessToken(true);
      window.location.reload();
    },
    onError: (error) => {
      validateAccessToken(false);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  const verifyOTP = () => {
    verifyMobileOTP.mutate();
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.title}>Session Expired or Invalid</h2>
        <h3 className={styles.subtitle}>Please Login</h3>

        {/* mobiler otp login form */}
        <div className={styles.inputContainer}>
          <div className={styles.inputBox}>
            <label htmlFor="mobileNumber">Mobile Number: </label>
            <input
              className={styles.mobileInput}
              type="text"
              placeholder="eg. 9912345678"
              maxlength="10"
              name="mobileNumber"
              id="mobileNumber"
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>

          <div className={styles.buttonContainer}>
            <button
              disabled={mobileNumber.length < 10}
              className={styles.submitButton}
              onClick={getOTP}
            >
              GET OTP
            </button>
          </div>

          <div className={styles.inputBox}>
            <label htmlFor="mobileOTP">OTP: </label>
            <input
              className={styles.mobileOTP}
              maxlength="6"
              type="text"
              name="mobileOTP"
              id="mobileOTP"
              onChange={(e) => setMobileOTP(e.target.value)}
            />
          </div>

          <div className={styles.buttonContainer}>
            <button
              disabled={mobileOTP.length < 6}
              className={styles.submitButton}
              onClick={verifyOTP}
            >
              VERIFY OTP
            </button>
          </div>
        </div>
      </div>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default AdminMobileOTPLoginForm;
