import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import axiosConfig from "../../../axios/axiosConfig";
import requestPermission, {
  generateToken,
  sendToken,
} from "../../../utils/notifications";
import { useEffect } from "react";

import OneSignal from "react-onesignal";
import toast from "react-hot-toast"
import ReactHotToaster from "../../common/ReactHotToaster";

const currentDateTime = new Date().toLocaleString().replace(',','');

const alertOptions = ["Price Alert", "Buy Alert", "Sell Alert"];

const intraDayOptions = [
  // { id: 1, value: "1-min", label: "1-min" },
  // { id: 2, value: "5-min", label: "5-min" },
  // { id: 3, value: "15-min", label: "15-min" },
  // { id: 4, value: "30-min", label: "30-min" },
  // { id: 5, value: "45-min", label: "45-min" },
  // { id: 6, value: "60-min", label: "60-min" },
  { id: 7, value: "1-d", label: "1-day" },
];

// const initialSelectedAlertOptions = [
//   { id: 1, isChecked: true, alertType: "web-mobile" },
//   { id: 2, isChecked: true, alertType: "email" },
//   { id: 3, isChecked: true, alertType: "sms" },
//   { id: 4, isChecked: true, alertType: "whatsapp" },

// ];

const initialSelectedAlertOptions = {
  isWebMobileChecked: true,
  isEmailChecked: true,
  isSmsChecked: true,
  isWhatsappChecked: true,
};

// crudType can be 'create' for creating new Alert, 'edit' for editing Alert
const ChooseAlertTypeModal = ({
  setOpenAlertTypeModal,
  alertDetails,
  setUserAlertList,
  crudType,
  setUserAlertDetails,
}) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);


  const [selectedAlertType, setSelectedAlertType] = useState(alertOptions[0]);
  const [alertTypeInput, setAlertTypeInput] = useState("");

  const [selectedInterval, setSelectedInterval] = useState(intraDayOptions[0]); // timeframe selected by user from autocomplete list
  const [intervalInput, setIntervalInput] = useState(""); //

  const [alertPrice, setAlertPrice] = useState(alertDetails.price); // Alert Price, Input by user

  const [selectedAlertOptions, setSelectedAlertOptions] = React.useState(
    initialSelectedAlertOptions
  ); // Select Alert Notifications options

  const handleChange = (event, alertType) => {
    // setChecked(event.target.checked);
    setSelectedAlertOptions((prevState) => {
      return { ...prevState, [alertType]: event.target.checked };
    });
  };

  useEffect(() => {
    OneSignal.getUserId(function (userId) {
      // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
      localStorage.setItem("one_signal_userid", userId);
    });

    OneSignal.isPushNotificationsEnabled(function (isEnabled) {
      if (isEnabled) {
        console.log("Push notifications are enabled!");
      } else {
        console.log("Push notifications are not enabled yet.");
        alert(
          "Push notifications are disabled. Please enable it on your browser to receive real time Alerts and recommendations."
        );
      }
    });
  }, []);

  // CREATE NEW ALERT
  const handleSetAlert = async () => {
    // const permission = await Notification.requestPermission();

    // if (permission !== "granted") {
    //   alert("Permission Denied. Please unblock push notifications from the browser!");
    //   return;
    // }

    try {
      const token_res = await sendToken(userDetails.id);
      // console.log("token_res: ", token_res)
      if (!token_res) return;
    } catch (error) {
      console.log(error);
      return;
    }

    try {
      
      const res = await axiosConfig({
        method: "post",
        url: "/tnibroadmin/alert",
        data: {
          userid: userDetails.id,
          symbol_id: alertDetails.symbol_id,
          price: alertPrice,
          alert_type: selectedAlertType,
          timeframe: selectedInterval?.value,
          set_alert_time: currentDateTime
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        setUserAlertList((prevState) => {
          return [...prevState, res.data.response];
        });
        toast.success(`Alert set for ${alertDetails.symbol}.`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response || "Something went wrong");
    } finally {
      setOpenAlertTypeModal(false);
    }
  };

  // EDIT ALERT
  const handleEditAlert = async () => {
    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/tnibroadmin/alert?alert_id=${alertDetails?.alert_id}`,
        data: {
          price: alertPrice,
          alert_type: selectedAlertType,
          timeframe: selectedInterval?.value,
          alert_active: true,
          set_alert_time: currentDateTime,
          triggered_alert_time: null
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        setUserAlertDetails((prevState) => {
          return prevState.map((alert) => {
            if (alert.id === alertDetails.alert_id) {
              return res.data.response;
            }

            return alert;
          });
        });
        toast.success(`Alert updated for ${alertDetails.symbol}.`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    } finally {
      setOpenAlertTypeModal(false);
    }
  };

  // DISABLE SET ALERT BUTTON LOGIC
  const disableBtn =
    !selectedAlertType ||
    selectedAlertType === "" ||
    !selectedInterval?.value ||
    selectedInterval?.value === "";

  return (
    <div>
      <Typography variant="h5" sx={{ textAlign: "center", mb: 3 }}>
        Set Alert for {alertDetails.symbol}
      </Typography>

      <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
        <Autocomplete
          size="small"
          value={selectedAlertType}
          onChange={(event, newValue) => {
            setSelectedAlertType(newValue);
          }}
          inputValue={alertTypeInput}
          onInputChange={(event, newInputValue) => {
            // if (
            //   newInputValue === "Buy Alert" ||
            //   newInputValue === "Sell Alert"
            // ) {
            //   setAlertPrice("");
            // }
            setAlertTypeInput(newInputValue);
          }}
          id="select-alert-type"
          options={alertOptions}
          sx={{ width: 180 }}
          renderInput={(params) => (
            <TextField {...params} label="Select Alert Type" />
          )}
        />
      </Stack>

      <Box sx={{ mb: 4 }}>
        {selectedAlertType === "Price Alert" ? (
          <TextField
            type="number"
            size="small"
            id="select-alert-type"
            label="Set Price"
            variant="outlined"
            value={alertPrice}
            // defaultValue={alertDetails.price}
            onChange={(e) => setAlertPrice(e.target.value)}
            sx={{ width: 110 }}
            inputProps={{ step: 0.05 }}
          />
        ) : null}

        {/* TIMEFRAME, INTRADAY INTERVAL */}
        {selectedAlertType === "Buy Alert" ||
        selectedAlertType === "Sell Alert" ? (
          <Autocomplete
            size="small"
            value={selectedInterval}
            onChange={(event, newValue) => {
              setSelectedInterval(newValue);
            }}
            inputValue={intervalInput}
            onInputChange={(event, newInputValue) => {
              setIntervalInput(newInputValue);
            }}
            id="select-alert-type"
            options={intraDayOptions}
            sx={{ width: 150 }}
            renderInput={(params) => (
              <TextField {...params} label="Select Interval" />
            )}
          />
        ) : null}
      </Box>

      {/* CHOOSE ALERT TYPE / OPTIONS */}
      <Box
        sx={{
          mb: 2,
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 2,
          display: "none",
        }}
      >
        <Typography>Enable Alert Notifications for:</Typography>
        <FormGroup sx={{ flexDirection: "row", flexWrap: "wrap" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedAlertOptions.isWebMobileChecked}
                onChange={(event) => handleChange(event, "isWebMobileChecked")}
                defaultChecked
              />
            }
            label="Web/Mobile Push"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedAlertOptions.isEmailChecked}
                onChange={(event) => handleChange(event, "isEmailChecked")}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedAlertOptions.isSmsChecked}
                onChange={(event) => handleChange(event, "isSmsChecked")}
              />
            }
            label="SMS"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedAlertOptions.isWhatsappChecked}
                onChange={(event) => handleChange(event, "isWhatsappChecked")}
              />
            }
            label="Whatsapp"
          />
        </FormGroup>
      </Box>

      {/* BUTTONS */}
      {crudType === "create" ? (
        <Button
          disabled={disableBtn}
          size="small"
          variant="contained"
          onClick={handleSetAlert}
        >
          Create
        </Button>
      ) : null}

      {crudType === "edit" ? (
        <Button
          disabled={disableBtn}
          size="small"
          variant="contained"
          onClick={handleEditAlert}
        >
          SUBMIT
        </Button>
      ) : null}

        {/* REACT HOT TOAST */}
      {/* <ReactHotToaster /> */}
    </div>
  );
};

export default ChooseAlertTypeModal;
