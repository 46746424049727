import React from 'react';

const ContactUs = () => {
  return (
    <div style={{
      padding: '1rem 2rem'
    }}>
        <div>
          <h1>Contact Us</h1>
          <ul style={{ listStyle: "none"}}>
            <li>TNIBRO Technologies LLP</li>
            <li>2nd Floor, Plot 756</li>
            <li>Udyog Vihar, Phase V</li>
            <li>Gurgaon-122016</li>
          </ul>
        </div>
    </div>
  )
}

export default ContactUs;