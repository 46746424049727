import React from "react";
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import PeopleIcon from '@mui/icons-material/People';
import ListItemIcon  from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



import FilterListIcon from '@mui/icons-material/FilterList';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import SaveIcon from '@mui/icons-material/Save';
import AddToPhotos from '@mui/icons-material/AddToPhotos';
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NotificationsIcon from '@mui/icons-material/Notifications';


const sidebarLinks = [
  { title: "Screener", linkto: "/", icon: <FilterListIcon /> },
  { title: "Options", linkto: "/nse-option-chain", icon: <ShowChartIcon /> },
  { title: "Users", linkto: "/users", icon: <PeopleIcon /> },
  { title: "Members", linkto: "/members", icon: <GroupTwoToneIcon /> },
  { title: "SavedScans", linkto: "/savedscan", icon: <SaveIcon /> },
  // { title: "ads", linkto: "/ads", icon: <AddToPhotos /> },
  // { title: "Chart", linkto: "/chart", icon: <CandlestickChartIcon /> },
  { title: "Dashboards", linkto: "/dashboards", icon: <DashboardIcon /> },
  { title: "Watchlist", linkto: "/watchlist", icon: <ListAltIcon /> },
  { title: "Alerts", linkto: "/alerts", icon: <NotificationsIcon /> },
  { title: "Alerts (Admin)", linkto: "/alerts-admin", icon: <NotificationsIcon /> },
];

const drawerWidth = 240;

const SideBar = () => {
  return (
    <Box sx={{ display: {xs: 'none', sm: 'none', md: "none", lg: 'flex' } }}>

      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {sidebarLinks.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton key={index}>
                <ListItemIcon>
                  {text.icon}
                </ListItemIcon>
                <Link style={{ textDecoration: 'none' }} to={text.linkto}>{text.title}</Link>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

export default SideBar;


