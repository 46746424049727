import { Skeleton, Stack } from '@mui/material'
import React from 'react'

const MuiTableSkeletonLoader = () => {
  return (
    <Stack sx={{
      padding: 4
    }}>
      <Skeleton sx={{ bgcolor: 'grey.500' }} animation="pulse" />
      <Skeleton sx={{ bgcolor: 'grey.500' }} animation="pulse" />
      <Skeleton sx={{ bgcolor: 'grey.500' }} animation="pulse" />
      <Skeleton sx={{ bgcolor: 'grey.500' }} animation="pulse" />
    </Stack>
  )
}

export default MuiTableSkeletonLoader