import { SET_CURRENT_PAGE, SET_ROWS_PER_PAGE, SET_TOTAL_STOCKS } from "../actions/types";


const initialState = {
  currentPage: 1,
  rowsPerPage: 100,
  totalStocks: 0,
};

export default function paginationReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_TOTAL_STOCKS:
      return { ...state, totalStocks: payload };

    default:
      return state;
  }
}