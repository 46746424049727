import React from 'react';
import image1 from '../../../assets/people/p8.jpg'
import InfoCard from './InfoCard';

import styles from './InfoCards.module.css'

const infoCardDataList = [
  {
    id: 1,
    img: image1,
    title: "Lorem Ipsum dummy text",
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
  },
  {
    id: 2,
    img: image1,
    title: "Lorem Ipsum 2nd",
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
  },
  {
    id: 3,
    img: image1,
    title: "Lorem Ipsum 3rd",
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
  },
]

const InfoCards = () => {
  return (
    <div className={styles.infoCards_Container} >
      {/* <h2>InfoCards</h2> */}

      {/* map infocard comp */}
      <div className={styles.infoCardContainer}>
      {infoCardDataList.map(card => {
        return <InfoCard key={card.id} cardInfo={card} />
      })}
      </div>
      
    </div>
  )
}

export default InfoCards;
