import axios from "axios";

const access_token = JSON.parse(localStorage.getItem("user"))?.access_token;

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${access_token}`,
  },
});

export const axiosNoToken = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    Accept: "application/json",
  },
});

// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common["Authorization"] = access_token;

// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request.use(
//   (config) => {
//     if (!config.headers.Authorization) {
//       // const token = JSON.parse(localStorage.getItem("user"))?.access_token;

//       if (access_token) {
//         config.headers.Authorization = access_token;
//       }
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

export default instance;

// axiosConfig.get("/posts") in components
