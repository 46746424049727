import axios from "axios";
// import { SET_MESSAGE } from "../actions/types";


const API_URL = "/enroll/";
// const access_token = JSON.parse(localStorage.getItem("user"))?.access_token;

const register = (username, email, password, phoneno, country, REFERRAL_CODE) => {
  // Phone number should be null or undefined if there's email (for non-Indians)
  if (email) {
    phoneno = undefined;
  }
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    phoneno,
    country,
    // referral_code: REFERRAL_CODE,
    referred_by: REFERRAL_CODE
  });
};

const login = async (email, password, phone) => {
  // Phone number should be null or undefined if there's email (for non-Indians)
  if (email) {
    phone = undefined;
  }

  return axios.post(API_URL + "login", {
    email,
    password,
    phoneno: phone
  });

  // try {
  //   const response = await axios.post(API_URL + "login", {
  //     email,
  //     password,
  //     phoneno: phone
  //   });
  //   if (response.data.access_token) {
      
  //     const allUserDetails = { ...response.data, userDetails: response.data.response}

  //     localStorage.setItem("user", JSON.stringify(allUserDetails));
      
  //     window.location.reload();
      
  //   }

  //   return response.data;
  // } catch (error) {
  //   console.log(error.response);
  //   return error.response;
  // }
};
const googleLogin = async (email) => {
  // Phone number should be null or undefined if there's email (for non-Indians)
  if (!email || email === "") {
    return;
  }

  try {
    const response = await axios.post(API_URL + "google_login", {
      email,
      
    });

    if (response.data.access_token) {
      const allUserDetails = { ...response.data, userDetails: response.data.response}
      const USERID = response.data.response.id;
      // await sendToken(USERID);  // sending fcm token to server for browser push notifications

      // localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("user", JSON.stringify(allUserDetails));
      
      // window.location.reload();
      // window.location.assign("/");
    }

    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("sorting_details_local");
  localStorage.removeItem("selectedFiltersLocal");
  localStorage.removeItem("selected_sector");
};

// function to store user details in redux store
// COMMENTED OUT BELOW FUNCTION AS JWT AUTH NOT WORKING PROPERLY
// const getUserDetails = async (token) => {
//   try {
//     const res = await axiosConfig({
//       method: "post",
//       url: "/tnibroadmin/login",
//       headers: {
//         Accept: "application/json",
//         Authorization: token,
//       },
//     });
//     // console.log(res.data);
//     return res.data;
//   } catch (error) {
//     console.log(error.response);
//   }
// };

// default exporting the object
export default {
  register,
  login,
  logout,
  googleLogin,
  // getUserDetails,
};

