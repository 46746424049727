import React, { useState } from "react";

import axiosConfig from "../../../axios/axiosConfig";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { YEARLY_DISCOUNT } from "../../../utils/globalConstants";
import { subscriptionPlanTiers } from "../../PricingPlans";
import ReactHotToaster from "../../common/ReactHotToaster";
import UserSubscriptionDetails from "./UserSubscriptionDetails";
import { Box, Button, CircularProgress } from "@mui/material";

const SubscriptionPlan = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;

  const [userSubscriptionDetails, setUserSubscriptionDetails] = useState({});
  // const { plan_type, add_on_list, subscription_date } = userSubscriptionDetails;
  // const formattedDate = new Date(subscription_date).toDateString();
  

  const [selectedPlan, setSelectedPlan] = useState("basic");
  const [planPeriod, setPlanPeriod] = useState("monthly");

  // FETCHING COUPON DETAILS API REQUEST
  const fetchUserSubscriptionQuery = useQuery({
    queryKey: ["user-subscription"],
    queryFn: () => fetchUserSubscription(USERID),
    onSuccess: (data) => {
      // setCouponDetails(data.response)
      setUserSubscriptionDetails(data.response);
    },
    onError: (error) => {
      console.log("error: ", error.response)
      // if (error.response.status === 404 || error.response.statusText === "Not Found") {
      //   setUserSubscriptionDetails({ isUserSubscribed: false })
      // }
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // user is subscribed to below plan
  const userSubscribedTier = subscriptionPlanTiers.find((item) => {
    return item.title.toLowerCase() === selectedPlan;
  });

  const formattedTier = {
    price: userSubscribedTier.price,
    title: userSubscribedTier.title,
  };

  const handleRenewPlan = () => {
    // calculating total payable amount without ADD ONS
    const totalBasePlanAmount = calcTotalPrice(userSubscribedTier, planPeriod); // calculate base plan price according to monthly or yearly
    const state = {
      totalBasePlanAmount,
      selectedTier: formattedTier,
      planPeriod,
      subscriptionType: "RENEW_PLAN",
      // subscriptionType: selectedPlan,
    };
    
    navigate("/cart", { state });
  };

  return (
    <Box>

      {/* USER SUBSCRIPTION DETAILS COMPONENT */}
      {fetchUserSubscriptionQuery.isLoading ? (
        <Box sx={{
          padding: '2rem'
        }}>
          <CircularProgress />
        </Box>
      ) : (
        Object.keys(userSubscriptionDetails).length === 0 ? (
          <Box sx={{
            padding: '2rem'
          }}>
            <Button variant="contained" onClick={() => navigate('/pricing')} >Buy New Plan</Button>
          </Box>
        ) : (
          <UserSubscriptionDetails
        userSubscriptionDetails={userSubscriptionDetails}
        handleRenewPlan={handleRenewPlan}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
        planPeriod={planPeriod}
        setPlanPeriod={setPlanPeriod}
      />
        )
        
      )}
      

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </Box>
  );
};

export default SubscriptionPlan;

// UTLILITY / API FUNCTIONS
const fetchUserSubscription = async (user_id) => {
  if (!user_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `/subscription/user_subscription?userid=${user_id}`,
    data: {},
  });

  return res.data;
};

function calcTotalPrice(selected_tier, plan_period) {
  let total_price = 1;

  if (plan_period === "monthly") {
    total_price = selected_tier.price;
  }

  // if yearly plan is selected, then multiply by 12, and apply the discount
  if (plan_period === "yearly") {
    let yearly_price = selected_tier.price * 12; // yearly price without discount
    total_price = yearly_price - yearly_price * YEARLY_DISCOUNT;
  }

  return total_price;
}
