// old patterns list
// export const candlestick_patterns = [
//   "bearish_doji",
//   "bullish_doji",
//   "engulfing_bearish",
//   "engulfing_bullish",
//   "harami bearish",
//   "harami bullish",
//   "Inverted Hammer",
//   "DragonFly Doji",
//   "morning_doji star",
//   "gravestone doji",
//   "hammer",
//   "hanging man",
//   "morning star",
//   "dojistar",
//   "piercing pattern",
//   "raindrop pattern",
//   "shooting star pattern",
//   "darkcloud ever pattern",
//   "up",
//   "down",
//   "deliberation bearish",
//   "upside gap method",
//   "three inside down",
//   "falling three",
//   "three outside down",
//   "tri-star bullish",
//   "abandoned baby bullish",
//   "bullish doji star pattern",
//   "belt hold bullish",
// ];

// new patterns list
export const candlestick_patterns = [
  "three outside up",
"rising three method",
"three white soldiers",
"belt hold bullish",
"abandoned baby bullish",
"Falling three method",
"three inside up",
"morning star",
"bullish doji",
"engulfing_bullish",
"harami bullish",
"hammer",
"morning star doji",
"DragonFly Doji",
"Inverted Hammer",
"white marubozu",
"belt hold bearish",
"three outside down",
"three inside down",
"deliberation bearish",
"darkcloud ever pattern",
"shooting star",
"bearish doji",
"engulfing_bearish",
"harami bearish",
"hanging man",
"piercing pattern",
"evening star",
"three black crow",
"black marubozu",
];

export const candlestick_patterns_arr = [
  // { id: 0, label: "", value: ""},
  { id: 27, label: "abandoned baby bullish", value: "abandoned baby bullish"},
  { id: 1, label: "bearish", value: "bearish"},
  { id: 30, label: "bearish doji", value: "bearish doji"},
  { id: 29, label: "belt hold bullish", value: "belt hold bullish"},
  { id: 2, label: "bullish", value: "bullish"},
  { id: 31, label: "bullish doji", value: "bullish doji"},
  { id: 28, label: "bullish doji star pattern", value: "bullish doji star pattern"}, // not in new list
  { id: 18, label: "darkcloud ever pattern", value: "darkcloud ever pattern"},
  { id: 21, label: "deliberation bearish", value: "deliberation bearish"},
  { id: 14, label: "dojistar", value: "dojistar"}, // not in new list
  { id: 20, label: "down", value: "down"}, // not in new list
  { id: 8, label: "DragonFly Doji", value: "DragonFly Doji"},
  { id: 3, label: "engulfing_bearish", value: "engulfing_bearish"},
  { id: 4, label: "engulfing_bullish", value: "engulfing_bullish"},
  { id: 24, label: "falling three method", value: "falling three method"},
  { id: 10, label: "gravestone doji", value: "gravestone doji"},
  { id: 11, label: "hammer", value: "hammer"}, // not in new list
  { id: 12, label: "hanging man", value: "hanging man"},
  { id: 5, label: "harami bearish", value: "harami bearish"},
  { id: 6, label: "harami bullish", value: "harami bullish"},
  { id: 7, label: "Inverted Hammer", value: "Inverted Hammer"},
  { id: 13, label: "morning star", value: "morning star"},
  { id: 9, label: "morning star doji", value: "morning star doji"},
  { id: 15, label: "piercing pattern", value: "piercing pattern"},
  { id: 16, label: "raindrop pattern", value: "raindrop pattern"}, // not in new list
  { id: 17, label: "shooting star", value: "shooting star"},
  { id: 26, label: "tri-star bullish", value: "tri-star bullish"}, // not in new list
  { id: 23, label: "three inside down", value: "three inside down"},
  { id: 25, label: "three outside down", value: "three outside down"},
  { id: 19, label: "up", value: "up"}, // not in new list
  { id: 22, label: "upside gap method", value: "upside gap method"}, // not in new list
];