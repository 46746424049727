import React, { useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";

import styles from "./PricingPlans.module.css";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PricingTile from "./PricingTile";

// MAIN FEATURES LIST
const featuresList = [
  { id: 1, label: "Screener", value: "screener" },
  { id: 2, label: "Options Chart", value: "options-chart" },
  { id: 3, label: "Backtesting", value: "backtesting" },
  { id: 4, label: "Stocks Chart", value: "stocks-chart" },
  { id: 5, label: "Dashboards", value: "dashboards" },
  { id: 6, label: "Watchlist", value: "watchlist" },
  { id: 7, label: "Alerts", value: "alerts" },
  { id: 8, label: "Email Support", value: "email-support" },
  { id: 9, label: "SELECT PLAN", value: "select-plan" },
];

// FREE TIER LIST DATA ITEMS
const freeTierList = [
  { id: 1, label: <VerifiedIcon />, value: "screener" },
  { id: 2, label: <VerifiedIcon />, value: "options-chart" },
  { id: 3, label: "", value: "backtesting" },
  { id: 4, label: "", value: "stocks-chart" },
  { id: 5, label: "", value: "dashboards" },
  { id: 6, label: "", value: "watchlist" },
  { id: 7, label: "", value: "alerts" },
  { id: 8, label: "", value: "email-support" },
  // { id: 9, label: "BUY", value: "buy-free-tier" },
];

// BASIC TIER LIST
const basicTierList = [
  { id: 1, label: <VerifiedIcon />, value: "screener" },
  { id: 2, label: <VerifiedIcon />, value: "options-chart" },
  { id: 3, label: <VerifiedIcon />, value: "backtesting" },
  { id: 4, label: <VerifiedIcon />, value: "stocks-chart" },
  { id: 5, label: <VerifiedIcon />, value: "dashboards" },
  { id: 6, label: <VerifiedIcon />, value: "watchlist" },
  { id: 7, label: <VerifiedIcon />, value: "alerts" },
  { id: 8, label: "", value: "email-support" },
  // { id: 9, label: "BUY", value: "buy-basic-tier" },
];

// BASIC TIER LIST
const premiumTierList = [
  { id: 1, label: <VerifiedIcon />, value: "screener" },
  { id: 2, label: <VerifiedIcon />, value: "options-chart" },
  { id: 3, label: <VerifiedIcon />, value: "backtesting" },
  { id: 4, label: <VerifiedIcon />, value: "stocks-chart" },
  { id: 5, label: <VerifiedIcon />, value: "dashboards" },
  { id: 6, label: <VerifiedIcon />, value: "watchlist" },
  { id: 7, label: <VerifiedIcon />, value: "alerts" },
  { id: 8, label: <VerifiedIcon />, value: "email-support" },
  // { id: 9, label: "BUY", value: "buy-premium-tier" },
];

// ALL PLANS DETAILS
export const subscriptionPlanTiers = [
  {
    title: "Free",
    subheader: "(with Ads)",
    price: 0,
    // description: ["Screener", "Options Chart"],
    buttonText: "Get Started",
    features: freeTierList,
  },
  {
    title: "Basic",
    subheader: "(with Ads)",
    price: 25,
    // description: ["Screener", "Options Chart"],
    buttonText: "Get Started",
    features: basicTierList,
  },
  {
    title: "Premium",
    subheader: "(No Ads)",
    price: 39,
    buttonText: "Get Started",
    features: premiumTierList,
  },
];

export default function PricingPlans () {
  const navigate = useNavigate();
  const [planPeriod, setPlanPeriod] = useState("monthly");
  // const [selectedTier, setSelectedTier] = useState("Free");

  // for 'month' / 'year' toggle button
  const handlePeriodChange = (event, newPlanPeriod) => {
    setPlanPeriod(newPlanPeriod);
  };



  // const handleClickNavigate = async (selectedTier) => {
  //   // calculating total payable amount without ADD ONS
  //   const totalPayableAmount = calcTotalPrice(selectedTier, planPeriod);
  //   console.log({ totalPayableAmount, selectedTier, planPeriod });
  //   const state = { totalPayableAmount, selectedTier, planPeriod };
  //   // navigate('/addons', { state: { totalPayableAmount, selectedTier, planPeriod }})
  //   await navigate("/addons", { state })
    
  // };

  return (
    <div className={styles.pricing_plan_outer}>
      <div className={styles.titleContainer}>
        <h1>Our Pricing Plans</h1>
        <p>Lorem ipsum dolor sit amet.</p>

        {/* MONTHLY / YEARLY TOGGLE BUTTON */}
        <Box sx={{
          mt: 1 
        }} >
          <ToggleButtonGroup
            color="primary"
            value={planPeriod}
            exclusive
            onChange={handlePeriodChange}
            aria-label="Plan Period"
          >
            <ToggleButton value="monthly">Monthly</ToggleButton>
            <ToggleButton value="yearly">Yearly</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      
      {/* PRICING PLAN COLUMNS / LIST */}
      <div className={styles.pricing_plan_container}>
        {/* COLUMN1 - FEATURES LIST */}
        <ul className={styles.features_list_column}>
          <li className={styles.heading_list_item}>
            <div className={styles.features_list_title_container}>
              <p className={styles.features_list_title}>Compare Plans</p>
              <span className={styles.offer_text}>20% Off with yearly plans</span>
            </div>
            <p className={styles.features_list_caption}>
              Choose your standard plan and Add Ons, according to your needs
            </p>
          </li>
          {featuresList.map((item) => {
            return <li key={item.id}>{item.label}</li>;
          })}
        </ul>

        {/* SUBSCRIPTION PLAN TILES */}
        {subscriptionPlanTiers.map((tier) => {
          return (
            <PricingTile key={tier.title} tier={tier} planPeriod={planPeriod} />
            // <ul key={tier.title} className={styles.free_tier_column}>
            //   <li className={styles.heading_list_item}>
            //     <h2 className={styles.plan_title}>{tier.title}</h2>
            //     <span>{tier.subtitle}</span>
            //     <h3>
            //       ${tier.price}
            //       <span className={styles.price_span}>
            //         {" "}
            //         /{planPeriod === "monthly" ? "Month" : "Year"}
            //       </span>
            //     </h3>
            //     <button
            //       className={styles.choose_plan_button}
            //       onClick={() => handleClickNavigate(tier)}
            //     >
            //       choose this plan
            //     </button>
            //   </li>
            //   {tier.features.map((item) => {
            //     return <li key={item.id}>{item.label}</li>;
            //   })}
            // </ul>
          );
        })}
      </div>
    </div>
  );
};


// UTILITY FUNCTIONS
function calcTotalPrice(selected_tier, plan_period) {
  let total_price = 1;
  const DISCOUNT = 0.1; // assuming discount is 10%

  if (plan_period === "monthly") {
    total_price = selected_tier.price;
  }

  // if yearly plan is selected, then multiply by 12, and apply the discount
  if (plan_period === "yearly") {
    let yearly_price = selected_tier.price * 12; // yearly price without discount
    total_price = yearly_price - yearly_price * DISCOUNT;
  }

  return total_price;
}
