import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  // minWidth: 300,
  // maxWidth: '100%',
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  // m: 2,
  borderRadius: 2,
  // minWidth: '400px',
  // maxWidth: '800px'
};

export default function MuiModal({ open, setOpen, children }) {
  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{ maxWidth: '100vw'}}
        // sx={{ minWidth: '90%'}}
      >
        <Box sx={style}>
          {/* IMP: ANY COMMON CONTENT for the MODAL MUST BE ADDED HERE  */}

          {/* CLOSE / CANCEL BUTTON ON TOP RIGHT */}
          <CancelIcon
            color="primary"
            sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
            onClick={() => setOpen(false)}
          />
          {children}
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
        </Box>
      </Modal>
    </>
  );
}
