import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

export default function ControlledCheckbox({ options, value, handleOptionChange }) {
  const [checked, setChecked] = useState(true);

  const handleChange = (event, option) => {
    setChecked(event.target.checked);
    handleOptionChange(event.target.checked, option.value);
  };

  return (<>
    {options.map((option) => {
      return <FormControlLabel
        label={option.label}
        control={
          <Checkbox
            checked={value}
            onChange={(e) => handleChange(e, option)}
          />
        }
      />
    })
    }
  </>
  );
}