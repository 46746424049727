import React from 'react'

const heading = ['userid', 'adv_id', 'adv_image', 'adv_url', 'screen_location', 'screen_name', 'country', 'created_date', 'published_date', 'expiry_date', 'adv_image', 'adv_image'];

const body = [
  {
    userid: "11",
    adv_id: "zck1p5962eDd",
    adv_image: "/media/advertisement/zck1p5962eDd/river_377603497_1000.jpg",
    adv_url: "https://www.youtube.com/watch?v=M6vLKxaOWZw",
    screen_location: "top",
    screen_name: "home",
    country: "India",
    published_date: "",
    expiry_date: "",
    created_date: "",
  },
  {
    userid: "11",
    adv_id: "zck1p5962eDd",
    adv_image: "/media/advertisement/zck1p5962eDd/river_377603497_1000.jpg",
    adv_url: "https://www.youtube.com/watch?v=M6vLKxaOWZw",
    screen_location: "bottom",
    screen_name: "home",
    country: "Albania",
    published_date: "",
    expiry_date: "",
    created_date: "",
  },
]



const CustomTable = () => {
  return (
    <table style={{ width: 500 }}>
      <thead>
        <tr>
          {heading.map(head => <th>{head}</th>)}
        </tr>
      </thead>
      <tbody>
        {/* {body.map(row => <TableRow row={row} />)} */}
        <tr>
          {body.map(val => <td>{val.userid}</td>)}
        </tr>
      </tbody>
    </table>
  )
}

// const TableRow = ({ row }) => {
//   return (
//     <tr>
//       {row.map(val => <td>{val}</td>)}
//     </tr>
//   )
// }


export default CustomTable;