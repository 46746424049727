import React from "react";
import "./AccessDenied.css";

const AccessDenied = () => {
  return (
    <div className="access-denied">
      <h1 className="access-denied__title">Access Denied</h1>
      <p className="access-denied__message">
        You do not have permission to view this page.
      </p>
    </div>
  );
};

export default AccessDenied;
