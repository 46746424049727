import React, { useState } from "react";
import UserCouponsTable from "./UserCouponsTable";
import axiosConfig from "../../../axios/axiosConfig";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Box, Button, Stack, Typography } from "@mui/material";
import MuiModal from "../../muiComps/MuiModal";
import CreateCouponForm from "./CreateCouponForm";
import ReactHotToaster from "../../common/ReactHotToaster";
import MuiTableSkeletonLoader from "../../muiComps/MuiTableSkeletonLoader";

const UserCouponCodesPage = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  const USERID = userDetails.id;

  const initialCouponDetailsInput = {
    coupon_name: "",
    coupon_desc: "",
    coupon_code: "",
    discount_pct: 0,
    start_date: new Date().toLocaleDateString(),
    // expiry_date: new Date().toLocaleDateString(),
    expiry_date: "",
  }

  const [openCreateCouponForm, setOpenCreateCouponForm] = useState(false);  // toggle state for create coupon code form
  const [couponDetailsInput, setCouponDetailsInput] = useState(initialCouponDetailsInput);
  // const [isActiveInput, setIsActiveInput] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [userCouponsData, setUserCouponsData] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false); // Edit Mode toggle for CreateCouponForm
  const [activeUserCouponDetails, setActiveUserCouponDetails] = useState({});  // Only for Edit Mode

  const {
    coupon_name,
    coupon_desc,
    coupon_code,
    discount_pct,
    start_date,
    expiry_date,
    // isActive,
  } = couponDetailsInput;

  const userCouponsQuery = useQuery({
    queryKey: ["user-coupons"],
    queryFn: () => getUserCoupons(USERID),
    onSuccess: (data) => {
      setUserCouponsData(data.response)
      
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });


  const formattedStartDate = start_date.replaceAll("/", "-");
  const formattedExpirtyDate = expiry_date.replaceAll("/", "-");
  // POST NEW COUPON
  const createCouponQuery  = useMutation({
    // mutationFn: fetchAllRecommendedStocks(selectedSegmentTab),
    mutationFn: async () => {
      // if (!selectedSegmentTab) return;

      const res = await axiosConfig({
        method: "post",
        url: "/referral/coupon",
        data: {
          userid: USERID,
          description: coupon_desc,
          title: coupon_name,
          coupon_code: coupon_code,
          discount: discount_pct,
          coupon_type: "general",
          start_date: formattedStartDate,
          expiry_date: formattedExpirtyDate,
          active: true
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setUserCouponsData(prevState => {
        return [...prevState, data.response]
      })
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });


  // HANDLER FUNCTION FOR SUBMIT (CREATE) BUTTON (CREATE COUPON)
  const handleCouponSubmit = () => {
    createCouponQuery.mutate();
    setOpenCreateCouponForm(false);
    // setCouponDetailsInput(initialCouponDetailsInput);
  }

  // EDIT / PATCH COUPON FORM
  const coupon_id = activeUserCouponDetails.id;
  const editCouponQuery  = useMutation({
    mutationFn: async () => {
      
      if (!coupon_id) return;

      const res = await axiosConfig({
        method: "patch",
        url: `/referral/coupon?coupon_id=${coupon_id}`,
        data: {
          userid: USERID,
          description: coupon_desc,
          title: coupon_name,
          coupon_code: coupon_code,
          discount: discount_pct,
          coupon_type: "general",
          start_date: formattedStartDate,
          expiry_date: formattedExpirtyDate,
          active: !isDeactivate  // isDeactivate is opposite of active
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      const newUserCouponsData = userCouponsData.map(item => {
        if (item.id === coupon_id) {
          return data.response
        }

        return item
      })
      setUserCouponsData(newUserCouponsData);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLER FUNCTION FOR SAVING BUTTON IN COUPON FORM
  const handleCouponEditSave = () => {
    editCouponQuery.mutate();
    setOpenCreateCouponForm(false);
    // setCouponDetailsInput(initialCouponDetailsInput);
  }

  // HANDLER FUNCTION FOR EDITING USER COUPON BUTTON IN TABLE ROW
  const handleEditCoupon = (user_details) => {
    const { title, description, coupon_code, discount, start_date: startDate, expiry_date: expiryDate } = user_details;
    setIsEditMode(true);
    setActiveUserCouponDetails(user_details);
    setOpenCreateCouponForm(true);
    setCouponDetailsInput({
      coupon_name: title,
      coupon_desc: description,
      coupon_code: coupon_code,
      discount_pct: discount,
      start_date: startDate,
      expiry_date: expiryDate,
    })
  }

  // CREATE NEW COUPON BUTTON HANDLER
  const handleCreateNewCoupon = () => {
    setOpenCreateCouponForm(true);
    setCouponDetailsInput(initialCouponDetailsInput);
  }
  

  return (
    <div>
      <Typography variant="h4" sx={{ margin: '2rem', textTransform: 'uppercase'}} >Coupon Codes</Typography>

      {/* CREATE COUPON CODE BTN FOR FORM - MODAL POPUP */}
      <Stack direction="row" sx={{ m: "2rem" }}>
        <Button
          variant="contained"
          size="small"
          onClick={handleCreateNewCoupon}
        >
          Create new coupon
        </Button>
        
      </Stack>

      {/* USER COUPONS TABLE */}
      <Box sx={{
        margin: '0 2rem',
        // minWidth: '800px',
        
      }} >
        {userCouponsQuery.isLoading ? (
          <MuiTableSkeletonLoader />
        ) : (
          <UserCouponsTable userCouponsData={userCouponsData} handleEditCoupon={handleEditCoupon} />
        )}
        
      </Box>
      

      {/* CREATE NEW COUPON FORM */}
      <MuiModal open={openCreateCouponForm} setOpen={setOpenCreateCouponForm}>
        <CreateCouponForm
          couponDetailsInput={couponDetailsInput}
          setCouponDetailsInput={setCouponDetailsInput}
          isDeactivate={isDeactivate}
          setIsDeactivate={setIsDeactivate}
          handleCouponSubmit={handleCouponSubmit}
          setOpenCreateCouponForm={setOpenCreateCouponForm}
          isEditMode={isEditMode}
          handleCouponEditSave={handleCouponEditSave}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </div>
  );
};

export default UserCouponCodesPage;

// UTLILITY / API FUNCTIONS
const getUserCoupons = async (admin_user_id) => {
  if (!admin_user_id) {
    return;
  }

  const res = await axiosConfig({
    method: "get",
    url: `/referral/coupon?userid=${admin_user_id}`,
    data: {},
  });

  return res.data;
};
