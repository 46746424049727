import React, {Suspense, lazy} from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import TopStocksHomeTables from "../../components/common/TopStocksHomeTables";
import { useSelector } from "react-redux";
import NavbarNEW from "../../Layout/NavbarNEW";
import Footer from "../../components/Footer";

import SimpleImageSlider from "react-simple-image-slider";
import image1 from "../../assets/image-slider/banner.jpg";
import ImageTextCarousel from "../../components/common/ImageTextCarousel";
import UserTestimonials from "../../components/UserTestimonials";

import GoogleStoreIcon from "../../assets/play-store-logo.png";
import AppleStoreIcon from "../../assets/apple-store-logo.png";

import styles from "./HomePage.module.css";
import TopBanner from "../../components/homepage/TopBanner";
import StepsBanner from "../../components/homepage/StepsBanner";
import MainImageSlider from "../../components/homepage/MainImageSlider";
import YoutubeTutorial from "../../components/homepage/YoutubeTutorial";
import IconBadges from "../../components/homepage/IconBadges";
import HelmetSEO from "../../components/common/HelmetSEO";
import CallToActionBanner from "../../components/homepage/CallToActionBanner";
import ImageSlider from "../../components/common/ImageSlider";
// import OurMissionAndVideoSection from "../../components/homepage/OurMissionAndVideoSection";
// import NewStepsSection from "../../components/homepage/NewStepsSection";
// import VideoGuidesSection from "../../components/homepage/VideoGuidesSection";
// import NewUserTestimonials from "../../components/homepage/NewUserTestimonials";
import topBannerImg from '../../assets/top-banner-img.jpg'

import InfoCards from "../../components/homepage/InfoCards";
import MobileAppPitch from "../../components/homepage/MobileAppPitch";

// LAZY LOADING COMPONENTS
const OurMissionAndVideoSection = lazy(() => import("../../components/homepage/OurMissionAndVideoSection"));
const NewStepsSection = lazy(() => import("../../components/homepage/NewStepsSection"));
const VideoGuidesSection = lazy(() => import("../../components/homepage/VideoGuidesSection"));
const NewUserTestimonials = lazy(() => import("../../components/homepage/NewUserTestimonials"));



const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
  // border: '1px solid',
  borderRadius: "4px",
  // boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.75)",

  // margin: '14px 0',
  height: "100%",
}));

const readMoreText =
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi voluptatibus dignissimos libero, autem quae accusamus numquam aut laboriosam ex, atque delectus. Deleniti laborum, id doloribus qui praesentium consectetur corporis dolorum. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam aut nostrum, aspernatur ex praesentium autem veritatis architecto quisquam exercitationem officia officiis et magnam hic dolor! Exercitationem natus nam accusamus maiores voluptatum cupiditate ut ipsum esse earum fuga delectus, facilis ex, commodi id nostrum iure quia ea quaerat? Corrupti aliquam qui, amet quaerat sed possimus incidunt doloremque deleniti earum repudiandae nulla tempora, quidem molestias harum veritatis, ullam suscipit sint consectetur corporis neque? Quo, voluptas? Dolorem doloremque porro aperiam, quis at laboriosam.";

// buying opportunities list
const buying_opps_list = [
  { id: 1, value: "stocks", label: "STOCKS", linkTo: "/stocks" },
  { id: 2, value: "crypto", label: "CRYPTO", linkTo: "/crypto" },
  { id: 3, value: "options", label: "OPTIONS", linkTo: "/options" },
];

// selling opportunities list
const selling_opps_list = [
  { id: 1, value: "stocks", label: "STOCKS", linkTo: "/stocks" },
  { id: 2, value: "crypto", label: "CRYPTO", linkTo: "/crypto" },
  { id: 3, value: "options", label: "OPTIONS", linkTo: "/options" },
];

// video tutorials list
const video_tutorials_list = [
  { id: 1, value: "stocks", label: "Trading Basics", linkTo: "/stocks" },
  { id: 2, value: "crypto", label: "How read Chart", linkTo: "/crypto" },
  { id: 3, value: "options", label: "Best Indicators", linkTo: "/options" },
  {
    id: 4,
    value: "options",
    label: "How to read Patterns",
    linkTo: "/options",
  },
];

const user = JSON.parse(localStorage.getItem("user"));

export default function HomePage() {
  const userState = useSelector((store) => store.auth);

  const images = [{ url: image1 }, { url: image1 }, { url: image1 }];

  return (
    <>
      {/* NAVBAR */}
      {/* <NavbarNEW
        isAuth={!!user}
        userDetails={userState?.user?.userDetails}
        showSearchBox={true}
      /> */}
      <div>
        {/* <Container maxWidth="lg"> */}

        <HelmetSEO
          title="TNIBro"
          description="Trading & Investing Brother"
          pageUrl={process.env.REACT_APP_ROOT_URL}
        />

        {/* COMMENTED OUT 'TOP BANNER' AS NOT NEEDED FOR TIME BEING */}
        {/* TOP HERO / BANNER */}
        {/* <TopBanner /> */}

        {/* UNCOMMENT AFTER DESIGNING */}
        {/* PNG IMAGE SLIDER - COMIC GUYS TALKING */}
        {/* <div className={styles.imageSliderContainer}>
          <ImageSlider imageList={[image_01, image_02]} />
        </div> */}

        {/* TOP IMAGE BANNER  */}
        <div className={styles.topImageBannerContainer}>
          <img className={styles.topBannerImg} src={topBannerImg} alt="stock yaari logo" loading="lazy" />
        </div>
        

        {/* INFO CARDS */}
        <InfoCards />

        {/* MOBILE APP SALES PITCH SECTION */}
        <MobileAppPitch />

        {/* IMP: UNCOMMENT AFTER COMPLETING DESIGN */}
        {/* TOP STOCKS TABLES */}
        <div className={styles.topStocksTableContainer}>
          <TopStocksHomeTables />
        </div>

        
        {/* OUR MISSION, OUR VISION SECTION */}
        <Suspense fallback={<div>LAZY LOADING...</div>}>
          <OurMissionAndVideoSection />
        </Suspense>

        {/* DISPLAY NONE AS MAKING NEW DESIGN */}
        <Box >
          {/* IMAGE/ TEXT SLIDER */}
          <div style={{ margin: "2rem 0 0", padding: "0 5%" }}>
            {/* <ImageTextCarousel /> */}
          </div>

          {/* ANDROID APPLE ICON BADGE COMPONENT */}
          <IconBadges />

          {/* STEPS AND ICONS BANNER */}
          {/* <StepsBanner /> */}

          {/* NEW STEPS BANNER SECTION */}
          <Suspense fallback={<div>LAZY LOADING...</div>}>
            <NewStepsSection />
          </Suspense>
          

          {/* IMPORTANT: DISPLAY NONE */}
          {/* How it works container */}
          <Box
            sx={{
              margin: "2rem 0.5rem",
              // minHeight: "400px",
              p: "1rem 0",
              // display: 'none'
            }}
                    >
            <Typography variant="h3" align="center">
              How it works
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ margin: "8px 0" }}>
                <Item>
                  <Box sx={{ p: "0 16px" }}>
                    <h1>grid content</h1>
                    <Typography variant="body2" align="justify">
                      {readMoreText.slice(0, 300)}...
                      <Link to="/">Read More</Link>
                    </Typography>
                  </Box>
                </Item>
              </Grid>

              <Grid item xs={12} md={6} sx={{ margin: "8px 0" }}>
                <Item>
                  <Box sx={{ p: "0 16px" }}>
                    <h2 style={{ textAlign: "center" }}>Video Tutorials</h2>
                    <iframe
                      width="100%"
                      height="200"
                      title="Watch Video Tutorial"
                      src="https://www.youtube.com/embed/tgbNymZ7vqY"
                    ></iframe>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Box>

          

          {/* NEW VIDEO TUTORIALS & GUIDES SECTION */}
          <Suspense fallback={<div>LAZY LOADING...</div>}>
            <VideoGuidesSection />
          </Suspense>
          

          {/* USER TESTIMONIALS */}
          {/* <UserTestimonials /> */}
        </Box>

        {/* COMMENTED OUT AS ADVISED */}
        {/* NEW USER TESTIMONIALS */}
        <Suspense fallback={<div>LAZY LOADING...</div>}>
          <NewUserTestimonials />
        </Suspense>

        {/* CALL TO ACTION BANNER */}
        {/* COMMENTED OUT AS NOT NEEDED IN THE NEW DESIGN */}
        {/* <CallToActionBanner /> */}
      </div>
      {/* </Container> */}

      {/* <Footer /> */}
    </>
  );
}
