import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SavedScanDetails from '../../SavedScan/SavedScanDetails';
import { Box, CircularProgress, Stack } from '@mui/material';
import Screener from '../../Screener/Screener';
import toast from "react-hot-toast";
import ReactHotToaster from '../../components/common/ReactHotToaster';
import DenseTable from '../../components/Tables/Tables';
import { Suspense } from 'react';
import BackTestTiles from '../../SavedScan/BackTestTiles';
import SymbolsListTable from '../../SavedScan/SymbolsListTable';

import axiosConfig from '../../axios/axiosConfig';
import NewScan from '../../components/main/NewScan';


const SingleSavedScanPage = () => {
  const { screenerId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [screenerObjDetails, setScreenerObjDetails] = useState({});
  const [savedScreenerResult, setSavedScreenerResult] = useState(null);
  const [latestStocks, setLatestStocks] = useState([]);
  const [userScreenerAlertDetail, setUserScreenerAlertDetail] = useState({}); // user's screener alert details
  const [savedScan, setSavedScan] = useState({});
  const [scanQuery, setScanquery] = useState([]);
  const [selectedStockCategory, setSelectedStockCategory] = useState("");

  
  
  // CHANGES ON 16/08/2023
  const [showBackTestTiles, setShowBackTestTiles] = useState(true);
  const [timeframeDate, setTimeframeDate] = useState(null);
  const [symbolsData, setSymbolsData] = useState([]);
  const [isSymbolsDataLoading, setIsSymbolsDataLoading] = useState(false);

  // PAGINATION STATES FOR BACKTESTING
  const [backtestCurrentPage, setBacktestCurrentPage] = useState(1);
  const [backtestTotalStocks, setBacktestTotalStocks] = useState(0);

  const [displayFilterData, setDisplayFilterData] = useState([]);


  const handleUpdateScreener = (editedFields, id) => {
    axios
      .patch(`/tnibroadmin/scanner?id=${id}`, {
        ...editedFields,
      })
      .then((response) => {
        const scanList = {
          ...response.data.response,
          scanner_query: JSON.parse(response.data.response.scanner_query),
        };
        setSavedScan(scanList);

        const query = JSON.parse(response.data.response.scanner_query);
        setScanquery(query);
        toast.success("Screener details updated!");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  const handlePublishToApp = (val, id) => {
    axios
      .patch(`/tnibroadmin/scanner?id=${id}`, {
        publish: val,
      })
      .then((response) => {
        toast.success(val ? "Published to app!" : "unplublished from App");
        // handleFetchScanList();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      });
  };

  
  const fetchBacktestSymbols = async () => {
    if (!timeframeDate || timeframeDate === "" || !screenerId) {
      return;
    }

    setIsSymbolsDataLoading(true);

    try {
      const res = await axiosConfig({
        method: "post",
        url: "/backtest",
        // url: `/backtest?page_no=${backtestCurrentPage}&per_page=${rowsPerPage}`,
        data: {
          screenerid: screenerId || savedScan?.id,
          timeframedate: timeframeDate,
        },
      });
      
      if (res.status === 200 || res.statusText === "OK") {
        setBacktestTotalStocks(res.data.total_length);
        setSymbolsData(res.data.response);
      }
      
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    } finally {
      setIsSymbolsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchBacktestSymbols();
  }, [timeframeDate, screenerId, savedScan?.id]);

  
  useEffect(() => {
    // setSavedScreenerResult(null);
    // setScanquery([]);
    // setSavedScan([]);

    const fetchScreenerDetails = async () => {
      setIsLoading(true);
      axios
        // .get(`/tnibroadmin/scanner?page_no=${globalCurrentPage}&per_page=${rowsPerPage}`, {
        .get('/tnibroadmin/scanner', {
          params: {
            id: screenerId,
          },
        })
        .then((response) => {
          // SetSavedScanList(response.data.response); // new code for dense table screener data
          
          if (response.status === 200 || response.statusText === "OK") {
            setScreenerObjDetails(response.data.scanner_data);
            setSavedScreenerResult(response.data);
            // fetchBacktestSymbols();

            setLatestStocks(response?.data?.latest_stock);
            setUserScreenerAlertDetail(
              response.data.scanner_data?.screener_alert[0]
            );
          }

          // If query is NOT hidden
          if (!response.data.scanner_data.hide_query) {
            const screenerIdResponse = {
              ...response.data.scanner_data,
              scanner_query: JSON.parse(
                response.data.scanner_data.scanner_query
              ),
            };
            setSavedScan(screenerIdResponse);

            const query = JSON.parse(response.data.scanner_data.scanner_query);
            setScanquery(query);
          }
          // if (response.data.hidden) {
            
          // } else {
            
          // }
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.response || "Something went wrong"
          );
          
        })
        .finally(() => {
          // fetchBacktestSymbols();
          setIsLoading(false);
        });
    }

    fetchScreenerDetails();
    // empty/nullify states when component is unmounted
    // return () => setScreenerObjDetails({});
  }, [screenerId]);
  // location.search, currentPage, itemsLimit
  

  return (
    <>
      {/* SCAN DETAILS (NAME, DESCRIPTION) */}
      <span>
        <h2>Saved Scan Details</h2>
      </span>

      {screenerObjDetails?.scan_name ? (
        <SavedScanDetails
          screenerId={screenerId || savedScan?.id}
          screenerObjDetails={screenerObjDetails}
          userScreenerAlertDetail={userScreenerAlertDetail}
        />
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}

      {/* WHEN QUERY IS NOT HIDDEN */}
      {scanQuery.length > 0 ? (
        <div>
          <div>
            {/* WHEN QUERY IS NOT HIDDEN */}
            {/* <Screener
              myScanQuery={scanQuery}
              // checking if int or string below
              segmentQuery={
                isNaN(parseInt(savedScan.segment))
                  ? savedScan.segment
                  : parseInt(savedScan.segment)
              }
              isPublished={savedScan.publish}
              queryNameDesc={{
                scan_name: savedScan.scan_name,
                scan_description: savedScan.scan_description,
              }}
              screenerId={savedScan.id ?? screenerId}
              handleUpdateScreener={handleUpdateScreener}
              handleScannerPublishToApp={handlePublishToApp}
              tableMarginTop={0}
              latestStocks={latestStocks}
            /> */}
            {scanQuery.map(screenerObj => {
              return (
                <NewScan screenerPayload_DataObj={screenerObj} handleFormData={() => {}} setDisplayFilterData={setDisplayFilterData} handleTextField={() => {}}  />
              )
            })}
            
          </div>
        </div>
      ) : null}

      

      {/* SECTION 2: QUERY IS HIDDEN */}
      {/* {savedScreenerResult ? (
        <div>
          <SavedScanWithResult
            tableFeed={savedScreenerResult || []}
            screenerObjDetails={screenerObjDetails}
            screenerId={screenerId}
            latestStocks={latestStocks}
            userScreenerAlertDetail={userScreenerAlertDetail}
          />
        </div>
      ) : null} */}

      {/* WHEN QUERY IS HIDDEN */}
      {/* IMP: REPLACE BELOW CODE WITH HIDDEN QUERY LOGIC */}
      {/* {!scanQuery || scanQuery.length <= 0 ? (
        savedScreenerResult?.response ? (
          <DenseTable
            // tableData={savedScanList}
            tableData={savedScreenerResult?.response}
            // setRowsPerPage={setRowsPerPage}
            // maxHeight="500px"
            selectedStockCategory={selectedStockCategory}
            setSelectedStockCategory={setSelectedStockCategory}
            latest_stocks={latestStocks}
          />
        ) : (
          <>
            <Stack direction="row" spacing={2}>
              <h1>Loading...</h1>
              <CircularProgress />
            </Stack>
          </>
        )
      ) : null} */}

      {/* THIS TABLE IS COMMON WHETHER QUERY IS HIDDEN OR NOT */}
      {savedScreenerResult?.response  ? (
          <DenseTable
            // tableData={savedScanList}
            tableData={savedScreenerResult?.response}
            // setRowsPerPage={setRowsPerPage}
            // maxHeight="500px"
            selectedStockCategory={selectedStockCategory}
            setSelectedStockCategory={setSelectedStockCategory}
            latest_stocks={latestStocks}
          />
        ) : (
          <>
            <Stack direction="row" spacing={2}>
              <h1>Loading...</h1>
              <CircularProgress />
            </Stack>
          </>
        )}

      {/* BACKTEST TABLE AND DATE WISE TABLE DATA */}

      {isSymbolsDataLoading ? (
        <Stack direction="row" spacing={2}>
          <h1>Backtest Data Loading...</h1>
          <CircularProgress />
        </Stack>
      ) : showBackTestTiles ? (
        <BackTestTiles
          setTimeframeDate={setTimeframeDate}
          timeframeDate={timeframeDate}
          setShowBackTestTiles={setShowBackTestTiles}
          screenerId={savedScan.id ?? screenerId}
        />
      ) : (
        <Suspense fallback={<div>TABLE IS LOADING, please wait...</div>}>
            <SymbolsListTable
          symbolsData={symbolsData}
          setSymbolsData={setSymbolsData}
          setShowBackTestTiles={setShowBackTestTiles}
          timeframeDate={timeframeDate}
          isSymbolsDataLoading={isSymbolsDataLoading}
          totalStocks={backtestTotalStocks}
          currentPage={backtestCurrentPage}
          setCurrentPage={setBacktestCurrentPage}
        />
          </Suspense>
       
      )}

      {/* REACT TOAST */}
      <ReactHotToaster />
    </>
  )
}

export default SingleSavedScanPage