import { Container } from "@mui/material";
import React from "react";

import styles from '../terms-conditions/TermsAndConditions.module.css';

const RefundsAndCancellation = () => {
  return (
    <Container maxWidth="lg" sx={{ minHeight: "calc(100vh - 150px)"}} >
      <div className={styles.RefundsAndCancellation}>
      <h1 >Refund and Cancellation Policy</h1>

      <p>
        As our products are intangible and irrevocable, we do not provide
        refunds once the order has been fulfilled and the service has been
        activated on www.tnibrocom. As a customer, it is your responsibility to
        comprehend and acknowledge this before making any purchases from our
        site. While we take the utmost care in ensuring the functionality and
        uptime of our services, we cannot guarantee it. However, in the event of
        an outage or other uncontrollable circumstances, we may extend your
        subscription duration as a goodwill gesture.
      </p>

      <p>
        In rare cases, if there are technical issues with our product or service
        that cannot be resolved within a reasonable amount of time, we may offer
        a partial or full refund at our discretion. We will work with the
        customer to resolve the issue before considering a refund.
      </p>

      <p>
        If you have any questions or concerns about our refund policy, please
        contact our customer support team for assistance.
      </p>
      </div>
    </Container>
  );
};

export default RefundsAndCancellation;
