import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";

export default function AutoComplete({
  options,
  value,
  currentValue,
  autoopen,
  disabled,
  handleDropdownValue,
  label,
  defaultValue,
  textFieldColor,
  textFieldWidth = 140,
  textFieldVariant = "filled", // outlined, filled, standard
  
}) {
  const [open, setOpen] = useState(false);

  // OPTIONS IS AN ARRAY OF OBJECTS
  const onChangeOption = (optionsItemObj) => {
    
    // 'optionsItemObj' is an item object from the options array, containing { group, label, value }
    // 1st argument 'optionsItemObj.value' is the option selected like 'max', 'min', 'high', 'low', etc.
    // 2nd argument 'value' is the type of Autocomplete comp, e.g. 'measure1', 'timeframe1'
    handleDropdownValue(optionsItemObj.value, value);
  };

  // COMMENTED OUT THIS CODE 
  useEffect(() => {
    if (autoopen && !currentValue) {
      setOpen(true);
    }

    if (!autoopen && !currentValue) {
      handleDropdownValue(defaultValue ?? options[0].value, value);
    }
  }, [autoopen, currentValue, defaultValue, value, options]);

  return (
    <div
      style={{
        marginLeft: "5px",
      }}
    >
      <Autocomplete
        disabled={disabled}
        // color="red"
        size="small"
        open={open}
        options={options}
        value={currentValue ?? ""}
        // autoOpen={autoopen || false}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        groupBy={(option) => option.group}
        // getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => {
          onChangeOption(newValue);
        }}
        id="combo-box-demo"
        // sx={{ width: open ? 190 : 180 }}
        // sx={{ width: open ? 190 : textFieldWidth }}
        sx={{ width: textFieldWidth }}
        renderInput={(params) => (
          <TextField
          
            sx={{
              input: {
                color: textFieldColor,
                fontWeight: "bold",
                textAlign: "center",
              },
              width: '100%'
            }}
            {...params}
            label={label}
            variant={textFieldVariant}
          />
        )}
      />
    </div>
  );
}
