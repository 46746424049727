
import axiosConfig from '../../../axios/axiosConfig';
import { axiosNoToken } from '../../../axios/axiosConfig';

// GET OTP HANDLER account activation or deactivation
export const getOTP = async (USERID, mobileNumber) => {
  try {
    const res = await axiosNoToken({
      method: "post",
      url: "/user/account_activation",
      data: {
        userid: USERID,
        phone: `91${mobileNumber}`,
      },
    });

    return res;
    
  } catch (error) {
    console.log(error.response);
    return error;    
  }
};



// VERIFY / SUBMIT OTP HANDLER
export const verifyOTP = async (mobileNumber, sessionID, mobileOTP, USERID, action) => {
  try {
    const res = await axiosConfig({
      method: "post",
      url: "/user/activation_verification",
      data: {
        phone: `91${mobileNumber}`,
        session_id: sessionID,
        otp: parseInt(mobileOTP),
        userid: USERID,
        action: action,
      },
    });

    return res;
    
  } catch (error) {
    console.log("error: ", error);
    return error;
  }
};