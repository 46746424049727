import { SET_SEARCH_DATE, SET_SEARCH_INPUT, SET_SEARCH_SYMBOL, SET_SEARCH_SEGMENT } from "../actions/types";

const INITIAL_DATE = new Date().toISOString().slice(0, 10); // Today's Date as compatible with date input eg 
  // SEARCH AUTOCOMPLETE STATES

const initialState = {
  searchInput: "",
  searchDate: INITIAL_DATE,
  searchSymbol: "",
  searchSegment: ""
};

export default function globalSearchReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SEARCH_DATE:
      return { ...state, searchDate: payload };

    case SET_SEARCH_INPUT:
      return { ...state, searchInput: payload };

    case SET_SEARCH_SYMBOL:
      return { ...state, searchSymbol: payload };

    case SET_SEARCH_SEGMENT:
      return { ...state, searchSegment: payload };

    default:
      return state;
  }
}