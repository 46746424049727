import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import axiosConfig from "../../../../axios/axiosConfig";
import SymbolAlertTable from "./SymbolAlertTable";
import PriceAlertDetailsTable from "./PriceAlertDetailsTable";
import { Stack, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import toast from "react-hot-toast";
import ReactHotToaster from "../../../common/ReactHotToaster";

export default function SymbolAlertDetailsPage() {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  
  // ALL USER ALERTS DATA FOR SUPER ADMINs
  const [symbolAlertData, setSymbolAlertData] = useState([]); // Alert count for specific symbol
  const [priceAlertData, setPriceAlertData] = useState([]); // Price Alert data for specific symbol

  const { symbol } = useParams();
  

  // FETCH ALL ALERTS DATA
  useEffect(() => {
    const fetchSymbolData = async () => {
      try {
        const res = await axiosConfig({
          method: "get",
          url: `/admin_alert?userid=${userDetails.id}&alert_symbol=${symbol}`,
          data: {},
        });

        
        if (res.status === 200 || res.statusText === "OK") {
          setSymbolAlertData(res.data.response);
          setPriceAlertData(res.data.price_alert_data);
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.response || "Something went wrong");
      }
    };

    fetchSymbolData()
  }, [userDetails.id, symbol]);

  return (
    <Box sx={{ p: '2rem'}} >
      {/* <h1>Symbol Alerts details page with 2 tables</h1> */}
      <Button size="small" variant="contained">back</Button>

      <Stack direction="column" spacing={6} >
        <SymbolAlertTable symbolAlertData={symbolAlertData} />

        <PriceAlertDetailsTable priceAlertData={priceAlertData} />
      </Stack>

      {/* REACT TOAST */}
      <ReactHotToaster />
    </Box>
  );
}
