import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  STORE_USER_DETAILS,
  UPDATE_EMAIL_VERIFICATION,
  UPDATE_USER_DETAILS
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

function authReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case UPDATE_EMAIL_VERIFICATION:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };

    case UPDATE_USER_DETAILS:
      return {
        ...state,
        isLoggedIn: true,
        user: { ...state.user, userDetails: payload.user },
      };

    case STORE_USER_DETAILS:
      return {
        ...state,
        user: { ...state.user, userDetails: payload },
      };

    default:
      return state;
  }
}

export default authReducer;