import React from "react";
import { STOCK_CATEGORIES2 } from "../../../utils/screener-dropdown-options";
import { Stack } from "@mui/material";

import styles from "./FilterByStockCategories.module.css";
// import { useDispatch, useSelector } from "react-redux";
// import { setStockCategory } from "../../../actions/stock-category";

const FilterByStockCategories = ({ selectedStockCategory, setSelectedStockCategory, disableInput = false }) => {
  // const dispatch = useDispatch();
  
  // const { selectedStockCategory } = useSelector((state) => state.stockCategory);
  

  // stock category select list handle change.
  const stockCategoryChange = (event) => {
    setSelectedStockCategory(event.target.value);
    localStorage.setItem('selected_sector', JSON.stringify({ selectedStockCategory : event.target.value}))
    // dispatch(setStockCategory(event.target.value))
  };

  return (
    <Stack direction="row" spacing={1} sx={{ marginTop: "0.2rem" }}>
      {/* <span className="h2">Choose Sector:</span> */}
      <select
        // className={styles.select_Input}
        className={`${styles.select_Input} ${selectedStockCategory ? styles.blinking_border : ""}`}
        name="Stock Categories"
        placeholder="Select Category"
        value={selectedStockCategory}
        onChange={stockCategoryChange}
        // style={{border: selectedStockCategory ? '2px solid red': ""}}
        disabled={disableInput}
      >
        <option value="">Select Any</option>
        {STOCK_CATEGORIES2.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </Stack>
    // <Stack direction="row" spacing={1} sx={{ margin: "1rem 0" }}>
    //   <span className="h2">Choose Sector:</span>
    //   <select
    //     className={styles.select_Input}
    //     style={{ border: `1px solid ${selectedStockCategory ? "red" : "none"}`}}
    //     name="Stock Categories"
    //     placeholder="Select Category"
    //     id="stock-categories"
    //     value={selectedStockCategory}
    //     onChange={stockCategoryChange}
    //   >
    //     <option value="">Show All</option>
    //     {STOCK_CATEGORIES1.map((item) => {
    //       return (
    //         <optgroup label={item.group_label}>
    //           {item.list_items.map((listItem) => {
    //             return (
    //               <option value={listItem.item_value}>
    //                 {listItem.item_label}
    //               </option>
    //             );
    //           })}
    //         </optgroup>
    //       );
    //     })}
    //   </select>
    // </Stack>
  );
};

export default FilterByStockCategories;
