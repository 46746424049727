import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import NavbarNEW from "./NavbarNEW";
import { useSelector } from "react-redux";
// import Footer from "../components/Footer";
import { Container } from "@mui/material";
import CountdownTimer from "../components/homepage/CountdownTimer";
import NewFooter from "../components/homepage/NewFooter";
import Footer from "../components/homepage/Footer";

const user = JSON.parse(localStorage.getItem("user"));

export default function HomePageLayout() {
  // const {
  //   user: { userDetails },
  // } = useSelector((store) => store.auth);
  const userState = useSelector((store) => store.auth);

  return (
    <>
      {/* OFFER TOOLBAR HERE */}
      <div style={styles.offerToolbar}>
        <p>
          GRAB EXCLUSIVE OFFER! USE CODE - TNIBRO30 & GET 30% OFF. 
        </p>
        <span>LIMITED TIME OFFER !! OFFER ENDS IN <CountdownTimer />{" "}</span>
        <button style={styles.offerButton}>AVAIL OFFER</button>
      </div>

      <NavbarNEW
        isAuth={!!user}
        userDetails={userState?.user?.userDetails}
        showSearchBox={true}
      />

      {/* <Container maxWidth="lg">
        <Outlet />
      </Container> */}

      <div style={styles.outletContainer}>
        <Outlet />
      </div>

      {/* <Footer /> */}

      {/* NEW FOOTER */}
      {/* <NewFooter /> */}
      <Footer />
    </>
  );
}

const styles = {
  outletContainer: {
    // padding: '0 2%',
  },
  offerToolbar: {
    // width: '100%',
    padding: '6px',
    // maxHeight: "70px",
    backgroundColor: "#4682B4",
    fontWeight: 300,
    color: "white",
    display: "flex",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: 'wrap',
    borderBottom: "3px solid #0BDA51",
  },
  offerButton: {
    border: "none",
    outline: "none",
    padding: "2px 14px",
    borderRadius: 22,
    backgroundColor: "#0BDA51",
    color: "white",
    fontFamily: "inherit",
    fontWeight: "400",
    fontSize: "16px",
    // display: 'inline-block'
    // letterSpacing: '1px'
  },
  date: {
    backgroundColor: "black",
    display: "inline-block",
    width: "80px",
    textAlign: "center",
    borderRadius: 2,
    fontFamily: "Orbitron",
    // fontWeight: 'bold',
    fontSize: "16px",
    padding: "2px",
  },
};
