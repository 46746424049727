import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Stack,
  Typography,
  Pagination,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import MuiModal from "../../muiComps/MuiModal";
import ChooseAlertTypeModal from "../../Tables/ChooseAlertTypeModal";

import axiosConfig from "../../../axios/axiosConfig";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchUserAlerts } from "../../../api/userAlerts";
import MuiLinearProgressLoader from "../../muiComps/MuiLinearProgressLoader";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";
import MuiSelectInput from "../../muiComps/MuiSelectInput";

const rowsPerPageOptions = [
  { id: 1, label: "5", value: "5" },
  { id: 2, label: "10", value: "10" },
  { id: 3, label: "25", value: "25" },
  { id: 4, label: "50", value: "50" },
  { id: 5, label: "100", value: "100" },
];

const UserAlertsTable = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const [userAlertDetails, setUserAlertDetails] = useState([]); // all Alert Data for User
  const [alertDetails, setAlertDetails] = useState({
    symbol: "",
    price: "",
    alert_id: "",
  });

  const [openAlertTypeModal, setOpenAlertTypeModal] = useState(false);

  // DELETE CHECKBOXES
  const [deleteAllBtnChecked, setDeleteAllBtnChecked] = useState(false);
  const [rowButtonsChecked, setRowButtonsChecked] = useState([]); // Array of Objects
  const filteredAlertIDs = rowButtonsChecked
    ?.filter((alert) => alert.isChecked)
    .map((alert) => alert.id);

  // STATES FOR TABLE PAGINATION
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]?.value);
  const [totalAlerts, setTotalAlerts] = useState(0);
  const totalPagesCount = Math.ceil(totalAlerts / rowsPerPage);

  // onClick Handler for 'DELETE ALL ALERTS'
  const DeleteAllAlerts = async () => {
    const consent = window.confirm(
      "Are you sure you want to delete all Alerts? Click OK to confirm."
    );
    if (!consent) return;

    try {
      const res = await axiosConfig({
        method: "delete",
        url: "tnibroapp/bulk_alert",
        data: { userid: userDetails.id, user_alerts: filteredAlertIDs },
      });

      if (res.status === 200 || res.statusText === "OK") {
        // delete all if delete all checkbox is checked.
        if (deleteAllBtnChecked) {
          setUserAlertDetails([]);
        } else {
          const newUserAlertDetails = userAlertDetails.filter((item) => {
            return !filteredAlertIDs.includes(item.id);
          });
          setUserAlertDetails(newUserAlertDetails);
        }

        toast.success("Alerts deleted successfuly!");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response || "Something went wrong");
    }
  };

  // SELECT / DESELECT ALL BUTTON
  const handleChangeDeleteAllBtn = (event) => {
    setDeleteAllBtnChecked(event.target.checked);
  };

  // handle change for ROW SELECT / UNSELECT BUTTON, FOR SINGLE ROW
  const handleChangeRowBtns = (event, alert_id) => {
    // setChecked(event.target.checked);
    setRowButtonsChecked((prevState) => {
      return prevState.map((alert) => {
        if (alert.id === alert_id) {
          return { ...alert, isChecked: event.target.checked };
        }

        return alert;
      });
    });
  };

  // useEffect for Select / Deselect Button
  useEffect(() => {
    if (rowButtonsChecked.length === 0) return;

    setRowButtonsChecked((prevState) => {
      return prevState.map((alert) => {
        return { ...alert, isChecked: deleteAllBtnChecked };
      });
    });
  }, [deleteAllBtnChecked, rowButtonsChecked.length]);

  // handle when user clicks on Edit button
  const handleEditClick = async (singleAlert) => {
    setOpenAlertTypeModal(true);

    setAlertDetails({
      symbol: singleAlert.symbol_id.name,
      price: singleAlert.price,
      alert_id: singleAlert.id,
    });
  };

  // FETCH ALL ALERTS FOR THE USER
  const userAlertsQuery = useQuery({
    queryKey: ["user-alerts", activePage, rowsPerPage],
    queryFn: () => fetchUserAlerts(userDetails.id, activePage, rowsPerPage),
    onSuccess: (data) => {
      setUserAlertDetails(data.response);
      setTotalAlerts(data?.total_length);
      // setTotalPages(data.response?.length);

      // storing alert IDs for checkboxes
      const alertIDs = data.response?.map((alert) => {
        return {
          id: alert.id,
          isChecked: false,
        };
      });
      setRowButtonsChecked(alertIDs);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // useEffect(() => {
  //   const fetchUserAlerts = async () => {
  //     try {
  //       const res = await axiosConfig({
  //         method: "get",
  //         url: `tnibroadmin/alert?userid=${userDetails.id}`,
  //         data: {},
  //       });

  //       if (res.status === 200 || res.statusText === "OK") {
  //         setUserAlertDetails(res.data.response);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       toast.error(`Something went wrong: ${error.response || error}.`);
  //     }
  //   };

  //   fetchUserAlerts();
  // }, [userDetails.id]);

  // DELETE ALERT, alert is an object containing all alert details
  const handleDeleteAlert = async (alertDetail) => {
    const isDelete = window.confirm(
      "Are you sure you want to delete this alert?"
    );

    if (!isDelete) return;

    try {
      const res = await axiosConfig({
        method: "delete",
        url: `/tnibroadmin/alert?alert_id=${alertDetail.id}`,
        data: {},
      });

      if (res.status === 200 || res.statusText === "OK") {
        setUserAlertDetails((prev) => {
          return prev.filter((alert) => alert.id !== alertDetail.id);
        });
        toast.error(`Alert deleted for ${alertDetail.symbol_id.name}.`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const headingStyles = { fontWeight: "bold" };

  if (userAlertsQuery.isLoading) {
    return <MuiLinearProgressLoader />;
  }

  return (
    <>
      <Box sx={{ maxWidth: 1200 }}>
        {/* <h1>User Alerts</h1> */}

        {/* DELETE ALL ALERTS BUTTON */}

        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          mb={1}
        >
          <Stack>
            <Button
              disabled={
                userAlertDetails.length === 0 || filteredAlertIDs.length === 0
              }
              size="small"
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
              onClick={DeleteAllAlerts}
            >
              Delete All ({filteredAlertIDs?.length})
            </Button>
          </Stack>

          <Stack spacing={2} direction="row" alignItems="center">
            <MuiSelectInput
              label="Rows Per Page"
              selectedInput={rowsPerPage}
              setSelectedInput={setRowsPerPage}
              inputOptions={rowsPerPageOptions}
            />
            <Pagination
              count={totalPagesCount}
              variant="outlined"
              shape="rounded"
              page={activePage}
              onChange={(event, value) => setActivePage(value)}
            />
          </Stack>
        </Stack>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 900 }}
            size="small"
            aria-label="user alerts table"
          >
            <TableHead sx={{ backgroundColor: "#FFF5EE" }}>
              <TableRow>
                <TableCell align="center" sx={headingStyles}>
                  S.No.
                </TableCell>

                {/* SELECT ALL, DELETE ALL CHECKBOX */}
                <TableCell align="center" sx={headingStyles}>
                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        disabled={false}
                        control={
                          <Checkbox
                            checked={deleteAllBtnChecked}
                            onChange={handleChangeDeleteAllBtn}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        // label="Select All"
                      />
                    </FormGroup>
                  </Box>
                </TableCell>

                <TableCell align="center" sx={headingStyles}>
                  Symbol
                </TableCell>
                <TableCell align="right" sx={headingStyles}>
                  Alert Type
                </TableCell>
                <TableCell align="right" sx={headingStyles}>
                  Alert Price
                </TableCell>

                <TableCell align="right" sx={headingStyles}>
                  Timeframe
                </TableCell>
                <TableCell align="right" sx={headingStyles}>
                  Alert Set On
                </TableCell>
                <TableCell align="right" sx={headingStyles}>
                  Alert Triggered On
                </TableCell>
                <TableCell align="right" sx={headingStyles}>
                  Alert Active?
                </TableCell>
                <TableCell align="center" sx={headingStyles}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "#FAF9F6" }}>
              {userAlertDetails.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {index + 1}
                  </TableCell>

                  {/* CHECKBOX */}
                  <TableCell align="center" component="th" scope="row">
                    <Box>
                      <Checkbox
                        checked={rowButtonsChecked[index]?.isChecked}
                        onChange={(event) => handleChangeRowBtns(event, row.id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Box>
                  </TableCell>

                  <TableCell align="center">{row.symbol_id?.name}</TableCell>
                  <TableCell align="right">
                    <strong>
                      {row?.alert_type.split(" ")[0].toUpperCase()}
                    </strong>
                  </TableCell>
                  <TableCell align="right">
                    {/* {row.price ? row.price : "-"} */}
                    {formatAlertPrice(
                      row?.alert_type,
                      row?.price,
                      row?.alert_active
                    )}
                  </TableCell>

                  <TableCell align="right">{row.timeframe}</TableCell>
                  <TableCell align="right">{row?.set_alert_time}</TableCell>
                  <TableCell align="right">
                    {row?.triggered_alert_time}
                  </TableCell>

                  <TableCell align="center">
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Alert is Active">
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            backgroundColor: row.alert_active ? "green" : "red",
                          }}
                        />
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 100 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "space-evenly" }}
                    >
                      {/* <Button
                        size="small"
                        variant="contained"
                        endIcon={<EditIcon />}
                        onClick={() => handleEditClick(row)}
                      >
                        edit
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        endIcon={<DeleteIcon />}
                        onClick={() => handleDeleteAlert(row)}
                      >
                        Delete
                      </Button> */}

                      <EditIcon
                        color="success"
                        onClick={() => handleEditClick(row)}
                        sx={{ cursor: "pointer" }}
                      />
                      <DeleteIcon
                        color="error"
                        onClick={() => handleDeleteAlert(row)}
                        sx={{ cursor: "pointer" }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* CHOOSE ALERT TYPE MODAL */}
      <MuiModal open={openAlertTypeModal} setOpen={setOpenAlertTypeModal}>
        <ChooseAlertTypeModal
          crudType="edit"
          alertDetails={alertDetails}
          setOpenAlertTypeModal={setOpenAlertTypeModal}
          setUserAlertDetails={setUserAlertDetails}
        />
      </MuiModal>

      {/* REACT HOT TOAST */}
      <ReactHotToaster />
    </>
  );
};

export default UserAlertsTable;

// Utilities
function formatAlertPrice(alert_type, alert_price, alert_active) {
  if (!alert_price) {
    return "-";
  }

  // if (alert_type === "Price Alert") {
  //   return alert_price;
  // }

  if (
    (alert_type === "Sell Alert" || alert_type === "Buy Alert") &&
    alert_active
  ) {
    return "-";
    // if (alert_active) {
    //   return '-';
    // } else {
    //   return alert_price;
    // }
  } else {
    return alert_price;
  }
}
