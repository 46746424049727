import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosConfig from "../../../axios/axiosConfig";
import DeleteIcon from '@mui/icons-material/Delete';

import styles from "./createMarqueeForm.module.css";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const initialInputDetails = {
  marqueeName: "",
  marqueeDesc: "",
  activateDate: "",
  expiryDate: "",
};

export const CreateMarqueeForm = () => {
  const [inputDetails, setInputDetails] = useState(initialInputDetails);
  const { marqueeName, marqueeDesc, activateDate, expiryDate } = inputDetails;
  
  // marquee list state
  const [marqueeList, setMarqueeList] = useState([]);
  
  const { user } = useSelector((state) => state.auth);
  const USERID = user?.userDetails?.id;


  const inputOnChange = (event) => {
    setInputDetails((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  // CREATE NEW MARQUEE FORM
  const postMarquee = useMutation({
    mutationFn: async () => {
      const res = await axiosConfig({
        method: "post",
        url: "appdashboard/announcement",
        data: {
          userid: USERID,
          announcement_name: marqueeName,
          announcement_description: marqueeDesc,
          activation_date: activateDate,
          expiry_date: expiryDate,
        },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setMarqueeList(prevState => {
        return [...prevState, data.response]
      })
      
      setInputDetails(initialInputDetails);
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong");
    },
  });

  // HANDLE CREATE / POST NEW MARQUEE
  const handleCreateMarquee = (event) => {
    event.preventDefault();
    postMarquee.mutate();
    
  };


  // FETCH ALL MARQUEE LIST 
  const marqueeQuery = useQuery({
    queryKey: ["marquee"], // dependency
    queryFn: () => fetchMarqueeList(USERID),
    onSuccess: (data) => {
      setMarqueeList(data.response);
      
    },
    onError: (error) => {
      console.log("watchlist error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });


  // DELETE MARQUEE
const deleteMarquee = async (marquee_id) => {
  try {
    const res = await axiosConfig({
      method: "delete",
      url: `/appdashboard/announcement?announcement_id=${marquee_id}`,
      data: {
        userid: USERID,
        },
    });

    // update state if delete successfully
    setMarqueeList(prevState => {
      const newMarqueeList = prevState.filter(item => item.id !== marquee_id);
      return newMarqueeList
    })
  } catch (error) {
    toast.error(error.message || "Something went wrong");
  }
}

  

  return (
    <div>
      <h2>CreateMarqueeForm</h2>

      {/* name, desc, 2 dates */}
      <form className={styles.marqueeForm}>
        <div className={styles.inputBox}>
          <p>Marquee Name:</p>

          <input
            className={styles.nameInput}
            type="text"
            name="marqueeName"
            id="marqueeName"
            value={marqueeName}
            onChange={inputOnChange}
          />
        </div>

        <div className={styles.inputBox}>
          <p>Marquee Description:</p>
          <textarea
            className={styles.descInput}
            rows={4}
            name="marqueeDesc"
            id="marqueeDesc"
            value={marqueeDesc}
            onChange={inputOnChange}
          />
        </div>

        <div className={styles.inputBox}>
          <p>Activate Date: </p>
          <input
            className={styles.activateDateInput}
            type="date"
            name="activateDate"
            id="activateDate"
            value={activateDate}
            onChange={inputOnChange}
          />
        </div>

        <div className={styles.inputBox}>
          <p>Expiry Date: </p>
          <input
            className={styles.expiryDateInput}
            type="date"
            name="expiryDate"
            id="expiryDate"
            value={expiryDate}
            onChange={inputOnChange}
          />
        </div>

        <button className={styles.marqueeCreateBtn} onClick={handleCreateMarquee}>create</button>
      </form>

      {/* MARQUEE TABLE, previous data */}
      {/* <MuiTable /> */}

      {/* TEMP MARQUEE DETAILS */}
      <div className={styles.marqueeList} >
        {marqueeList?.map((item, index) => {
          return (
            <div key={item.id} className={styles.marqueeItem} >
              <p>{index + 1}.</p>
              <p>{item.announcement_name}</p>
              <p>{item.announcement_description}</p>
              <p>{new Date(item.created_at).toDateString()}</p>
              <p>{new Date(item.expiry_date).toDateString()}</p>
              
              <DeleteIcon className={styles.deleteIcon} onClick={() => deleteMarquee(item.id)} />
            </div>
          )
        })}
      </div>

    </div>
  );
};



// UTILITY FUNCTIONS
export const fetchMarqueeList = async (USERID) => {
  if (!USERID) return;

  const res = await axiosConfig({
    method: "get",
    url: `/appdashboard/announcement?userid=${USERID}&marquee=true`,
    data: {},
  });

  return res.data;
};


