import React from "react";
import { Button, Checkbox, Stack, TextField, Typography } from "@mui/material";

const CreateCouponForm = ({
  couponDetailsInput,
  setCouponDetailsInput,
  isDeactivate,
  setIsDeactivate,
  handleCouponSubmit,
  handleCouponEditSave,
  isEditMode,
  activeUserCouponDetails,
  
}) => {
  const {
    coupon_name,
    coupon_desc,
    coupon_code,
    discount_pct,
    start_date,
    expiry_date,
    // isActive,
  } = couponDetailsInput;

  const CREATE_BTN_DISABLED = !coupon_name || coupon_name.trim() === "" ||
  !coupon_desc || coupon_desc.trim() === "" ||
  !coupon_code || coupon_code.trim() === "" ||
  !discount_pct || discount_pct.trim() === "" ||
  !start_date || start_date.trim() === "" ||
  !expiry_date || expiry_date.trim() === ""

  const couponsInputChangeHandler = (event) => {
    setCouponDetailsInput((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  // handler for isActive Input
  const isDeactiveOnChange = (event) => {
    
    // IMPORTANT: IF INPUT IS TRUE/CHECKED, ADMIN WANTS TO DEACTIVATE
    const input = event.target.checked;

    if (input) {
      const prompt = window.confirm("Are you sure you want to deactivate?");

      // if cancelled / false ()
      if (!prompt) return;
    }

    setIsDeactivate(event.target.checked);
  };


  // LABEL STYLING
  const textLabelStyles = {
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: 1,
  };

  return (
    <div>
      <Typography variant="h6" sx={{
        // display: 'inline-block',
        margin: '1rem 0 2rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: 2,
        border: '1px solid',
        borderRadius: 1,
      }} >Create Coupon</Typography>

      {/* form */}
      <Stack spacing={2} sx={{}}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles} >Coupon Name: </Typography>

          <TextField
            required
            size="small"
            id="coupon_name"
            name="coupon_name"
            label="coupon name"
            value={coupon_name}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>Description: </Typography>

          <TextField
            required
            size="small"
            id="coupon_desc"
            name="coupon_desc"
            label="Description"
            value={coupon_desc}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>Coupon Code: </Typography>

          <TextField
            required
            size="small"
            id="coupon_code"
            name="coupon_code"
            label="coupon"
            placeholder="eg. DIWALIOFFER"
            value={coupon_code}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>Discount %: </Typography>

          <TextField
            required
            type="number"
            size="small"
            id="discount_pct"
            name="discount_pct"
            label="discount"
            placeholder="eg. 50"
            value={discount_pct}
            onChange={couponsInputChangeHandler}
            // defaultValue="Hello World"
            inputProps={{}}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>Start Date: </Typography>

          <input
            required
            type="date"
            min={new Date().toISOString().slice(0, -14)}
            name="start_date"
            id="start_date"
            value={start_date}
            onChange={couponsInputChangeHandler}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" sx={textLabelStyles}>Expiry Date: </Typography>

          <input
            required
            type="date"
            name="expiry_date"
            id="expiry_date"
            value={expiry_date}
            onChange={couponsInputChangeHandler}
          />
        </Stack>

        {/* ONLY SHOW CHECK BOX IF EDIT MODE IS ON */}
        {isEditMode ? (
          <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">
            Deactivate? (Active if unchecked):
          </Typography>

          <Checkbox
            // checked={isActiveInput}
            checked={isDeactivate}
            // onChange={handleChange}
            onChange={isDeactiveOnChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Stack>
        ) : null}
        

        {/* BUTTONS */}
        {isEditMode ? (
          <Stack>
          <Button variant="contained" onClick={handleCouponEditSave}>
            save
          </Button>
        </Stack>
        ) : (
          <Stack>
          <Button disabled={CREATE_BTN_DISABLED} variant="contained" onClick={handleCouponSubmit}>
            create
          </Button>
        </Stack>
        )}
        
      </Stack>
    </div>
  );
};

export default CreateCouponForm;
