import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axiosConfig from "../../../../../axios/axiosConfig";

const CopyScreenerModal = ({ setOpenModal, screenerDetails }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;

  const navigate = useNavigate();

  const [userDashboardList, setUserDashboardList] = useState([]);
  const [dashboardValue, setDashboardValue] = useState("");
  
  // FORMATING SCREENER NAME (APPEND COPY)
  const [newScreenerName, setNewScreenerName] = useState(
    `${screenerDetails.scan_name} COPY`
  );

  const disableSaveBtn = !dashboardValue || !newScreenerName.trim();

  // Fetch all dashboards, for specific user Dashboards List
  useEffect(() => {
    const fetchAllDashboards = async () => {
      try {
        const res = await axiosConfig({
          method: "get",
          url: `/user/dashboard?userid=${USERID}`,
          data: {},
        });

        if (res.status === 200 || res.statusText === "OK") {
          
          const fetchUserDashboardList = res.data?.response?.map((item) => {
            return {
              label: item.dashboard_name,
              id: item.id,
            };
          });
          setUserDashboardList(fetchUserDashboardList);
        }
      } catch (error) {
        console.log(error.response);
      }
    };

    fetchAllDashboards();
  }, [USERID]);

  // HANDLE SELECT DASHBOARD and save screener
  const handleSaveDashboard = async () => {
    // patch screenerid to dashboard here
    try {
      const res = await axiosConfig({
        method: "patch",
        url: "/user/dashboard",
        data: {
          dashboard_id: dashboardValue.id,
          screener_id: screenerDetails.id,
        },
      });

      
      if (res.status === 200 || res.statusText === "OK") {
        // add a cogo toast
        console.log(res.data);
        navigate(`/dashboards/${dashboardValue.id}`);

      } else {
        console.log("something went wrong...");
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setOpenModal(false);
    }
  };

  return (
    <Box>
      <Typography variant="h5" mb={1}>
        Copy Screener
      </Typography>
      <Divider />

      {/* screener name, add TextField */}
      <Box sx={{ mb: 2, mt: 4 }}>
        <TextField
          id="outlined-basic"
          label="Screener Name"
          variant="outlined"
          value={newScreenerName}
          onChange={(e) => setNewScreenerName(e.target.value)}
        />
      </Box>

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={dashboardValue}
        onChange={(event, newValue) => {
          setDashboardValue(newValue);
        }}
        options={userDashboardList}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label="Select Dashboard" />
        )}
      />

      {/* DASHBOARD SAVE BUTTON */}
      <Stack direction="row" spacing={2} mt={2}>
        <Button
          variant="contained"
          disabled={disableSaveBtn}
          onClick={handleSaveDashboard}
        >
          SAVE
        </Button>
      </Stack>
    </Box>
  );
};

export default CopyScreenerModal;
