import axiosConfig from "../../axios/axiosConfig";

// CHECKOUT FUNCTION FOR RAZORPAY PAYMENT

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

// const __DEV__ = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export async function displayRazorpay(USERID, AMOUNT, CURRENCY, SELECTED_PLAN, SELECTED_ADD_ONS, COUPON_CODE_ID, SUBSCRIPTION_TYPE, PLAN_PERIOD) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  // NEW TRY CATCH BLOCK HERE
  try {
    const res = await axiosConfig({
      method: "post",
      url: "/payment/payment_order",
      data: { userid: USERID, amount: AMOUNT, plan_type: SELECTED_PLAN, currency: CURRENCY },
    });

    const data = res.data.response;
    console.log("res.data: ", res.data)
    
    const transaction_id = res.data.user_payment_details.id

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      currency: data.currency,
      amount: data.amount.toString(),
      order_id: data.id,
      name: "TNIBRO",
      callback_url: res.data.callback_url,
      description: "Thanks for paying",
      image: "",
      handler: function (response) {
        // const payment_id = response.razorpay_payment_id;
        
        const data = {
          userid: USERID,
          transaction_id: transaction_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        }
        
        verifyPayment(data, SELECTED_PLAN, SELECTED_ADD_ONS, COUPON_CODE_ID, transaction_id, USERID, SUBSCRIPTION_TYPE, PLAN_PERIOD);
      },
      prefill: {
        name: "STATIC NAME",
        email: "static_email@ndsfdf.com",
        phone_number: "static_mobile",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  } catch (error) {
    console.log(error);
  }

}

// below function is being used above in line 57
async function verifyPayment(DATA, selected_plan, selected_addons, coupon_code_id, transaction_id, user_id, subscription_type, PLAN_PERIOD) {
  try {
    const res = await axiosConfig({
      method: "post",
      url: "/payment/payment_verify",
      data: DATA,
    });

    console.log("payment_verify res: ", res.data)
    
    handleSubscribe(selected_plan, selected_addons, coupon_code_id, transaction_id, user_id, subscription_type, PLAN_PERIOD)
    // IMP: UNCOMMENT IT LATER, PAYMENT SUCCESS URL.
    // window.location.replace(res.data.callback_url);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
}

const numberOfDays = {
  monthly: 30,
  quarterly: 120,
  yearly: 365
}

// HANDLE SUBSCRIPTION AFTER SUCCESSFULL PAYMENT
const handleSubscribe = async (SELECTED_PLAN, selectedAddOns, coupon_code_id, transaction_id, user_id, subscription_type, plan_period) => {
  // Adds Ons Array"
  let addOn_String, NO_OF_DAYS;
  addOn_String = selectedAddOns
    .filter((item) => item.checked)
    .map((item) => item.value)
    .join();
  
  // if (!addOn_String || addOn_String === "") return;
  NO_OF_DAYS = numberOfDays[plan_period]
  const today_date = new Date().toLocaleString()
  const calc_expiry_date = addDays(today_date, NO_OF_DAYS)
  const formatted_date = new Date(calc_expiry_date).toISOString().slice(0, 10)

  const data_params = {
    plan_type: SELECTED_PLAN,
    add_on: addOn_String,
    userid: user_id,
    payment: transaction_id,  // integer ID
    user_coupon: coupon_code_id,
    active: true,
    expiry_date: formatted_date // today's date + 30
  }

  if (addOn_String?.length !== 0) {
    data_params.add_on = addOn_String;
  }

  // POST METHOD IF "NEW PLAN"
  if (subscription_type === "NEW_PLAN") {
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/subscription/user_subscription",
        data: data_params,
      });
  
      console.log(res);
      
      if (res.status === '200' || res.statusText === 'OK') {
        return res;
        
      }
      
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  // PATCH METHOD IF "renew PLAN"
  if (subscription_type === "RENEW_PLAN") {
    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/subscription/user_subscription?userid=${user_id}`,
        data: data_params,
      });
  
      console.log(res);
      
      if (res.status === '200' || res.statusText === 'OK') {
        return res;
        
      }
      
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  
  
};



// Add days to today's date 
function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}