import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";

import GoogleIcon2 from '../../../assets/icons/google-48.png'

const GoogleLogin = ({ loginToGoogle, isCountrySelected }) => {
  const navigate = useNavigate();

  const buttonStyles = {
    border: "none",
    outline: "none",
    padding: "4px 10px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
    fontFamily: "inherit",
    textTransform: "uppercase",
    fontSize: "14px",
    // fontWeight: "bold",
    letterSpacing: "2px",
    color: "#555",
    cursor: 'pointer'
  }

  return (
    <Stack direction="row" spacing={2}>
      <button style={buttonStyles} onClick={loginToGoogle} >
        <img src={GoogleIcon2} alt="google icon" width={20} />
        <span>Login with Google</span>
      </button>
    </Stack>
  );
};

export default GoogleLogin;
