import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DenseTable from "../../../Tables/Tables";

import Toolbar from "@mui/material/Toolbar";

import axiosConfig from "../../../../axios/axiosConfig";

import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, ButtonGroup } from "@mui/material";
import MuiModal from "../../../muiComps/MuiModal";
import CopyScreenerModal from "./CopyScreenerModal";

import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import MuiLinearProgressLoader from "../../../muiComps/MuiLinearProgressLoader";
import toast from "react-hot-toast";
import ReactHotToaster from "../../../common/ReactHotToaster";

const ScreenerTable = ({ screenerID, dashboardId, setScreenerID_List }) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);
  // state for Table, Number of List/ rows selection
  const [rowsPerPage, setRowsPerPage] = useState(50);
  // Stock category filter component
  const [selectedStockCategory, setSelectedStockCategory] = useState("");
  const [screenerDetails, setScreenerDetails] = useState({});
  const { scan_name } = screenerDetails;

  // copy screener to dashboard modal
  const [openCopyScreenerModal, setOpenCopyScreenerModal] = useState(false);

  const [latestStocks, setLatestStocks] = useState([]);

  // this state is for search filter
  const [stockList, setStockList] = useState([]);

  // FETCHING SCREENER DATA
  const screenerDataQuery = useQuery({
    queryKey: ["screener_data", screenerID], // dependency
    queryFn: () => fetchScreenerData(screenerID),
    onSuccess: (response) => {
      // console.log("screenerkadata: ", response)
      setScreenerDetails(response.data.scanner_data);
      setStockList(response.data.response);
      setLatestStocks(response.data?.latest_stock);
    },
    onError: (error) => {
      console.log("fetching screener data error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // HANDLE DELETE SCREENER FUNCTION
  const handleRemoveScreener = async () => {
    const consent = window.confirm(
      "Are you sure you want to remove this Screener?"
    );

    if (!consent) {
      return;
    }

    try {
      const res = await axiosConfig({
        method: "patch",
        url: `/user/dashboard?remove=true`,
        data: {
          dashboard_id: parseInt(dashboardId),
          screener_id: parseInt(screenerID),
        },
      });

      if (res.status === 200 || res.statusText === "OK") {
        setScreenerID_List((prev) => {
          return prev.filter((screener) => screener !== screenerID);
        });
        // alert("Screener Removed Successfully!");
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  if (screenerDataQuery?.isLoading) {
    return <MuiLinearProgressLoader />;
  }

  return (
    <Box>
      {/* <h2>Screener ID: {screenerID}</h2> */}

      <Box sx={{ width: 700, height: "100%", m: "1rem" }}>
        {/* SCREENER HEADER WITH ICONS */}

        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            // sx={{ flex: "1 1 100%" }}
            variant="h6"
          >
            {scan_name}
          </Typography>

          <ButtonGroup
            size="small"
            variant="outlined"
            aria-label="outlined primary button group"
          >
            <Button startIcon={<EditIcon />}>EDIT</Button>
            <Button
              startIcon={<ContentCopyIcon />}
              onClick={() => setOpenCopyScreenerModal(true)}
            >
              COPY
            </Button>
            <CSVLink data={stockList} filename={`${scan_name}.csv`}>
              <Button startIcon={<DownloadIcon />}>DOWNLOAD</Button>
            </CSVLink>

            {/* <CSVDownload data={stockList} target="_blank" /> */}
            <Button onClick={handleRemoveScreener} startIcon={<DeleteIcon />}>
              Remove
            </Button>
          </ButtonGroup>
        </Toolbar>

        {screenerDataQuery.isLoading ? (
          <h1>Loading...</h1>
        ) : (
          <DenseTable
            tableData={stockList}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            maxHeight="500px"
            selectedStockCategory={selectedStockCategory}
            setSelectedStockCategory={setSelectedStockCategory}
            latest_stocks={latestStocks}
          />
        )}

        {/* COPY SCREENER TO ANOTHER DASHBOARD MODAL */}
        <MuiModal
          open={openCopyScreenerModal}
          setOpen={setOpenCopyScreenerModal}
        >
          <CopyScreenerModal
            setOpenModal={setOpenCopyScreenerModal}
            screenerDetails={screenerDetails}
          />
        </MuiModal>
      </Box>

      {/* REACT HOT */}
      <ReactHotToaster />
    </Box>
  );
};

export default ScreenerTable;

// UTLITY FUNCTIONS
const fetchScreenerData = async (screener_id) => {
  if (!screener_id) return;

  const res = await axiosConfig({
    method: "get",
    url: `/tnibroadmin/scanner?id=${screener_id}`,
    data: {},
  });

  return res;
};
