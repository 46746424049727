import { SET_SEARCH_DATE, SET_SEARCH_INPUT, SET_SEARCH_SEGMENT, SET_SEARCH_SYMBOL } from "./types";

export const setSearchDate = (date_input) => ({
  type: SET_SEARCH_DATE,
  payload: date_input,
})

export const setSearchInput = (search_input) => ({
  type: SET_SEARCH_INPUT,
  payload: search_input,
})

export const setSearchSymbol = (search_symbol) => ({
  type: SET_SEARCH_SYMBOL,
  payload: search_symbol,
})

export const setSearchSegment = (search_segment) => ({
  type: SET_SEARCH_SEGMENT,
  payload: search_segment,
})