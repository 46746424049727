import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import toast from "react-hot-toast";
import { getUserReferrals } from '../utils';
import MuiDenseTable from '../../muiComps/MuiDenseTable';
import { Box } from '@mui/material';
import ReactHotToaster from '../../common/ReactHotToaster';
import MuiTableSkeletonLoader from '../../muiComps/MuiTableSkeletonLoader';

const columns = [
  { id: "id", label: "ID", minWidth: 50 },
  { id: "email", label: "Email", minWidth: 50 },
  {
    id: "phoneno",
    label: "Phone",
    // minWidth: 80,
    align: "right",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "referral_code",
    label: "Referral Code",
    // minWidth: 100,
    align: "right",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "user_type",
    label: "User Type",
    // minWidth: 80,
    align: "right",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "date_joined",
    label: "Date Joined",
    minWidth: 80,
    align: "center",
  },
  {
    id: "country",
    label: "Country",
    minWidth: 80,
    align: "center",
  },
  {
    id: "email_verified",
    label: "Email Verified",
    minWidth: 80,
    align: "center",
  },
  
];

const UserReferrals = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const [userReferralData, setUserReferralData] = useState([]);
  const rows = userReferralData?.map((item, index) => {
    return {
      id: index + 1,
      email: item.email,
      phoneno: item.phoneno,
      referral_code: item.referralcode,
      user_type: item.user_type,
      // date_joined: new Date(item.date_joined).toDateString(),
      date_joined: new Date(item.date_joined_time).toString().slice(0, 24),
      country: item.country,
      email_verified: item.email_verified,
    };
  });


  // FETCH USER Payments (all transactions) USING useQUERY
  const userReferralsQuery = useQuery({
    queryKey: ["user-referrals"],
    queryFn: () => getUserReferrals(userDetails.id),
    onSuccess: (data) => {
      setUserReferralData(data.response);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  return (
    <Box sx={{ m: 4}}>
      <h2>List of Users referred successfully.</h2>

      {/* USER Referrals TABLE */}
      {userReferralsQuery.isLoading ? (
        <MuiTableSkeletonLoader />
      ) : (
        <MuiDenseTable rowData={rows} columnHeadings={columns} />
      )}
      

    {/* REACT TOAST */}
    <ReactHotToaster />
    </Box>
  )
}

export default UserReferrals