import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography } from "@mui/material";

import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  addStocksToWatchlist,
  getUserWatchlists,
  removeSymbolFromWatchlist,
} from "../watchlist_utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import ReactHotToaster from "../../common/ReactHotToaster";

const SaveToWatchlistModal = ({
  symbolDetails,
  setOpenSaveToWatchlistModal,
  setSortedTableFeed,
  userWatchlistDataRes
}) => {
  const navigate = useNavigate();
  const selectedSymbol = symbolDetails.symbol_name;
  // FORMAT: dashboardValue: { label: <DASHBOARDNAME>, id: DASHBOARD_ID }
  // const [dashboardValue, setDashboardValue] = React.useState(userDashboardList[0]);
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const [userWatchlists, setUserWatchlists] = useState([]);
  const [watchlistOptionsForActiveSymbol, setWatchlistOptionsForActiveSymbol] =
    useState([]); // ALL WATCHLISTS FOR WHICH PROP selectedSymbol ALREADY EXISTS
  const [selectedWatchlist, setSelectedWatchlist] = useState({
    label: "",
    id: 0,
  });
  const [selectedWatchlistForRemoval, setSelectedWatchlistForRemoval] =
    useState({ label: "", id: 0 });

  const handleCreateNewDashboard = () => {
    navigate("/watchlist/create");
  };

  // FETCH USER WATCHLISTS USING useQUERY
  // const watchlistQuery = useQuery({
  //   queryKey: ["user-watchlists"],
  //   queryFn: () => getUserWatchlists(userDetails.id),
  //   onSuccess: (data) => {
  //     // Filtering out watchlists where symbol is already added.
  //     const userWatchlistArray = data.response
  //       .filter((item) => !item.symbol.includes(selectedSymbol))
  //       .map((item) => {
  //         return {
  //           label: item.name,
  //           id: item.id,
  //         };
  //       });
      
  //     setUserWatchlists(userWatchlistArray);
  //     setSelectedWatchlist(userWatchlistArray[0]);

  //     // Filtering those watchlists where symbols are not added.
  //     const newWatchlistsForActiveSymbol = data.response
  //       .filter((item) => item.symbol?.includes(selectedSymbol))
  //       .map((item) => {
  //         return {
  //           label: item.name,
  //           id: item.id,
  //         };
  //       });
  //     setWatchlistOptionsForActiveSymbol(newWatchlistsForActiveSymbol);
  //   },
  //   onError: (error) => {
  //     // ADD REACT HOT TOAST HERE
  //     toast.error(error?.response?.data?.response || "Something went wrong");
  //   },
  // });

  // FETCHING USER WATCHLISTS
  useEffect(() => {
    const fetchUserWatchlistsData = async () => {
      // Filtering out watchlists where symbol is already added.
      const userWatchlistArray = userWatchlistDataRes
        .filter((item) => !item.symbol.includes(selectedSymbol))
        .map((item) => {
          return {
            label: item.name,
            id: item.id,
          };
        });
      
      setUserWatchlists(userWatchlistArray);
      setSelectedWatchlist(userWatchlistArray[0]);

      // Filtering those watchlists where symbols are not added.
      const newWatchlistsForActiveSymbol = userWatchlistDataRes
        .filter((item) => item.symbol?.includes(selectedSymbol))
        .map((item) => {
          return {
            label: item.name,
            id: item.id,
          };
        });
      setWatchlistOptionsForActiveSymbol(newWatchlistsForActiveSymbol);
    }

    fetchUserWatchlistsData();
  }, [selectedSymbol, userWatchlistDataRes]) 

  // Patch / send / ADD stocks array to backend function
  const addSymbolToWatchlist = async () => {
    if (!selectedWatchlist?.id) {
      // Toast message here: Id or watchlist is empty
      return;
    }

    try {
      const res = await addStocksToWatchlist(selectedWatchlist?.id, [
        selectedSymbol,
      ]);
      // console.log(res);
      if (res.status === 200 || res.statusText === "OK") {
        setSortedTableFeed((currentState) => {
          return currentState.map((stockData) => {
            if (stockData.symbol === selectedSymbol) {
              return {
                ...stockData,
                added_in_watchlist: true,
              };
            }

            return stockData;
          });
        });

        toast.success(
          `${selectedSymbol} added to watchlist [${selectedWatchlist?.label}] successfully!`
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message || "Something went wrong");
    } finally {
      setOpenSaveToWatchlistModal(false);
    }
  };

  // REMOVE SYMBOL FROM WATCHLIST HANDLER
  const handleRemoveSymbol = async () => {
    if (!selectedWatchlistForRemoval?.id || !selectedSymbol) {
      return;
    }

    try {
      const res = await removeSymbolFromWatchlist(
        selectedWatchlistForRemoval?.id,
        selectedSymbol
      );

      if (res.status === 200 || res.statusText === "OK") {
        setSortedTableFeed((currentState) => {
          return currentState.map((stockData) => {
            if (stockData.symbol === selectedSymbol) {
              return {
                ...stockData,
                added_in_watchlist: false,
              };
            }

            return stockData;
          });
        });

        toast.success(
          `${selectedSymbol} removed from watchlist [${selectedWatchlist?.label}] successfully!`
        );
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message || "Something went wrong");
    } finally {
      setOpenSaveToWatchlistModal(false);
    }
  };

  return (
    <Box sx={{}}>
      <Typography variant="h6" mb={2} textAlign="center">
        ADD TO WATCHLIST
      </Typography>

      <Box sx={{ border: "1px solid #ccc", p: 2, borderRadius: 1 }}>
        <Typography variant="subtitle1" mb={1}>
          Save To Watchlist
        </Typography>

        <Autocomplete
          size="small"
          disablePortal
          id="combo-box-demo"
          value={selectedWatchlist?.label}
          onChange={(event, newValue) => {
            setSelectedWatchlist(newValue);
          }}
          options={userWatchlists}
          disabled={userWatchlists?.length === 0}
          sx={{ width: 250 }}
          renderInput={(params) => (
            <TextField {...params} label="Choose a Watchlist" />
          )}
        />

        {/* DASHBOARD SAVE BUTTON */}
        <Stack direction="row" spacing={1} mt={2}>
          <Button
            variant="contained"
            size="small"
            startIcon={<SaveIcon />}
            disabled={
              !selectedWatchlist?.label || selectedWatchlist?.label === ""
            }
            onClick={addSymbolToWatchlist}
          >
            SAVE
          </Button>
          <span>OR</span>
          <Button
            variant="contained"
            size="small"
            startIcon={<DashboardCustomizeIcon />}
            onClick={handleCreateNewDashboard}
          >
            CREATE NEW
          </Button>
        </Stack>
      </Box>

      {/* REMOVE WATCHLIST DISABLED IF NO WATCHLIST */}
      {!symbolDetails.added_in_watchlist ? null : (
        <Box mt={2} sx={{ border: "1px solid #ccc", p: 2, borderRadius: 1 }}>
          <Typography variant="subtitle1" mb={1}>
            Remove from Watchlist
          </Typography>

          <Autocomplete
            size="small"
            disablePortal
            id="watchlist-active-symbol"
            value={selectedWatchlistForRemoval?.label}
            onChange={(event, newValue) => {
              setSelectedWatchlistForRemoval(newValue);
            }}
            options={watchlistOptionsForActiveSymbol}
            disabled={watchlistOptionsForActiveSymbol.length === 0}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField {...params} label="Choose a Watchlist" />
            )}
          />

          <Stack direction="row" spacing={1} mt={2}>
            {/* REMOVE SYMBOL FROM WATCHLIST BUTTON */}
            <Button
              variant="contained"
              size="small"
              startIcon={<BookmarkRemoveIcon />}
              disabled={!symbolDetails.added_in_watchlist}
              onClick={handleRemoveSymbol}
            >
              REMOVE
            </Button>
          </Stack>
        </Box>
      )}

      {/* REACT HOT TOAST */}
      {/* <ReactHotToaster /> */}
    </Box>
  );
};

export default SaveToWatchlistModal;
