import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";

const MuiPasswordInput = ({ userPassword, setUserPassword, label = "Password", id }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // handling onchange password
  const handleUserPassword = (event) => {
    event.preventDefault();
    // console.log(event);
    setUserPassword(event.target.value);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel sx={{}} htmlFor={id}>
        {label}
      </InputLabel>
      <OutlinedInput
        sx={{ backgroundColor: "#fff", borderRadius: 1 }}
        value={userPassword}
        onChange={handleUserPassword}
        size="small"
        id={id}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        // label="Password"
      />
    </FormControl>
  );
};

export default MuiPasswordInput;
