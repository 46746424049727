import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Link } from "react-router-dom";
import { isContentEditable } from "@testing-library/user-event/dist/utils";

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }));

const MuiCustomTooltip = ({ heading, content, videoLink, iconColor = "#333" }) => {

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography variant="subtitle1" sx={{ textAlign: 'center'}} >{heading}</Typography>
          <Typography variant="body2">{content}</Typography>
          <Link to={videoLink}>
            Click here for video tutorial
          </Link>
        </React.Fragment>
      }
    >
      {/* static width, height provided to make background hover full circle */}
      <IconButton sx={{ width: 25, height: 25}} >
        <HelpOutlineIcon sx={{ color: iconColor, fontSize: 16 }} />
      </IconButton>
    </HtmlTooltip>
  );
};

export default MuiCustomTooltip;
