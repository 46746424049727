import { Stack } from "@mui/material";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";

const MuiSortIcons = ({
  sortingByValue,
  sortingBy,
  sortingOrder,
  onClick,
  pointerEvents = 'auto'
}) => {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      direction="column"
      spacing={-3}
      sx={{ cursor: "pointer", pointerEvents: pointerEvents }}
      onClick={onClick}
      
    >
      
      <ArrowDropUpSharpIcon
        fontSize="large"
        sx={{ color: sortingBy === sortingByValue && sortingOrder === 'asc' ? "#0FFF50" : ""}}
        // color={sortingBy === sortingByValue && sortingOrder ? "success" : ""}
      />
      <ArrowDropDownSharpIcon
        fontSize="large"
        sx={{ color: sortingBy === sortingByValue && sortingOrder === 'desc' ? "#FF2400" : ""}}
        // color={sortingBy === sortingByValue && !sortingOrder ? "error" : ""}
      />
      
    </Stack>
  );
};

export default MuiSortIcons;
