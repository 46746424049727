import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";


import axiosConfig from "../../../axios/axiosConfig";

import "./SingleDashboardPage.module.css";
import ScreenerTable from "./ScreenerTable";
import { useSelector } from "react-redux";

import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import toast from "react-hot-toast";
import ReactHotToaster from "../../common/ReactHotToaster";
import { useQuery } from "@tanstack/react-query";

// IMP: THIS PAGE SHOULD CONTAIN A GRID OF ALL SCREENERS
// https://api.tnibro.com/tnibroadmin/scanner?id=79

// This component/ page is a route of /dashboards/:dashboardId
// API Endpoint: /user/dashboard?userid=11&dashboard_id=3
const SingleDashboardPage = (props) => {
  const { dashboardId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { user: currentUser } = useSelector((state) => state.auth);
  const USERID = currentUser.userDetails.id;

  const [screenerID_List, setScreenerID_List] = useState([]);
  const [dashboardDetails, setDashboardDetails] = useState({});

  // FETCHING USER DASHBOARD
  const userDashboardQuery = useQuery({
    queryKey: ["user_dashboard", dashboardId], // dependency
    queryFn: () => fetchUserDashboard(USERID, dashboardId),
    onSuccess: (res) => {
      // console.log("screenerkadata: ", response)
      setDashboardDetails(res.data.response);
      setScreenerID_List(res.data.response.screener_list);
    },
    onError: (error) => {
      console.log("user dashboard error: ", error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    },
  });

  // Route to screener
  const routeToScreener = () => {
    navigate("/screener");
  };

  if (userDashboardQuery?.isLoading) {
    return (
      <Stack>
        <h1>User Dashboard Loading...</h1>
        <CircularProgress />
      </Stack>
      
    )
  }

  return (
    <Box sx={{ position: "relative" }}>
      {/* TOP RIGHT BUTTONS */}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: "absolute",
          top: 0,
          right: 25,
          display: { sm: "none", md: "block" },
        }}
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<AutoAwesomeMosaicIcon />}
          disabled
        >
          Edit Layout
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<LibraryAddIcon />}
          onClick={routeToScreener}
        >
          Add Scan
        </Button>
      </Stack>

      <Typography
        variant="h4"
        sx={{
          m: 2,
          // textAlign: "center",
          letterSpacing: 2,
          textTransform: "uppercase",
        }}
      >
        {dashboardDetails.dashboard_name}
      </Typography>

      <Link to={`/dashboards`}>
        <Button size="small" variant="contained" >Back to Dashboards</Button>
      </Link>

      {/* MAIN CONTAINER FOR ALL SCREENERS, MAP THE SCREENERS BELOW */}
      <Box m={4}>
        {/* <ScreenerItemTable /> */}
        {/* MAP SCREENER IDs */}
        {screenerID_List?.length > 0 && screenerID_List?.map((screener_id) => {
          return (
            <ScreenerTable
              key={screener_id}
              screenerID={screener_id}
              dashboardId={dashboardId}
              setScreenerID_List={setScreenerID_List}
            />
          );
        })}
      </Box>

      {/* REACT HOT */}
      <ReactHotToaster />
    </Box>
  );
};

export default SingleDashboardPage;


// UTILITY FUNCTIONS
const fetchUserDashboard = async (user_id, dashboard_id) => {
  if (!dashboard_id) return;

  const res = await axiosConfig({
    method: "get",
    url: `/user/dashboard?userid=${user_id}&dashboard_id=${dashboard_id}`,
    data: {},
  });

  return res;
};