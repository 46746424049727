import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const UserSubscriptionDetails = ({
  handleRenewPlan,
  userSubscriptionDetails,
  selectedPlan,
  setSelectedPlan,
  planPeriod,
  setPlanPeriod,
}) => {
  const { plan_type, add_on_list, subscription_date } = userSubscriptionDetails;
  const formattedDate = new Date(subscription_date).toDateString();

  return (
    <div>
      <Box sx={{}}>
        <Stack direction="row" spacing={2}>
          <Typography>Plan Type:</Typography>
          <Typography>{plan_type}</Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Typography>Add Ons:</Typography>
          {add_on_list?.map((item, index) => {
            return <span>{item}</span>;
          })}
        </Stack>

        <Stack direction="row" spacing={2}>
          <Typography>Subscribed / Renewed On:</Typography>
          <Typography>{formattedDate}</Typography>
        </Stack>
      </Box>

      {/* RENEW SUBSCRIPTION */}
      <Stack
        sx={{
          display: "inline-block",
          margin: "2rem",
          padding: "2rem",
          border: "1px solid #ccc",
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            textTransform: "capitalize",
            mb: 3,
            padding: 1,
            border: "1px solid #ccc",
            borderRadius: 2,
          }}
        >
          Renew / Upgrade Subscription
        </Typography>
        <Stack direction="row" spacing={2}>
          <FormControl>
            <FormLabel id="subscription-plan-group">Plan</FormLabel>
            <RadioGroup
              aria-labelledby="subscription-plan-radio-buttons-group"
              name="subscription-plan-group"
              value={selectedPlan}
              onChange={(event) => setSelectedPlan(event.target.value)}
            >
              <FormControlLabel
                value="basic"
                control={<Radio />}
                label="Basic"
              />
              <FormControlLabel
                value="premium"
                control={<Radio />}
                label="Premium"
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel id="subscription-type-buttons-group">
              Monthly/Yearly
            </FormLabel>
            <RadioGroup
              aria-labelledby="subscription-type-radio-buttons-group"
              name="subscription-type-group"
              value={planPeriod}
              onChange={(event) => setPlanPeriod(event.target.value)}
            >
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label="Monthly"
              />
              <FormControlLabel
                value="yearly"
                control={<Radio />}
                label="Yearly"
              />
            </RadioGroup>
          </FormControl>

          {/* <Button variant='contained' size='small' >Renew</Button> */}
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            mt: 2,
            display: "inline-block",
          }}
        >
          <Button variant="contained" size="small" onClick={handleRenewPlan}>
            Renew
          </Button>

          {/* <Button variant="contained" size="small">
            plan change
          </Button> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default UserSubscriptionDetails;
