import CircularProgress from "@mui/material/CircularProgress";
import { Container } from "@mui/material";
// import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from "react";
import { useSelector } from "react-redux";

import axiosConfig from "../../../axios/axiosConfig";
import { useNavigate } from "react-router-dom";
import CommonWatchlistForm from "../CommonWatchlistForm";
import ReactHotToaster from "../../common/ReactHotToaster";
import toast from "react-hot-toast";

const CreateWatchlistForm = () => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  const navigate = useNavigate();

  const [dashboardName, setDashboardName] = useState("");
  const [dashboardDesc, setDashboardDesc] = useState("");
  const [loading, setLoading] = useState(true);

  const handleCreateWatchlist = async () => {
    setLoading(true);
    try {
      const res = await axiosConfig({
        method: "post",
        url: "/watchlist/userwatchlist",
        data: {
          userid: userDetails.id,
          name: dashboardName,
          description: dashboardDesc,
        },
      });
      
      if (res.status === 201 || res.statusText === "Created") {
        const watchlistId = res.data.response.id;
        navigate(`/watchlist/${watchlistId}`);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <h1>Create New Watchlist</h1>

      <CommonWatchlistForm
        dashboardName={dashboardName}
        setDashboardName={setDashboardName}
        dashboardDesc={dashboardDesc}
        setDashboardDesc={setDashboardDesc}
        onClickHandler={handleCreateWatchlist}
      />

      <ReactHotToaster />
      
    </Container>
  );
};

export default CreateWatchlistForm;
