import React, { useState } from "react";

import styles from "./ResetPasswordPage.module.css";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import axiosConfig from '../../axios/axiosConfig';
import ReactHotToaster from "../../components/common/ReactHotToaster";
import MuiPasswordInput from "../../components/muiComps/MuiPasswordInput/MuiPasswordInput";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [userPassword, setUserPassword] = useState("");
  const [reUserPassword, setReUserPassword] = useState("");

  const user_email = location.state?.email;
  
  const handleResetPassword = async () => {
    if (!user_email) {
      alert("Email field is undefined!");
      return;
    }

    const isPasswordValid = checkPassword(userPassword);
    if (!isPasswordValid) {
      toast.error("Password must be a minimum of 8 characters, with at least 1 symbol, upper and lower case letters and a number", { position: "top-right", hideAfter: 8 });
      return;
    }

    if (userPassword !== reUserPassword) {
      toast.error("Password do not match");
      return;
    }
    
    try {
      const res = await axiosConfig({
        method: "patch",
        url: "/enroll/forgot_password",
        data: { email: user_email, password: userPassword },
      });
      console.log("res: ", res)
      if (res.status === 202 || res.statusText === "Accepted") {
        toast.success('Password changed successfully. Please login with new password!');
        navigate('/login');
      }
      
    } catch (error) {
      console.log(error)
      toast.error(error.response?.data?.response || "Something went wrong");
    }
  }

  if (!user_email) {
    return (
      <div>
        <h2>Access Denied.</h2>
      </div>
    )
  }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      mt: 4,
      // backgroundColor: 'yellow'
    }}>
      <h1>Reset Password</h1>

      <Box sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
      }} >

        <Typography>Type New Password</Typography>

        <MuiPasswordInput userPassword={userPassword} setUserPassword={setUserPassword} id="password" />

        <MuiPasswordInput userPassword={reUserPassword} setUserPassword={setReUserPassword} id="re-password" label="Retype-Password" />
        
        {/* <TextField
          size="small"
          id="password"
          label="Password"
          value={userPassword}
          onChange={(event) => {
            setUserPassword(event.target.value);
          }}
        />

        <TextField
          size="small"
          id="re-password"
          label="Retype-Password"
          value={reUserPassword}
          onChange={(event) => {
            setReUserPassword(event.target.value);
          }}
        /> */}

        <Button size="small" variant='contained' onClick={handleResetPassword} >Reset</Button>
      </Box>

      {/* REACT HOT TOAST FOR TOAST MESSAGES */}
      <ReactHotToaster /> 
    </Box>
  );
};

export default ResetPasswordPage;


// Password Validation: minimum 8 characters, at least 1 symbol, upper and lower case letters and a number
function checkPassword(str)
{
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
}