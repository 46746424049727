import { Box, Button, Pagination, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import SymbolTable from "./SymbolTable";
import DownloadIcon from "@mui/icons-material/Download";

import { CSVLink, CSVDownload } from "react-csv";
import MuiLinearProgressLoader from "../../components/muiComps/MuiLinearProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import usePagination from "../../hooks/usePagination";
// import { setCurrentPage } from "../../reduxStore/actions/pagination";

const SymbolsListTable = ({
  symbolsData = [],
  setShowBackTestTiles,
  setSymbolsData,
  timeframeDate,
  isSymbolsDataLoading,
  totalStocks,
  // currentPage,
  // setCurrentPage
}) => {
  // const { screenerId, timeframeDate } = symbolsData;
  const dispatch = useDispatch();

  const rowsPerPage = 100;

  const formattedDate = timeframeDate?.slice(0, 10);
  const formattedDateInWords = new Date(formattedDate).toDateString();

  // PAGINATION CODE
  const { currentPage, getCurrentData, setCurrentPage, pageCount } =
    usePagination(symbolsData, rowsPerPage); // dataFeed was tableData instead of filteredTableFeed
  let paginatedTableData = getCurrentData(); // DATA THAT IS DISPLAYED AFTER PAGINATION

  // Below function is only being used for Download CSV
  const filteredData = paginatedTableData?.map((item) => {
    return {
      symbol: item.symbol,
      date: new Date(item.timeframe).toDateString(),
      trigger_price: item.CMP,
      high_price: item.latest_cmp,
      percent_change: item.pcnt,
    };
  });

  return (
    <Stack marginX={6} mt={4}>
      <Typography variant="h4" mb={2} textTransform="uppercase">
        Backtest Table ({formattedDateInWords})
      </Typography>

      <Box mb={2}>
        <Button
          fullWidth={false}
          onClick={() => {
            setShowBackTestTiles(true);
            // make symbols data null or previous data will be shown in table
            // setSymbolsData([]);
          }}
          variant="contained"
          size="small"
          sx={{ mr: 2 }}
        >
          Go Back
        </Button>

        {filteredData?.length > 0 ? (
          <CSVLink
            data={filteredData || []}
            filename={`BACKTEST [${formattedDate}].csv`}
          >
            <Button
              variant="contained"
              size="small"
              startIcon={<DownloadIcon />}
            >
              DOWNLOAD
            </Button>
          </CSVLink>
        ) : null}
      </Box>

      {/* PAGINATION */}
      <Stack mb={2}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={(event, newPage) => setCurrentPage(newPage)}
          // onChange={(event, newPage) => dispatch(setCurrentPage(newPage))}
          variant="outlined"
          shape="rounded"
        />
      </Stack>

      {/* SYMBOLS TABLE COMPONENT HERE   */}
      {isSymbolsDataLoading ? (
        <MuiLinearProgressLoader />
      ) : (
        <SymbolTable symbolsData={paginatedTableData} />
      )}
    </Stack>
  );
};

export default SymbolsListTable;
