import React from "react";
import styles from "./PaymentSuccess.module.css";

const PaymentSuccess = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Payment Successful!</h1>
      <p className={styles.message}>
        Thank you for your purchase. Your order has been successfully processed.
      </p>
    </div>
  );
};

export default PaymentSuccess;
