import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import axiosConfig from "../../../axios/axiosConfig";
import { useSelector } from "react-redux";
import { sendToken } from "../../../utils/notifications";


const AddScreenerAlertModal = ({ closeModal, screenerId }) => {
  const {
    user: { userDetails },
  } = useSelector((store) => store.auth);

  // States for taking user input (alert name and description)
  const [alertDetails, setAlertDetails] = useState({
    alertName: "",
    alertDesc: "",
  });

  const alertDetailsOnChange = (event) => {
    setAlertDetails((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const alertSaveHandler = async () => {
    try {
      await sendToken(userDetails.id);
      // console.log("token_res: ", token_res)
      // if (!token_res) return;
    } catch (error) {
      console.log(error);
      return;
    }

    try {
      const alertRes = await axiosConfig({
        method: "post",
        url: "screener_alert/user_screener_alert",
        data: {
          userid: userDetails.id,
          screener_id: screenerId,
          alert_name: alertDetails?.alertName,
          description: alertDetails?.alertDesc,
        },
      });

      console.log("alert res: ", alertRes);
    } catch (error) {
      console.log(error);
    } finally {
      closeModal();
    }
  };

  const saveBtnDisabled =
    !alertDetails?.alertName || alertDetails?.alertName.trim() === "";

  return (
    <div>
      <Typography variant="h6" align="center" mb={2}>
        Add Screener Alert
      </Typography>

      <Box>
        <Stack gap={2}>
          <TextField
            required
            size="small"
            id="alert-name"
            name="alertName"
            label="Alert Name"
            value={alertDetails?.alertName}
            onChange={alertDetailsOnChange}
          />

          <TextField
            size="small"
            id="alert-desc"
            name="alertDesc"
            label="Description"
            multiline
            rows={3}
            value={alertDetails?.alertDesc}
            onChange={alertDetailsOnChange}
          />
        </Stack>

        <Stack direction="row" gap={1} mt={2} sx={{}}>
          <Button
            disabled={saveBtnDisabled}
            variant="contained"
            onClick={alertSaveHandler}
          >
            Save
          </Button>
          <Button variant="contained" onClick={closeModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default AddScreenerAlertModal;
