import { SET_CURRENT_PAGE, SET_ROWS_PER_PAGE, SET_TOTAL_STOCKS } from "./types";

export const setCurrentPage = (current_page) => ({
  type: SET_CURRENT_PAGE,
  payload: current_page,
});

export const setRowsPerPage = (rows) => ({
  type: SET_ROWS_PER_PAGE,
  payload: rows
})

export const setTotalStocks = (total_stocks) => ({
  type: SET_TOTAL_STOCKS,
  payload: total_stocks
})