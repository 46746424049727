// MuiDenseTable

import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    padding: "4px 2px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "4px 2px",
  },
}));

// FORMAT FOR COLUMNS
const columns_FORMAT = [
  { id: "payment_id", label: "Payment ID", minWidth: 50 },
  { id: "amount", label: "Amount", minWidth: 50 },
  {
    id: "plan_type",
    label: "Plan Type",
    // minWidth: 80,
    align: "right",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "payment_date",
    label: "Payment Date",
    // minWidth: 100,
    align: "right",
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: "payment_status",
    label: "Payment Status",
    // minWidth: 80,
    align: "right",
    // format: (value) => value.toFixed(2),
  },
  {
    id: "payment_mode",
    label: "Payment Mode",
    minWidth: 80,
    align: "center",
  },
];

export default function MuiDenseTable({ rowData, columnHeadings }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ maxWidth: "80%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440, p: 2 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnHeadings?.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columnHeadings?.map((column) => {
                      const value = row[column.id];

                      // code for remove button column
                      if (column.onClick) {
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            <div
                              onClick={() => column.onClick(row.id)}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {value}
                            </div>
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
