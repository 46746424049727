import React, { } from 'react';
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const tableHeaders = [
  { id: 1, label: "Title", align: 'left'},
  { id: 2, label: "Coupon Code", align: 'left'},
  { id: 3, label: "Description", align: 'left'},
  { id: 4, label: "Discount %", align: 'left'},
  { id: 5, label: "Start Date", align: 'left'},
  { id: 6, label: "Expiry Date", align: 'left'},
  { id: 7, label: "Coupon Type", align: 'left'},
  { id: 8, label: "Status", align: 'left'},
  { id: 9, label: "Actions", align: 'left'},
]

const UserCouponsTable = ({ userCouponsData, handleEditCoupon }) => {
  
  const tableCellStyles = {
    padding: "4px 6px",
    // border: '1px solid'
  };

  return (
    <TableContainer
        sx={{  maxWidth: 1200, overflowX: 'scroll' }}
        component={Paper}
                >
        <Table size="small" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: "#e2e2e2" }}>
            <TableRow>
              {tableHeaders.map(item => {
                return (
                  <TableCell sx={{ ...tableCellStyles, fontWeight: "bold", textTransform: 'uppercase' }}>
                    {item.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          {/* TABLE BODY STARTS HERE */}
          <TableBody sx={{ backgroundColor: "white" }}>
            {userCouponsData?.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:nth-of-type(odd)": {
                    backgroundColor: "#f9f9f9",
                  },
                  
                }}
              >
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.coupon_code}
                </TableCell>
                <TableCell sx={tableCellStyles} component="th" scope="row">
                  {row.description}
                </TableCell>
                <TableCell sx={tableCellStyles} component="th" scope="row" align="center">
                  {row.discount}
                </TableCell>

                <TableCell sx={tableCellStyles} align="center">
                  {/* {row.start_date} */}
                  {new Date(row.start_date).toDateString()}
                </TableCell>
                <TableCell sx={tableCellStyles} align="center">
                  {/* {row.expiry_date} */}
                  {new Date(row.expiry_date).toDateString()}
                </TableCell>

                <TableCell sx={tableCellStyles} align="center"  >
                {row.coupon_type}
                  {/* {new Date(row.user_subscription?.subscription_date).toDateString()} */}
                </TableCell>

                <TableCell sx={tableCellStyles}>
                  {row.active ? (
                    <Circle color="green" />
                  ) : (
                    <Circle color="red" />
                  )}
                </TableCell>
                
                {/* ACTION BUTTONS */}
                <TableCell sx={tableCellStyles}>
                  <Stack direction="row" spacing={1}>
                    {/* <Button variant='contained' size='small' onClick={() => handleEditCoupon(row)} >edit</Button> */}
                    <EditIcon sx={{
                      cursor: 'pointer'
                    }} onClick={() => handleEditCoupon(row)} />
                  </Stack>
                  
                </TableCell>

                
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* ERROR MSG IF EMPTY */}
        {userCouponsData?.length === 0 ? (
              <Typography variant="h4" sx={{
                p: 2,
                textAlign: 'center',
                width: '100%'
              }} >NO DATA AVAILABLE</Typography>
            ) : null}
      </TableContainer>
  )
}

export default UserCouponsTable;


// Red / Green circule component to show active
const Circle = ({ color }) => {
  return (
    <Box sx={{
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: color
    }}>

    </Box>
  )
}